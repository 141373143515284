import Styles from "./Navbar.module.css";
import { PiInstagramLogoBold } from "react-icons/pi";
import { RiFacebookFill } from "react-icons/ri";
import { TiSocialLinkedin } from "react-icons/ti";
import { BiLogoTwitter } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import BookADemo from "../Screens/Home/BookADemo";
export default function Footer() {
  const [BookADemoActive, setBookADemoActive] = useState(false);
  // const Mobile_Screen = window.innerWidth;
  const Navigate = useNavigate();
  return (
    <footer className={Styles.Footer}>
      {BookADemoActive ? (
        <BookADemo setBookADemoActive={setBookADemoActive} />
      ) : (
        ""
      )}
      <div>
        <div>
          <div>
            <img
              onClick={() => Navigate("/")}
              src="/assets/image/Logos/Testifyi_logo1.jpg"
              alt="logo"
            />
            <p>
              Facilitating students acquire perfect-fit education placements
              that ultimately changes their lives!
            </p>
            <p style={{ display: "flex", gap: "8px", color: "white" }}>
              <span
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/testifyi.india?igsh=c3ZpN2N2ZDg4cWVm"
                  )
                }
              >
                <PiInstagramLogoBold />
              </span>
              <span
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/testifyi.india?mibextid=LQQJ4d"
                  )
                }
              >
                <RiFacebookFill />
              </span>
              {/* <span>
                <TiSocialLinkedin />{" "}
              </span>
              <span>
                <BiLogoTwitter />
              </span> */}
            </p>
          </div>
          <hr className={Styles.Hr1} />
          <div>
            <h3>Company</h3>
            <p onClick={() => Navigate("/Course")}>Our Courses</p>
            {/* <p onClick={() => (window.location.href = "/#Searchcourses")}>
              Find Courses
            </p> */}
            {/* <p>Mock Tests</p>{" "} */}
            <p onClick={() => Navigate("/about-us")}>About Us</p>
            <p onClick={() => Navigate("/contact-us")}>Contact Us</p>
          </div>
          <hr className={Styles.Hr1} />
          <div>
            <h3>Important Links</h3>
            <p onClick={() => Navigate("/course")}>Courses</p>
            {/* <p onClick={() => setBookADemoActive(true)}>Book A Demo</p> */}
            <p onClick={() => Navigate("/dashboard")}>Dashboard</p>
            <p onClick={() => Navigate("/terms-and-conditions")}>
              Terms And Conditions
            </p>
            <p onClick={() => Navigate("/privacy-policy")}>Privacy Policy</p>
          </div>
          <hr className={Styles.Hr1} />
          <div>
            <h3>My Profile</h3>
            <p onClick={() => Navigate("/signup", { replace: true })}>
              Create Account
            </p>
            <p onClick={() => Navigate("/login", { replace: true })}>
              Login Now
            </p>
          </div>
        </div>

        <div>
          <hr className={Styles.Hr2} />
          <p>@ 2024 Testifyi.com All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

import { useNavigate } from "react-router-dom";
import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import Styles from "./PsychometricTest.module.css";
import { useMemo, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import { GrMail } from "react-icons/gr";
import { TfiAlignJustify } from "react-icons/tfi";
import ReactHelmet from "../../Components/ReactHelmet";

export default function PsychometricTest() {
  const [Score, setScore] = useState("");
  const [SidebarOpen, SetSideBarOpen] = useState(false);

  const Mobile_Screen = window.innerWidth;

  const Navigate = useNavigate();

  useMemo(() => {
    const PsychometricTestScore = localStorage.getItem("PsychometricTestScore");
    if (PsychometricTestScore) {
      setScore(PsychometricTestScore);
    }
  }, []);

  return (
    <div className={Styles.Dashboard}>
      <ReactHelmet title={"T-LAT Test"} description={""} />
      {/* SidePanel */}

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Mock Test"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Mock Test"
        />
      ) : (
        ""
      )}
      {/* Middle Section */}
      {!SidebarOpen ? (
        <section className={Styles.MiddleSection}>
          <div>
            <div className={Styles.TopBar}>
              {window.innerWidth <= 780 ? (
                <h2
                  onClick={() => SetSideBarOpen(!SidebarOpen)}
                  style={{ cursor: "pointer" }}
                >
                  <TfiAlignJustify />
                </h2>
              ) : (
                ""
              )}
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  flexDirection: window.innerWidth <= 480 ? "column" : "row",
                }}
                className={Styles.Test}
              >
                <h2 className="Font_Rajdhani">
                  T-LAT(Testifyi Language Assessment Test) :{" "}
                  {Score ? "29" : "N/A"}{" "}
                </h2>
                {window.innerWidth <= 480 ? (
                  ""
                ) : (
                  <div>
                    {Score ? (
                      <button
                        onClick={() => Navigate("/T-LAT-answers")}
                        className={Styles.custom_btn}
                      >
                        Check your Result
                      </button>
                    ) : (
                      <button
                        onClick={() => Navigate("/T-LAT-acknowledge")}
                        className={Styles.custom_btn}
                      >
                        Take A Test
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div>
                <p>
                  <IoMdNotifications />{" "}
                </p>
                <p>
                  <GrMail />{" "}
                </p>
              </div>
            </div>
          </div>

          {window.innerWidth <= 481 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {Score ? (
                <button
                  onClick={() => Navigate("/T-LAT-answers")}
                  className={Styles.custom_btn}
                >
                  Check your Result
                </button>
              ) : (
                <button
                  onClick={() => Navigate("/T-LAT-acknowledge")}
                  className={Styles.custom_btn}
                >
                  Take A Test
                </button>
              )}
            </div>
          ) : (
            ""
          )}

          <div className={Styles.Predicated_Score_Container}>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <h4>Study Interested :</h4>
              <select
                style={{ padding: "4px 20px", color: "blue" }}
                valaue="IELTS"
              >
                <option value="IELTS">IELTS</option>
              </select>
            </div>
          </div>
          <div className={Styles.Predicated_Score_Container}>
            <h4>Predicated Score :</h4>
            <table>
              {window.innerWidth <= 600 ? (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>Speaking</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>Listening</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Overall Score</td>
                    <td>6</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>Writing</td>
                    <td>Reading</td>
                    <td>Speaking</td>
                    <td>Listening</td>
                    <td>Overall Score</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>7</td>
                    <td>5</td>
                    <td>8</td> <td>7</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div className={Styles.Predicated_Score_Container}>
            <h4>Target Score :</h4>
            <table>
              {window.innerWidth <= 600 ? (
                <tbody>
                  <tr>
                    <td>Writing</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>Speaking</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>Listening</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Overall Score</td>
                    <td>6</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>Reading</td>
                    <td>Speaking</td>
                    <td>Listening</td>
                    <td>Overall Score</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>7</td>
                    <td>5</td>
                    <td>8</td> <td>7</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div className={Styles.Predicated_Score_Container}>
            <h4>Session IELTS Score :</h4>
            <table>
              {window.innerWidth <= 600 ? (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>Speaking</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>Listening</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Overall Score</td>
                    <td>6</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>Reading</td>
                    <td>Speaking</td>
                    <td>Listening</td>
                    <td>Overall Score</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>7</td>
                    <td>5</td>
                    <td>8</td> <td>7</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </section>
      ) : (
        " "
      )}

      {Mobile_Screen <= 480 ? "" : <RightSidePanel />}
      {/* Right Section  */}
    </div>
  );
}

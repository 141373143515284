import { useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar2 from "../../Components/Navbar2";
import Styles from "./AboutUs.module.css";
import ReactHelmet from "../../Components/ReactHelmet";
export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReactHelmet title={"About Us"} description={"About Us"} />
      {/* navbar Section */}
      <Navbar2 />
      <section className={Styles.showcase}>
        <img src={"/assets/image/About-Us.jpg"} alt="Banner" />
        <div className={Styles.overlay}>
          <div>
            <div>
              <p>
                <span> HOME</span> /<span> ABOUT US </span>{" "}
              </p>
            </div>
            <div>
              <h1 style={{ fontSize: "40px" }} className="Font_Rajdhani">
                About Us
              </h1>
            </div>
            <div>
              {/* <p>
                We help internationals to get enrolled into the best possible
                career-oriented courses <br /> along with the selection of
                colleges and universities across Australia.
              </p> */}
            </div>
          </div>
        </div>
      </section>
      <section className={Styles.Margin2}>
        <div>
          <div>
            {/* <h3 className={Styles.H3}>
              <strong>About Us – Testify</strong>
            </h3> */}
            <p>
              <span>
                Welcome to Testify! We are passionate about helping individuals
                achieve their language goals and succeed in their English
                proficiency exams. Whether you are preparing for the IELTS, PTE,
                or Duolingo English Test, or simply looking to improve your
                general English skills, Testify is here to guide you every step
                of the way.
              </span>
            </p>
            <p>
              <span>
                At Testify, we understand that preparing for language exams can
                be challenging and sometimes overwhelming. That's why we are
                committed to providing high-quality, accessible, and reliable
                learning resources that cater to your specific needs. Our
                mission is to make exam preparation smooth and stress-free,
                helping you build the confidence you need to excel.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>Our Story</strong>
            </h3>
            <p>
              <span>
                Testify was born out of a simple desire: to help people succeed
                in their language exams with ease and confidence. We noticed
                that many test-takers struggled to find the right preparation
                materials or didn't know where to start. Our goal is to fill
                this gap by offering a one-stop solution for all your exam
                preparation needs.
              </span>
            </p>
            <p>
              <span>
                Since our founding, we have focused on creating practice
                materials that are not only effective but also user-friendly.
                Our resources are designed to mimic real exam conditions so that
                you can experience what the actual test feels like. Whether
                you're practicing reading, writing, speaking, or listening, we
                ensure that every material you use is of the highest quality.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>What We Offer</strong>
            </h3>
            <p>
              <span>
                At Testify, we specialize in providing study resources and mock
                tests for the most popular English proficiency exams, including:
              </span>
            </p>
            <ul>
              <li>
                <strong>
                  IELTS (International English Language Testing System)
                </strong>
                <span> – both Academic and General Training versions</span>
              </li>
              <li>
                <strong>PTE (Pearson Test of English)</strong>
                <span> – Academic and Core versions</span>
              </li>
              <li>
                <strong>Duolingo English Test</strong>
                <span>
                  {" "}
                  – a growing alternative for students seeking online English
                  certification
                </span>
              </li>
              <li>
                <strong>General English Classes</strong>
                <span>
                  {" "}
                  – for anyone looking to enhance their English language skills
                  in everyday situations
                </span>
              </li>
            </ul>
            <p>
              <span>
                We also understand that every learner is different. That's why
                we offer a range of materials that cater to different learning
                styles. Whether you prefer taking mock tests, reviewing detailed
                explanations, or practicing with AI-driven feedback, our
                platform is designed to support your learning journey.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>Why Choose Testify?</strong>
            </h3>
            <ul>
              <li>
                <strong>Comprehensive Resources:</strong>
                <span>
                  {" "}
                  Our materials cover all sections of the exams, from reading to
                  speaking, ensuring that you’re well-prepared.
                </span>
              </li>
              <li>
                <strong>Realistic Mock Tests:</strong>
                <span>
                  {" "}
                  Our mock exams are designed to reflect the actual test
                  experience, helping you get comfortable with the format and
                  timing.
                </span>
              </li>
              <li>
                <strong>AI-Powered Feedback:</strong>
                <span>
                  {" "}
                  Get instant, detailed results that highlight your strengths
                  and weaknesses so you know exactly where to focus your
                  efforts.
                </span>
              </li>
              <li>
                <strong>User-Friendly Platform:</strong>
                <span>
                  {" "}
                  Our easy-to-use interface makes learning simple and
                  accessible, whether you’re studying from a computer or a
                  mobile device.
                </span>
              </li>
              <li>
                <strong>Flexible Learning:</strong>
                <span>
                  {" "}
                  Study at your own pace, whenever and wherever it’s convenient
                  for you. We believe in making learning flexible and accessible
                  for everyone.
                </span>
              </li>
            </ul>
            <h3 className={Styles.H3}>
              <strong>Our Values</strong>
            </h3>
            <p>
              <span>
                At Testify, we believe in putting learners first. We are
                dedicated to providing support that is not only helpful but also
                empowering. Our core values are:
              </span>
            </p>
            <ul>
              <li>
                <strong>Quality:</strong>
                <span>
                  {" "}
                  We strive to deliver the best content, designed and reviewed
                  by experts, ensuring that you’re using materials that will
                  truly help you succeed.
                </span>
              </li>
              <li>
                <strong>Integrity:</strong>
                <span>
                  {" "}
                  We are transparent about our methods, and we respect your
                  privacy and trust. Your success is our priority.
                </span>
              </li>
              <li>
                <strong>Innovation:</strong>
                <span>
                  {" "}
                  We leverage technology to offer the best learning experience.
                  From AI-driven feedback to intuitive design, we use modern
                  tools to enhance your preparation process.
                </span>
              </li>
              <li>
                <strong>Support:</strong>
                <span>
                  {" "}
                  We are here to guide you, whether it’s through learning
                  materials, answering your questions, or providing feedback.
                  You’re never alone in your preparation.
                </span>
              </li>
            </ul>
            <h3 className={Styles.H3}>
              <strong>Our Community</strong>
            </h3>
            <p>
              <span>
                Testify is more than just a learning platform; we are a
                community of learners and educators working together toward a
                common goal – success in English language exams. Whether you're
                a student, a professional, or someone aiming to improve your
                language skills, you’ll find a supportive network that
                encourages growth and confidence.
              </span>
            </p>
            <p>
              <span>
                We also love hearing from our community. Your feedback helps us
                improve and innovate to ensure we’re meeting your needs. We’re
                here to listen, help, and celebrate your achievements along the
                way.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>Get Started Today!</strong>
            </h3>
            <p>
              <span>
                Ready to take your English proficiency to the next level? Join
                Testify today and access our wide range of resources to help you
                prepare effectively. Whether you’re studying for the IELTS, PTE,
                or Duolingo English Test, or just brushing up on your general
                English skills, we have everything you need to succeed.
              </span>
            </p>
          </div>
        </div>
      </section>
      {/* Top Footer Section*/}
      <section className={"Footer_Top"}>
        <div>
          <div>
            <h1 className={"Font_Rajdhani"}>
              Start Your IELTS Journey With The Best
            </h1>
          </div>
          <div>
            <button className={"Font_Rajdhani"}>Create Account</button>
            <button className={"Font_Rajdhani"}>Login Now</button>
          </div>
        </div>
      </section>
      {/* Footer Section */}
      <Footer />
    </>
  );
}

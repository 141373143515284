import {
  PACKAGE_DETAILS_FAILED,
  PACKAGE_DETAILS_REQ,
  PACKAGE_DETAILS_RESET,
  PACKAGE_DETAILS_SUCCESS,
  PACKAGE_LIST_FAILED,
  PACKAGE_LIST_REQ,
  PACKAGE_LIST_RESET,
  PACKAGE_LIST_SUCCESS,
  PURCHASED_PACKAGES_LIST_FAILED,
  PURCHASED_PACKAGES_LIST_REQ,
  PURCHASED_PACKAGES_LIST_SUCCESS,
} from "../Constant/packageConstant";

export const Package_List_Reducer = (state = {}, { type, payload, Total }) => {
  switch (type) {
    case PACKAGE_LIST_REQ: {
      return { loading: true };
    }

    case PACKAGE_LIST_SUCCESS: {
      return { loading: false, PackageRec: payload, total: Total };
    }
    case PACKAGE_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case PACKAGE_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const packageDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PACKAGE_DETAILS_REQ: {
      return { loading: true };
    }

    case PACKAGE_DETAILS_SUCCESS: {
      return { loading: false, DetailsPack: payload };
    }

    case PACKAGE_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    case PACKAGE_DETAILS_RESET: {
      return { loading: false, success: "", error: "" };
    }

    default:
      return state;
  }
};

export const PurchasedPackagesReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PURCHASED_PACKAGES_LIST_REQ: {
      return { loading: true };
    }

    case PURCHASED_PACKAGES_LIST_SUCCESS: {
      return { loading: false, purchasedPack: payload };
    }

    case PURCHASED_PACKAGES_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

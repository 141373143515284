import Footer from "../../Components/Footer";
import TopFooter from "../../Components/TopFooterSection";
import Styles from "./Record.module.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaRegCircleDot } from "react-icons/fa6";
import { FiWatch } from "react-icons/fi";
import { useState } from "react";
import { RecordClassModel } from "../../Model/RecordClassModel";
import { useEffect } from "react";
import Navbar2 from "../../Components/Navbar2";
import ReactHelmet from "../../Components/ReactHelmet";
export default function RecordedClass() {
  const [RecordedClasses, setRecordedClasses] = useState(RecordClassModel);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ReactHelmet title={"Recorded Classes"} description={""} />
      <Navbar2 Page={"RecordClass"} />
      <div>
        <div className={Styles.Package}>
          <section className={Styles.showcase}>
            <img src={"/assets/image/Package_Banner2.jpeg"} alt="Banner" />
            <div className={Styles.overlay}>
              <div>
                <div>
                  <p>
                    <span> HOME</span> /<span> ALL COURSES </span>{" "}
                  </p>
                </div>
                <div>
                  <h1 className="Font_Rajdhani">All Recorded Classes</h1>
                </div>
                <div>
                  <p>
                    We help internationals to get enrolled into the best
                    possible career-oriented courses <br /> along with the
                    selection of colleges and universities across Australia.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/*Section 2*/}
          <section>
            <div className={Styles.Custom_Margin}>
              <div>
                <div>
                  <div className={Styles.FilterCard}>
                    <div>
                      <h4>Filter</h4>
                      <button>Clear Filters</button>
                    </div>
                    <hr />
                    <div>
                      <h4>Course Level</h4>
                      <div>
                        <input type="checkbox" /> <p>Beginner</p>
                      </div>
                      <div>
                        <input type="checkbox" />
                        <p>Intermediate</p>
                      </div>
                      <div>
                        <input type="checkbox" />
                        <p>Advance</p>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <h4>Subject</h4>
                      <div>
                        <input type="checkbox" /> <p>Writing</p>
                      </div>
                      <div>
                        <input type="checkbox" /> <p>Reading</p>
                      </div>
                      <div>
                        <input type="checkbox" /> <p>Speaking</p>
                      </div>
                      <div>
                        <input type="checkbox" /> <p>Listening</p>
                      </div>
                      <div>
                        <input type="checkbox" /> <p>Time Management</p>
                      </div>
                      <div>
                        <input type="checkbox" /> <p>Counselling</p>
                      </div>
                      <div>
                        <input type="checkbox" /> <p>General Strategy</p>
                      </div>
                      <div>
                        <input type="checkbox" /> <p>All Subjects</p>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <h4>Module</h4>
                      <div>
                        <input
                          type="checkbox"
                          value={true}
                          style={{ color: "blue" }}
                        />{" "}
                        <p>Pre Recorded</p>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <h4>Test Type</h4>
                      <div>
                        <input
                          type="checkbox"
                          value={true}
                          style={{ color: "blue" }}
                        />{" "}
                        <p>IELTS</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          value={true}
                          style={{ color: "blue" }}
                        />{" "}
                        <p>PTE</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          value={true}
                          style={{ color: "blue" }}
                        />{" "}
                        <p>TOFEL</p>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.Expert}>
                    <img
                      src="/assets/image/Expert.png"
                      alt="expert_contact_us"
                    />
                  </div>
                </div>
                <div className={Styles.PackageCard_Container}>
                  {RecordedClasses.map((el, i) => (
                    <div key={i + 24}>
                      <div className={Styles.card_design}>
                        <div className={Styles.Card1}></div>
                        <div>
                          <div className={Styles.CardDesign_Top}>
                            <div className={Styles.Record}>
                              <span>
                                {" "}
                                <FaRegCircleDot />{" "}
                              </span>
                              <span>Recorded on: 26 Aug 2022</span>
                            </div>
                            <div className={Styles.Record}>
                              <span>
                                <FiWatch />{" "}
                              </span>
                              <span>1 Hrs 30 min</span>
                            </div>
                          </div>
                          <div>
                            <h4>
                              Speaking & Listening <br /> Summary 0027
                            </h4>
                          </div>
                          <div className={Styles.Text_color}>
                            <p>Lecturer Name : Dr. Imran Wajid</p>
                            <p>
                              Topic Covered : Listing, Grammar, Time Management,
                              Reading , Writing, Speaking
                            </p>
                          </div>
                          <div className={Styles.Card_Design_btn}>
                            <div>
                              <p>₹ 299 / -</p>
                              <p>₹ 299 / -</p>
                            </div>
                            <div>
                              <button>Download Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div>
                    <div className={Styles.Pagination}>
                      <div>
                        <hr />
                      </div>
                      <div>
                        <p className={Styles.Page}>
                          <BsChevronLeft />
                        </p>
                        <p className={Styles.active}>1</p>
                        <p className={Styles.Page}>2</p>
                        <p className={Styles.Page}>3</p>
                        <p className={Styles.Page}>4</p>
                        <p className={Styles.Page}>5</p>
                        <p className={Styles.Page}>
                          {" "}
                          <BsChevronRight />{" "}
                        </p>
                      </div>
                      <div>
                        <hr />
                      </div>
                    </div>
                    {/* <div className={Styles.FAQ}>
                      <h1>Frequently Asked Questions</h1>
                      <div className={Styles.faq}>
                        <input id="faq-a" type="checkbox" />
                        <label for="faq-a">
                          <p className={Styles.faq_heading}>
                            <span>1</span>
                            {"  "} Can a Person extend their 482 visa ?
                          </p>
                          <div className={Styles.faq_arrow}></div>
                          <p className={Styles.faq_text}>
                            It is completely safe and totally legal! There will
                            is no record of this process to your shared Dropbox
                            users.
                          </p>
                        </label>
                        <input id="faq-b" type="checkbox" />
                        <label for="faq-b">
                          <p className={Styles.faq_heading}>
                            <span>2</span> Is a person holding a 482 visa
                            eligible to work in Australia ?
                          </p>
                          <div className={Styles.faq_arrow}></div>
                          <p className={Styles.faq_text}>
                            Does a Person need to have an employer to sponsor
                            them so as to apply for a TSS visa ?
                            <strong>
                              In order to control the risk and secure the space
                              you earned, we will gradually process it.
                            </strong>{" "}
                            during this time you can still use your account as
                            normal as usual.
                          </p>
                        </label>
                        <input id="faq-c" type="checkbox" />
                        <label for="faq-c">
                          <p className={Styles.faq_heading}>
                            <span>3</span> How does one apply for a TSS visa ?
                          </p>
                          <div className={Styles.faq_arrow}></div>
                          <p className={Styles.faq_text}>
                            NO ACCESS TO YOUR PERSONAL ACCOUNT OR INFO IS
                            REQUIRED! All I need from you is your Dropbox
                            referral link.
                          </p>
                        </label>
                      </div>{" "}
                      <div>
                        <hr />{" "}
                      </div>
                    </div> */}

                    {/* <div className={Styles.BrowseService}>
                      <h1>Browse Other Services</h1>
                      <div>
                        <div>
                          <div>
                            <img
                              src="/assets/image/TestPapers.png"
                              alt="testPapers"
                            />
                          </div>
                          <div>Live Classes</div>
                          <div className={Styles.ArrowHover}>
                            <BsFillArrowRightCircleFill />{" "}
                          </div>
                        </div>
                        <div>
                          <div>
                            <img
                              src="/assets/image/TestPapers.png"
                              alt="testPapers"
                            />
                          </div>
                          <div>Upcoming Mock Test</div>
                          <div className={Styles.ArrowHover}>
                            <BsFillArrowRightCircleFill />{" "}
                          </div>
                        </div>
                        <div>
                          <div>
                            <img
                              src="/assets/image/TestPapers.png"
                              alt="testPapers"
                            />
                          </div>
                          <div>All Test papers</div>
                          <div className={Styles.ArrowHover}>
                            <BsFillArrowRightCircleFill />{" "}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <TopFooter />
      <Footer />
    </>
  );
}

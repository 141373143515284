import { useDispatch, useSelector } from "react-redux";
import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./Purchases.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { ListOrders } from "../../Action/OrderPackageAction";
import { GET_TIME_ZONE } from "../../Model/HomeModel";
import { Link } from "react-router-dom";
import ReactHelmet from "../../Components/ReactHelmet";

export default function Purchases() {
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const { OrdersRec } = useSelector((s) => s.ListOrdersReducer);
  const [OpenMenuBar2, setOpenMenuBar2] = useState(false);

  const Mobile_Screen = window.innerWidth;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ListOrders());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={Styles.Dashboard}>
      <ReactHelmet title={"Purchases"} description={""} />
      {/* SidePanel */}

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Purchases"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Purchases"
        />
      ) : (
        ""
      )}
      {/* Middle Section */}
      {!SidebarOpen && !OpenMenuBar2 ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              SetSideBarOpen2={setOpenMenuBar2}
              SidebarOpen2={OpenMenuBar2}
              PageName={"Purchases"}
            />
          </div>
          <div className={Styles.OnlineClassTopSection}>
            <div>
              <h3>All Order List</h3>
              {/* <p>
                IELTS is an English language test for study, migration or work.
                Over three million people take out test every year. IELTS is
                accepted by more than 11,000 employers, universities & schools
                around the world.
              </p> */}
            </div>
          </div>

          <div className={Styles.OnlineClassContainer}>
            <div className={Styles.OnlineClassCard}>
              {window.innerWidth <= 480 ? (
                ""
              ) : (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "40%" }}>
                      <p style={{ margin: "0" }}> #Order Id </p>
                    </div>
                    <div style={{ width: "35%" }}>
                      <p style={{ margin: "0" }}>Order Date</p>
                    </div>
                    <div style={{ width: "13%" }}>
                      <p style={{ margin: "0" }}>Amount</p>
                    </div>
                    <div style={{ width: "20%" }}>
                      <p style={{ margin: "0" }}>Status</p>
                    </div>
                  </div>
                  <hr />
                </>
              )}
              {OrdersRec
                ? OrdersRec?.map((el, i) =>
                    window.innerWidth <= 480 ? (
                      <div style={{ padding: "10px 0" }} key={el._id}>
                        <div style={{ fontSize: "11px" }}>
                          Order Id: {el?._id}{" "}
                        </div>
                        <div style={{ fontSize: "11px" }}>
                          Order Date : {GET_TIME_ZONE(el?.createdAt)}
                        </div>
                        <div style={{ fontSize: "11px" }}>
                          Amount :{el?.Amount}
                        </div>
                        <div style={{ fontSize: "11px" }}>
                          Status :{el?.transactionStatus}
                        </div>
                        <hr />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          padding: "10px 0",
                          fontSize: "12.5px",
                        }}
                        key={el._id}
                      >
                        <div style={{ width: "40%" }}>{el?._id} </div>
                        <div style={{ width: "35%" }}>
                          {GET_TIME_ZONE(el?.createdAt)}
                        </div>
                        <div style={{ width: "13%" }}>₹ {el?.Amount}</div>
                        <div style={{ width: "20%" }}>
                          {el?.transactionStatus == "Pending" ? (
                            <Link to={`/order/${el._id}`} target="_blank">
                              Retry Payment
                            </Link>
                          ) : (
                            el?.transactionStatus
                          )}
                        </div>
                      </div>
                    )
                  )
                : ""}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 ? (
        <RightSidePanel
          SetSideBarOpen={setOpenMenuBar2}
          SidebarOpen={OpenMenuBar2}
        />
      ) : (
        <RightSidePanel
          SetSideBarOpen={setOpenMenuBar2}
          SidebarOpen={OpenMenuBar2}
        />
      )}
      {/* Right Section  */}
    </div>
  );
}

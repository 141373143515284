import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore,
} from "redux";
import thunk from "redux-thunk";
import {
  CHANGE_PASSWORD_REDUCER,
  UPDATE_USER_DET_REDUCER,
  USER_FORGOT_PASSWORD_REDUCER,
  USER_LOGIN_REDUCER,
  USER_SIGNUP_REDUCER,
  VERIFY_PASSWORD_URL_REDUCER,
} from "./Reducer/UserReducer";
import { GET_LOCAL } from "./Model/LocalData";
import {
  QuestionListResucer,
  QuestionRecordSubmitReducer,
  TestAnswerDetailsReducer,
  TestResultReducer,
} from "./Reducer/QuestionTestReducer";
import {
  courseListAllRecReducer,
  SelectStudentPackageReducer,
} from "./Reducer/CourseReducer";
import { Book_Demo_Reducer } from "./Reducer/BookDemoReducer";
import {
  Contact_Us_Reducer,
  Test_Book_Reducer,
} from "./Reducer/ContactReducer";
import {
  Take_Test_Det_Reducer,
  Test_Question_List_Reducer,
  Test_Submitted_Reducer,
} from "./Reducer/TLatReducer";
import {
  Package_List_Reducer,
  packageDetailsReducer,
  PurchasedPackagesReducer,
} from "./Reducer/PacakgeReducer";
import {
  AddToCartReducer,
  ApplyCouponReducer,
  DeleteToCartReducer,
  ListCartReducer,
  ListOrdersReducer,
  orderStatusReducer,
  PackageOrderReducer,
} from "./Reducer/OrderPackageReducer";
import {
  listCalssModeReducer,
  listClassTimingReducer,
} from "./Reducer/CommonEnumReducer";
import { classCenterListReducer } from "./Reducer/classCenterReducer";
import {
  mockTestDetailsReducer,
  mockTestListAllRecReducer,
} from "./Reducer/MockTestReducer";
import {
  materialdetReducer,
  materialListAllRecReducer,
} from "./Reducer/MaterialReducer";
import { GurullyDetReducer } from "./Reducer/GurullyReducer";
import { AttendanceRecordsListReducer } from "./Reducer/AttendanceReducer";

const rootReducers = combineReducers({
  USER_LOGIN_REDUCER,
  USER_SIGNUP_REDUCER,
  UPDATE_USER_DET_REDUCER,
  QuestionListResucer,
  QuestionRecordSubmitReducer,
  USER_FORGOT_PASSWORD_REDUCER,
  VERIFY_PASSWORD_URL_REDUCER,
  CHANGE_PASSWORD_REDUCER,
  courseListAllRecReducer,
  Book_Demo_Reducer,
  Contact_Us_Reducer,
  Take_Test_Det_Reducer,
  Test_Submitted_Reducer,
  Test_Question_List_Reducer,
  Package_List_Reducer,
  packageDetailsReducer,
  PackageOrderReducer,
  AddToCartReducer,
  DeleteToCartReducer,
  listClassTimingReducer,
  listCalssModeReducer,
  classCenterListReducer,
  ApplyCouponReducer,
  ListCartReducer,
  ListOrdersReducer,
  orderStatusReducer,
  TestResultReducer,
  TestAnswerDetailsReducer,
  mockTestListAllRecReducer,
  mockTestDetailsReducer,
  materialListAllRecReducer,
  materialdetReducer,
  GurullyDetReducer,
  SelectStudentPackageReducer,
  PurchasedPackagesReducer,

  // Landing page reducer
  Test_Book_Reducer,

  // Attendance Recs
  AttendanceRecordsListReducer,
});

const InitialState = {
  USER_LOGIN_REDUCER: { Login_User: GET_LOCAL("IELTS_USER") },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = legacy_createStore(
  rootReducers,
  InitialState,
  composeEnhancers(applyMiddleware(thunk))
);

import {
  CART_DETAILS_LIST_FAILED,
  CART_DETAILS_LIST_REQ,
  CART_DETAILS_LIST_SUCCESS,
  COUPON_APPLY_FAILED,
  COUPON_APPLY_REQ,
  COUPON_APPLY_SUCCESS,
  ORDER_PACKAGE_FAILED,
  ORDER_PACKAGE_REQ,
  ORDER_PACKAGE_SUCCESS,
  ORDERS_LIST_FAILED,
  ORDERS_LIST_REQ,
  ORDERS_LIST_SUCCESS,
  ORDERS_STATUS_FAILED,
  ORDERS_STATUS_REQ,
  ORDERS_STATUS_SUCCESS,
  PACKAGE_ADD_CART_FAILED,
  PACKAGE_ADD_CART_REQ,
  PACKAGE_ADD_CART_SUCCESS,
  PACKAGE_REMOVE_CART_FAILED,
  PACKAGE_REMOVE_CART_REQ,
  PACKAGE_REMOVE_CART_SUCCESS,
} from "../Constant/OrderPackageConstant";
import api from "../Core/api";

export const PackageOrder = (Details, EmailAddress) => async (dispatch) => {
  // console.log(Details,"OrderDetails")
  try {
    dispatch({ type: ORDER_PACKAGE_REQ });
    Details = { ...Details, EmailAddress };
    const { data } = await api.post(
      EmailAddress ? "/student/order/withemail" : "/student/order",
      Details
    );

    dispatch({ type: ORDER_PACKAGE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_PACKAGE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const RetryPayment = (Details) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_PACKAGE_REQ });
    const { data } = await api.post("/student/order/retry-payment", Details);
    dispatch({ type: ORDER_PACKAGE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_PACKAGE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const ListCarts = () => async (dispatch) => {
  try {
    dispatch({ type: CART_DETAILS_LIST_REQ });
    const { data } = await api.get("/student/cart");
    dispatch({ type: CART_DETAILS_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_DETAILS_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const ListOrders = () => async (dispatch) => {
  try {
    dispatch({ type: ORDERS_LIST_REQ });
    const { data } = await api.get("/student/order");
    dispatch({ type: ORDERS_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDERS_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const orderStatus = (Id) => async (dispatch) => {
  try {
    dispatch({ type: ORDERS_STATUS_REQ });
    const { data } = await api.get("/student/order/" + Id);
    dispatch({ type: ORDERS_STATUS_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDERS_STATUS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const AddToCart = (Details, apiUrl) => async (dispatch) => {
  try {
    dispatch({ type: PACKAGE_ADD_CART_REQ });
    const { data } = await api.post(`/${apiUrl}`, Details);
    dispatch({ type: PACKAGE_ADD_CART_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: PACKAGE_ADD_CART_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const DeleteToCart = (Id) => async (dispatch) => {
  try {
    dispatch({ type: PACKAGE_REMOVE_CART_REQ });
    const { data } = await api.delete(`/student/cart/${Id}`);
    dispatch({ type: PACKAGE_REMOVE_CART_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: PACKAGE_REMOVE_CART_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const ApplyCoupon = (CouponData) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_APPLY_REQ });
    const { data } = await api.get(`/student/coupon?CouponCode=${CouponData}`);

    dispatch({ type: COUPON_APPLY_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: COUPON_APPLY_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const BestSellingCourseModel = [
  { Name: "Best selling course" },
  { Name: "Best selling course" },
  { Name: "Best selling course" },
  { Name: "Best selling course" },
  { Name: "Best selling course" },
  { Name: "Best selling course" },
];

export const LiveMockModel = [
  { Name: "Live" },
  { Name: "Live" },
  { Name: "Live" },
  { Name: "Live" },
];

export const BookCardModel = [
  {
    Name: "",
    Type: "E-Book",
    Price: "Free",
    Image: "/assets/image/IELTS_BOOK.png",
  },
  {
    Name: "",
    Type: "E-Book",
    Price: "299",
    Image: "/assets/image/IELTS_BOOK.png",
  },
  {
    Name: "",
    Type: "E-Book",
    Price: "299",
    Image: "/assets/image/IELTS_BOOK.png",
  },
  {
    Name: "",
    Type: "E-Book",
    Price: "299",
    Image: "/assets/image/IELTS_BOOK.png",
  },
  {
    Name: "",
    Type: "E-Book",
    Price: "299",
    Image: "/assets/image/IELTS_BOOK.png",
  },
  {
    Name: "",
    Type: "E-Book",
    Price: "299",
    Image: "/assets/image/IELTS_BOOK.png",
  },
  {
    Name: "",
    Type: "E-Book",
    Price: "299",
    Image: "/assets/image/IELTS_BOOK.png",
  },
];

export const BookForm_Model = {
  First_Name: "",
  Last_Name: "",
  EmailAddress: "",
  PhoneNo: "",
  CourseInterestedIn: "",
  BookingDate: "",
  BookingTime: "",
};

export const ContactUs_Model = {
  First_Name: "",
  Last_Name: "",
  EmailAddress: "",
  PhoneNo: "",
  Query: "",
  // CourseInterestedIn: "any",
};

export const Testimonials_Model = [
  {
    Name: "Anaahiita Rishi",
    Details:
      "I recently took IELTS coaching from Testifyi and scored an overall 8 band, which is my dream score to pursue the course I wanted— a master's in Speech-Language Pathology in Australia. I highly recommend Testifyi for IELTS training because they provide materials and mock tests in a timely manner, which greatly improved my efficiency and performance during the exam. I am always grateful to them and encourage everyone to choose Testifyi for their IELTS preparation",
    extra: "Overall 8 Band, IELTS",
    Image: "/assets/image/Testimonials/Anaahiita-Rishi.png",
  },

  {
    Name: "Aadya",
    Details:
      "I achieved an overall band score of 7.5 in IELTS. I discovered Testifyi while surfing the internet. My overall experience was really good, and Testifyi helped me achieve my desired band score. I highly recommend Testifyi to others as well.",
    extra: "Overall 7.5 Band, IELTS",
    Image: "/assets/image/Testimonials/aadhya.png",
  },

  {
    Name: "Tanpreet",
    Details:
      "I recently received my IELTS results and achieved an overall band score of 7.5. With this score, I was able to secure admission to UCD Smurfit Business School in Ireland. I enrolled in Testifyi classes, and I am thankful to the tutor for all their efforts and for simplifying the entire IELTS process. I highly recommend everyone to enroll in these classes and experience it firsthand.",
    extra: "Overall 7.5 Band, IELTS",
    Image: "/assets/image/Testimonials/tanpreet.png",
  },

  {
    Name: "Sansita",
    Details:
      "I am a student, and I took classes from Testifyi. I cleared my IELTS with an overall band score of 8, and I got a 9 in reading. Testifyi helped me achieve my desired band score. I definitely recommend Testifyi to anyone looking to study abroad.",
    extra: "Overall 8 Band, IELTS",
    Image: "/assets/image/Testimonials/sansta.png",
  },

  {
    Name: "Riya",
    Details:
      "With the help of Testifyi, I scored 82 out of 90 in the PTE. A friend of mine recommended that I join Testifyi, and I was able to achieve my desired score with the help of various templates and multiple mock tests they provided. I would definitely recommend Testifyi to everyone planning to study abroad and aiming to excel in IELTS or PTE. I will surely recommend joining Testifyi.",
    extra: "Scored 82, PTE",
    Image: "/assets/image/Testimonials/Riya.png",
  },

  {
    Name: "Pranav",
    Details:
      "I scored 73 out of 90 in the PTE because Testifyi helped me achieve this score easily. They cleared all my doubts and taught me several useful tricks. I would definitely recommend Testifyi to my friends and family.",
    extra: "Scored 73, PTE",
    Image: "/assets/image/Testimonials/Pranav.png",
  },
];

export const Package_Type_Model = [
  {
    name: "Basic",
    id: "1",
    color: "#8FA1FE",
  },
  {
    name: "Star",
    id: "2",
    color: "#3CCE63",
  },
  {
    name: "Premium",
    id: "3",
    color: "#E12ADB",
  },
  {
    name: "Advance",
    id: "4",
    color: "#6C1BE5",
  },
];

export const Get_Package_Type = (Id) => {
  for (let i = 0; i < Package_Type_Model?.length; i++) {
    if (Package_Type_Model[i].id == Id) {
      return Package_Type_Model[i];
    }
  }

  return {};
};

export const GET_TIME_ZONE = (time) => {
  var date = new Date(time);
  return (
    date.getDate() +
    " " +
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getFullYear() +
    " " +
    "at " +
    date.toLocaleTimeString()
  );
};

export const GET_SCORE_TEXT = (score) => {
  if (score <= 15) {
    return "Every expert was once a beginner,  Let’s take your skills to the next level with our IELTS/PTE preparation!";
  } else if (score > 15 && score <= 35) {
    return "You’re closer than you think! Enhance your performance with our expert IELTS/PTE coaching.";
  } else if (score > 35 && score <= 55) {
    return "Solid foundation!  Our IELTS/PTE course can help you achieve the band score you’re aiming for.";
  } else if (score > 55) {
    return "Fantastic,YOU ARE READY TO EXCEL! Our advanced TESTIFYI course can take you even higher.";
  }
};

export const faqs = [
  {
    id: 1,
    header: "What is Testify?",
    text: `Testify is a platform that helps people prepare for English language tests like IELTS, PTE, and the Duolingo English Test. We also offer General English classes to improve your overall English skills.`,
  },
  {
    id: 2,
    header: "What services does Testify offer?",
    text: `Testify provides preparation courses for IELTS (Academic and General), PTE (Academic and Core), the Duolingo English Test, and General English classes for all levels.`,
  },
  {
    id: 3,
    header: "How can Testify help me improve my English?",
    text: `Testify gives you personalized lessons to help you reach your goals. Whether you're preparing for a test or just want to improve your English, our teachers and study plans will help you succeed.`,
  },
  {
    id: 4,
    header: "Why choose Testify for your English test preparation?",
    text: `At Testify, we offer expert guidance, flexible schedules, and useful resources to help you do well in your English exams. Our methods have helped many students get high scores.`,
  },
  {
    id: 5,
    header: "Can I take courses online with Testify?",
    text: `Yes! Testify offers online classes so you can prepare for your English test from anywhere, with the help of our expert teachers.`,
  },
  {
    id: 6,
    header:
      "What makes Testify different from other English training providers?",
    text: `Testify offers personalized lessons, experienced teachers, and flexible learning options. We make sure to give each student the support they need to achieve their goals.`,
  },
];

export const FAQS_LandingPage = [
  {
    id: 10,
    header: "What is T-LAT?",
    text: `T-LAT is a free test designed to assess your IELTS?PTE readiness.`,
  },

  {
    id: 11,
    header: "How long does it take?",
    text: `The test takes just 15 minutes.`,
  },

  {
    id: 12,
    header: "Is it really free?",
    text: `Yes, 100% free with no hidden charges.`,
  },

  {
    id: 13,
    header: "What happens after the test?",
    text: `You’ll receive a detailed report with expert recommendations.
`,
  },
];

export const FAQS_TRT = [
  {
    id: 10,
    header: "What is TRT: Testifyi Reality Test?",
    text: `TRT is a specially designed mock IELTS/PTE test that allows students to:
Experience the real IELTS/PTE exam environment.
Assess their current skill level. Receive detailed feedback to improve performance in Listening, Reading, Writing, and Speaking`,
  },
  {
    id: 11,
    header: "What is the cost of the test?",
    text: `The test is priced at just ₹99—a highly affordable opportunity to experience a real exam-like environment.`,
  },
  {
    id: 12,
    header: "When and where will the event be conducted?",
    text: `The event is scheduled for January 18, 2025, at Nehru Place, Delhi & On February 1, 2025, at Sector 35C, Chandigarh, from 10:00 AM to 5:00 PM.`,
  },
  {
    id: 13,
    header: "What documents should I bring along?",
    text: `Please bring the following:
A valid photo ID (Aadhaar card, passport, or student ID).
Your registration confirmation email or message.
Basic stationery like pens, pencils, and erasers.`,
  },
  {
    id: 14,
    header: "What is included in the test?",
    text: `The test includes all four sections of the IELTS/PTE exam: Listening, Reading, Writing, and Speaking. You’ll get a complete experience of the actual test format.`,
  },
  {
    id: 15,
    header: "When will my Speaking module test be conducted?",
    text: `The Speaking test will be conducted on the same day as the event. Specific time slots will be assigned 2 days prior to the test day.`,
  },
  {
    id: 16,
    header: "Will I be notified about my Speaking test time slot?",
    text: `Yes, your Speaking test time slot will be shared with you 2 days prior to the test day.`,
  },
  {
    id: 17,
    header: "Who can participate in this event?",
    text: `This test is ideal for students preparing for IELTS/PTE, whether you're a beginner or looking to refine your skills before the actual exam.`,
  },

  {
    id: 18,
    header: "Will I receive feedback on my performance?",
    text: `Yes, our IELTS/PTE experts will provide personalized feedback after the test, helping you understand your strengths and areas for improvement.`,
  },

  {
    id: 19,
    header: "How can I register for the test?",
    text: `Click the [Register Now for ₹99] button, fill in your details, and a Testifyi coordinator will reach out to help you secure your spot.`,
  },

  {
    id: 20,
    header: "What happens if I miss the test?",
    text: `Unfortunately, the fee is non-refundable. However, you may contact us to explore any future test opportunities.`,
  },
];

export const FAQS_TRT2 = [
  {
    id: 10,
    header: "What’s included in the ₹99 IELTS webinar?",
    text: `The ₹99 webinar includes expert strategies and tips for all IELTS sections: Listening, Reading, Speaking, and Writing. Our experienced trainers will help you tackle common challenges and guide you on improving your band scores effectively.`,
  },
  {
    id: 11,
    header: "How does the 50% off on the full IELTS course work?",
    text: `After attending the webinar, you’ll receive a 50% discount on our full IELTS course. This course will dive deeper into each section, providing personalized training and practice sessions to help you achieve your target score.`,
  },
  {
    id: 12,
    header: "How do I qualify for the 100% refund on my IELTS fees?",
    text: `If you choose to apply for study abroad services with us, we will refund the full amount of your IELTS fees once your Visa is granted. This is part of our exclusive offer when you enroll with one of our study abroad partners.`,
  },
  {
    id: 13,
    header: "Are there any hidden charges for study abroad services?",
    text: `No! Our study abroad services are completely free of charge with no hidden costs. You’ll get comprehensive support for university applications, visa processes, and more, at no additional fee.`,
  },
  {
    id: 14,
    header:
      "How soon can I register for the full IELTS course after attending the webinar?",
    text: `You can register for the full IELTS course immediately after attending the webinar and claim your 50% discount. Our team will guide you through the registration process to ensure you get started right away.`,
  },
];

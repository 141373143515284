import { IoIosArrowDroprightCircle } from "react-icons/io";
export default function SliderArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "white" }}
      onClick={onClick}>
      <IoIosArrowDroprightCircle />
    </div>
  );
}

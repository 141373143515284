import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./LiveTestAttempted.module.css";
import { useState } from "react";
import { ImCross } from "react-icons/im";
import { useEffect } from "react";
import { MdDoneOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Test_Result,
  TestAnswerDetails,
} from "../../Action/QuestionTestAction";
import ReactHelmet from "../../Components/ReactHelmet";

export default function LiveTestAttempted() {
  const { AnsweDet } = useSelector((s) => s.TestAnswerDetailsReducer);
  const dispatch = useDispatch();
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const { TLatResult } = useSelector((s) => s.TestResultReducer);
  const Mobile_Screen = window.innerWidth;

  useEffect(() => {
    dispatch(TestAnswerDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(Test_Result());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={Styles.Dashboard}>
      {/* SidePanel */}
            <ReactHelmet
              title={"T-LAT"}
              description={""}
            />

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="T-LAT (Attempt)"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
           Page="T-LAT (Attempt)"
        />
      ) : (
        ""
      )}
      
      {/* Middle Section */}
      {!SidebarOpen ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              PageName={"T-LAT (Attempt)"}
            />
          </div>
          <div className={Styles.OnlineClassTopSection}>
            <div>
              {/* <p>21 Aug 2024 10:00 AM</p> */}
              <p style={{ fontWeight: "600", fontSize: "18px" }}>
                Scored :{TLatResult}
              </p>
            </div>
            <table className={Styles.QuestionSec2}>
              <tbody>
                <tr>
                  <td rowspan={1}>
                    {AnsweDet?.map((ev, i) => (
                      <div>
                        <div>
                          <h4 style={{ fontSize: "20px" }}>Q:{i + 1}</h4>
                          <p className={Styles.Questiondet}>{ev?.Question}</p>
                        </div>
                        <div
                          style={{ margin: "10px 0" }}
                          className={Styles.OptionContainer}
                        >
                          {ev?.Options?.map((el, j) => (
                            <div className={Styles.Options}>
                              <input
                                type="radio"
                                disabled={true}
                                checked={
                                  ev?.AttemptedAnswer === el ? true : false
                                }
                              />
                              <p
                                style={{
                                  color:
                                    ev?.CorrectAnswer === el
                                      ? "rgb(64, 225, 64)"
                                      : ev?.AttemptedAnswer === el &&
                                        ev?.CorrectAnswer != ev?.AttemptedAnswer
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {el}
                              </p>
                            </div>
                          ))}
                          {!ev?.AttemptedAnswer ? (
                            <p style={{ color: "grey" }}>
                              {" "}
                              <ImCross /> Not Attempted
                            </p>
                          ) : ev?.CorrectAnswer == ev?.AttemptedAnswer ? (
                            <p style={{ color: "rgb(64, 225, 64)" }}>
                              {" "}
                              <MdDoneOutline /> Correct Answer
                            </p>
                          ) : (
                            <p style={{ color: "red" }}>
                              {" "}
                              <ImCross /> Wrong Answer
                            </p>
                          )}
                        </div>{" "}
                      </div>
                    ))}
                  </td>
                </tr>

                {/* <tr>
                  <td>
                    <div>
                      <div className={Styles.NumberQuestionData}>
                        <span>
                          Number of Attempted :{" "}
                          <b style={{ color: "rgb(132, 225, 132)" }}> 0</b>
                        </span>
                        <span>
                          Number of Pending :{" "}
                          <b style={{ color: "blue" }}>30</b>
                        </span>
                        <div className={Styles.QuestionBtns}>
                          <button
                            disabled={CurrentQuestionNumber === 0}
                            className={
                              CurrentQuestionNumber === 0
                                ? Styles.disabled
                                : Styles.active
                            }
                            onClick={() => handlePrev()}
                          >
                            PREV
                          </button>
                          {QuestionData?.length &&
                          QuestionData?.length - 1 == CurrentQuestionNumber &&
                          CurrentQuestionNumber !== 0 ? (
                            <button onClick={() => handleSubmitForm()}>
                              SUBMIT
                            </button>
                          ) : (
                            <button onClick={() => handleNext()}>NEXT</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={Styles.NumberQuestionData}>
                      <span>
                        Number of Questions : <b> 30</b>
                      </span>
                      <span>
                        Time : <b>15 min</b>
                      </span>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </section>
      ) : (
        ""
      )}

      {Mobile_Screen >= 780 ? <RightSidePanel /> : ""}
      {/* Right Section  */}
    </div>
  );
}

import axios from "axios";
import {
  QUESTION_RECORD_LIST_FAILED,
  QUESTION_RECORD_LIST_REQ,
  QUESTION_RECORD_LIST_SUCCESS,
  SUBMIT_QUESTION_RECORD_FAILED,
  SUBMIT_QUESTION_RECORD_REQ,
  SUBMIT_QUESTION_RECORD_SUCCESS,
  TLAT_ANSWER_RESULT_FAILED,
  TLAT_ANSWER_RESULT_REQ,
  TLAT_ANSWER_RESULT_SUCCESS,
  TLAT_TEST_RESULT_FAILED,
  TLAT_TEST_RESULT_REQ,
  TLAT_TEST_RESULT_SUCCESS,
} from "../Constant/QuestionTestConstant";
import api from "../Core/api";

export const Question_List = (Id) => async (dispatch) => {
  try {
    dispatch({ type: QUESTION_RECORD_LIST_REQ });
    const { data } = await api.get(`/student/Psychometric/${Id}`);
    dispatch({ type: QUESTION_RECORD_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: QUESTION_RECORD_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Question_Data_Submit = (AnsweData, Id) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_QUESTION_RECORD_REQ });
    const { data } = await api.post(`/student/Psychometric/${Id}`, AnsweData);

    dispatch({
      type: SUBMIT_QUESTION_RECORD_SUCCESS,
      payload: data?.Result ? data?.Result : data.Result,
    });
  } catch (error) {
    dispatch({
      type: SUBMIT_QUESTION_RECORD_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Test_Result = () => async (dispatch) => {
  try {
    dispatch({ type: TLAT_TEST_RESULT_REQ });

    const { data } = await api.get("/student/Psychometric/result");
    dispatch({ type: TLAT_TEST_RESULT_SUCCESS, payload: data.Result });
  } catch (error) {
    dispatch({
      type: TLAT_TEST_RESULT_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const TestAnswerDetails = () => async (dispatch) => {
  try {
    dispatch({ type: TLAT_ANSWER_RESULT_REQ });

    const { data } = await api.get("/student/Psychometric");
    dispatch({ type: TLAT_ANSWER_RESULT_SUCCESS, payload: data.Result });
  } catch (error) {
    dispatch({
      type: TLAT_ANSWER_RESULT_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

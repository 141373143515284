export const DetailsFormJSon = {
  name: "",
  email: "",
  phone: "",
  city: "",
  location: "",
  timeSlot: "",
};

export const ValidatorForm = (data) => {
  let Error = "";
  if (!data?.name) {
    Error += "Name is required !";
  }

  if (
    !data?.email ||
    !data?.email.includes(".") ||
    !data?.email.includes("@")
  ) {
    Error += "Valid Email is required !";
  }

  if (!data?.phone || data?.phone?.length < 10) {
    Error += "Valid Phone No is required !";
  }

  if (!data?.city) {
    Error += "City is required !";
  }

  if (!data?.location) {
    Error += "Location No is required !";
  }

  if (!data?.timeSlot) {
    Error += "Time Slot is required !";
  }

  if (Error) {
    return "Error - " + Error;
  } else {
    return "success";
  }
};


export const ValidatorForm2 = (data) => {
  let Error = "";
  if (!data?.name) {
    Error += "Name is required !";
  }

  if (
    !data?.email ||
    !data?.email.includes(".") ||
    !data?.email.includes("@")
  ) {
    Error += "Valid Email is required !";
  }

  if (!data?.phone || data?.phone?.length < 10) {
    Error += "Valid Phone No is required !";
  }

  // if (!data?.city) {
  //   Error += "City is required !";
  // }

  // if (!data?.location) {
  //   Error += "Location No is required !";
  // }

  // if (!data?.timeSlot) {
  //   Error += "Time Slot is required !";
  // }

  if (Error) {
    return "Error - " + Error;
  } else {
    return "success";
  }
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCourses } from "../../Action/CourseAction";

const CourseDropDown = React.memo(() => {
  const { CouresesRec } = useSelector((s) => s.courseListAllRecReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listCourses());
  }, [dispatch]);

  return (
    <>
      {CouresesRec ? (
        CouresesRec?.map((ev, i) => (
          <option key={ev?._id} value={ev?._id}>
            {ev?.CourseName}
          </option>
        ))
      ) : (
        <></>
      )}
    </>
  );
});

export default CourseDropDown;

import { useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar2 from "../../Components/Navbar2";
import Styles from "./PrivacyPolicy.module.css";
import ReactHelmet from "../../Components/ReactHelmet";
export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReactHelmet title={"Privacy Policy"} description={""} />
      {/* navbar Section */}
      <Navbar2 />
      <section className={Styles.showcase}>
        <img src={"/assets/image/Privacy-Policy.jpg"} alt="Banner" />
        <div className={Styles.overlay}>
          <div>
            <div>
              <p>
                <span> HOME</span> /<span> PRIVACY POLICY </span>{" "}
              </p>
            </div>
            <div>
              <h1 style={{ fontSize: "40px" }} className="Font_Rajdhani">
                Privacy Policy
              </h1>
            </div>
            <div>
              <p>
                Our Privacy Policy outlines how we collect, use, and protect
                your personal <br />
                information, ensuring your privacy and data security.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={Styles.Margin2}>
        <div>
          <div>
            <p>
              <strong>
                Thank you for being part of our community at Testifyi&nbsp;
                (“Testifyi,” “we,” “us,” or “our”).
              </strong>
            </p>
            <p>
              <span>
                We are dedicated to safeguarding your personal information and
                your right to privacy. If you have any questions or concerns
                about this policy or how we handle your personal information,
                feel free to contact us at info@testifyi.com.
              </span>
            </p>
            <p>
              <span>When you visit our website (</span>
              <a
                href="https://www.testifyi.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.testifyi.com
              </a>
              <span>
                ) or use our services, you trust us with your personal data. We
                take this responsibility seriously. This Privacy Policy explains
                what information we collect, how we use it, and what rights you
                have concerning your data. We encourage you to review it
                carefully.
              </span>
            </p>
            <p>
              <span>
                If you disagree with any part of this policy, please stop using
                our website and services.
              </span>
            </p>
            <p>
              <span>
                This Privacy Policy applies to all information collected through
                our website (
              </span>
              <a
                href="https://www.testifyi.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.testifyi.com
              </a>
              <span>
                ), and any related services, sales, marketing, or events
                (collectively referred to as "Services").
              </span>
            </p>
            <p>
              <span>
                Please read this policy thoroughly to make informed decisions
                about sharing your personal information with us.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>Table of Contents</strong>
            </h3>
            <ol>
              <li>
                <span>What Information Do We Collect?</span>
              </li>
              <li>
                <span>How Do We Use Your Information?</span>
              </li>
              <li>
                <span>Will Your Information Be Shared With Anyone?</span>
              </li>
              <li>
                <span>Who Will Your Information Be Shared With?</span>
              </li>
              <li>
                <span>Do We Use Cookies and Tracking Technologies?</span>
              </li>
              <li>
                <span>Do We Use Google Maps?</span>
              </li>
              <li>
                <span>How Do We Handle Your Social Logins?</span>
              </li>
              <li>
                <span>How Long Do We Keep Your Information?</span>
              </li>
              <li>
                <span>How Do We Keep Your Information Safe?</span>
              </li>
              <li>
                <span>What Are Your Privacy Rights?</span>
              </li>
              <li>
                <span>Data Breach</span>
              </li>
              <li>
                <span>Controls for Do-Not-Track Features</span>
              </li>
              <li>
                <span>Policy Updates</span>
              </li>
              <li>
                <span>Contact Information</span>
              </li>
            </ol>
            <h3 className={Styles.H3}>
              <strong>1. What Information Do We Collect?</strong>
            </h3>
            <p>
              <strong>Personal Information You Provide</strong>
            </p>
            <p>
              <span>
                We collect personal information you voluntarily provide when
                interacting with our Services. This includes your name, contact
                details, payment information, login credentials, and more. The
                data we collect depends on how you interact with us and which
                services you use.
              </span>
            </p>
            <ul>
              <li>
                <strong>Publicly Available Information</strong>
                <span>: Name, phone number, email address, etc.</span>
              </li>
              <li>
                <strong>Credentials</strong>
                <span>
                  : Passwords and security details for account access.
                </span>
              </li>
              <li>
                <strong>Payment Information</strong>
                <span>
                  : Details for processing transactions via Razorpay, Paypal,
                  Apple Pay, and Google Pay.
                </span>
              </li>
              <li>
                <strong>Social Media Login Data</strong>
                <span>
                  : Information obtained when you register via social media
                  accounts (e.g., Facebook, Google).
                </span>
              </li>
            </ul>
            <p>
              <span>
                All information you provide must be accurate and updated. Notify
                us of any changes to your personal data.
              </span>
            </p>
            <p>
              <strong>Automatically Collected Information</strong>
            </p>
            <p>
              <span>
                When you use our Services, we automatically gather technical
                information like IP address, browser type, and usage data to
                maintain security and performance.
              </span>
            </p>
            <p>
              <strong>Information from Other Sources</strong>
            </p>
            <p>
              <span>
                We may receive additional data from public sources, marketing
                partners, or social media platforms (e.g., Facebook).
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>2. How Do We Use Your Information?</strong>
            </h3>
            <p>
              <span>
                We use your information for various business purposes, including
                but not limited to:
              </span>
            </p>
            <ul>
              <li>
                <span>Account creation and management</span>
              </li>
              <li>
                <span>
                  Marketing and promotional communications (you can opt out
                  anytime)
                </span>
              </li>
              <li>
                <span>Order fulfillment and management</span>
              </li>
              <li>
                <span>Requesting feedback</span>
              </li>
              <li>
                <span>Improving our services</span>
              </li>
              <li>
                <span>Legal and contractual obligations</span>
              </li>
            </ul>
            <h3 className={Styles.H3}>
              <strong>3. Will Your Information Be Shared?</strong>
            </h3>
            <p>
              <span>
                We share information only when necessary for business purposes,
                legal compliance, or with your consent. Examples include:
              </span>
            </p>
            <ul>
              <li>
                <strong>Service Providers</strong>
                <span>: Payment processing, customer support, etc.</span>
              </li>
              <li>
                <strong>Business Partners</strong>
                <span>: To offer specific products or services.</span>
              </li>
              <li>
                <strong>Legal Requirements</strong>
                <span>: Compliance with applicable laws and regulations.</span>
              </li>
            </ul>
            <h3 className={Styles.H3}>
              <strong>4. Who Will Your Information Be Shared With?</strong>
            </h3>
            <p>
              <span>
                We only share information with trusted third parties such as:
              </span>
            </p>
            <ul>
              <li>
                <span>Payment processors (PhonePe)</span>
              </li>
              <li>
                <span>Marketing platforms (Facebook, Google)</span>
              </li>
              <li>
                <span>Cloud services&nbsp;</span>
              </li>
              <li>
                <span>Customer communication tools (whatsapp)</span>
              </li>
            </ul>
            <h3 className={Styles.H3}>
              <strong>5. Do We Use Cookies and Tracking Technologies?</strong>
            </h3>
            <p>
              <span>
                Yes, we use cookies and other tracking tools to improve your
                experience. Learn more in our Cookie Policy.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>6. Do We Use Google Maps?</strong>
            </h3>
            <p>
              <span>
                Yes, we integrate Google Maps for location-based services. By
                using these features, you agree to Google’s Terms of Service.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>7. How Do We Handle Social Logins?</strong>
            </h3>
            <p>
              <span>
                If you register using a social media account (e.g., Facebook or
                Google), we receive information from that platform and use it to
                manage your account.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>8. How Long Do We Keep Your Information?</strong>
            </h3>
            <p>
              <span>
                We retain your data only as long as necessary to fulfill the
                purposes outlined in this policy, unless otherwise required by
                law.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>9. How Do We Keep Your Information Safe?</strong>
            </h3>
            <p>
              <em>In Short:</em>
              <span>
                {" "}
                We prioritize the protection of your personal information by
                using appropriate organizational and technical security
                measures.
              </span>
            </p>
            <p>
              <span>
                We have implemented measures designed to safeguard the security
                of the personal information we process. However, no method of
                transmitting data over the internet or storing it electronically
                is completely secure. While we work to protect your information,
                transmitting your data to and from our Services is done at your
                own risk. We recommend that you access our services in a secure
                environment.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>
                10. What Are Your Privacy Rights under the DPDP Act?
              </strong>
            </h3>
            <p>
              <span>
                In Short: The Digital Personal Data Protection (DPDP) Act,
                passed in August 2023, grants specific rights over your personal
                data. These rights are designed to balance your ability to
                protect your data with the lawful processing of that data for
                legitimate purposes.
              </span>
            </p>
            <p>
              <span>
                Under the DPDP Act, if you are a Data Principal (the individual
                whose personal data is being processed), you have the following
                rights:
              </span>
            </p>
            <p>
              <span>
                Right to Access: You can request details of the personal data we
                collect and process about you.
              </span>
            </p>
            <p>
              <span>
                Right to Correction: You can ask us to correct any inaccurate or
                incomplete personal data.
              </span>
            </p>
            <p>
              <span>
                Right to Erasure: You may request that we delete your personal
                data when it is no longer necessary for the purpose it was
                collected, or if you withdraw consent for its processing.
              </span>
            </p>
            <p>
              <span>
                Right to Data Portability: You can request a copy of your
                personal data in a structured, machine-readable format and
                transfer it to another data fiduciary.
              </span>
            </p>
            <p>
              <span>
                Right to Restrict Processing: In certain circumstances, you may
                request limitations on the processing of your data.
              </span>
            </p>
            <p>
              <span>
                Right to Withdraw Consent: If we process your personal data
                based on your consent, you may withdraw that consent at any
                time. However, withdrawing consent will not affect the
                processing of data that occurred before the withdrawal.
              </span>
            </p>
            <p>
              <span>
                To exercise any of these rights, you can contact us using the
                information provided below. We will assess and respond to your
                request according to the guidelines of the DPDP Act and
                applicable laws.
              </span>
            </p>
            <p>
              <span>
                Grievance Mechanism: If you believe your personal data has been
                mishandled, you can file a grievance with our Data Protection
                Officer (DPO). If you are not satisfied with the resolution, you
                may escalate the matter to the Data Protection Board of India
                for further adjudication.
              </span>
            </p>
            <p>
              <strong>Account Information:</strong>
            </p>
            <p>
              <span>
                If you would like to review, modify, or delete the information
                associated with your account, you can:
              </span>
            </p>
            <p>
              <span>Contact us using the details provided.</span>
            </p>
            <p>
              <span>
                Upon your request to terminate your account, we will deactivate
                or delete your account and remove your data from active systems.
                However, some information may be retained for fraud prevention,
                to comply with legal obligations, or for other legitimate
                purposes.
              </span>
            </p>
            <p>
              <strong>Cookies and Tracking:</strong>
            </p>
            <p>
              <span>
                Most browsers are set to accept cookies by default. If you
                prefer, you can adjust your browser settings to reject cookies.
                Please note that rejecting cookies may affect certain
                functionalities of our services.
              </span>
            </p>
            <p>
              <strong>Opting Out of Marketing Communications:</strong>
            </p>
            <p>
              <span>
                You can unsubscribe from marketing emails at any time by
                clicking the "unsubscribe" link in those emails or contacting us
                directly. However, even after opting out of marketing emails,
                you may still receive service-related communications necessary
                for managing your account.
              </span>
            </p>
            <p>
              <span>
                If you have any questions about your privacy rights under the
                DPDP Act, you can contact us at info@testifyi.com.
              </span>
            </p>
            <p>
              <span>
                This version aligns with the DPDP Act and integrates the key
                rights and obligations outlined by the legislation.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>11. Data Breach</strong>
            </h3>
            <p>
              <span>
                A data breach occurs when unauthorized access, use, disclosure,
                or destruction of personal data happens. If a breach occurs that
                poses a risk of serious harm (e.g., financial or personal
                damage), Testifyi will notify you promptly. We will also inform
                the appropriate Supervisory Authority within 72 hours unless the
                breach is deemed unlikely to harm the rights of individuals.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>12. Do-Not-Track Features</strong>
            </h3>
            <p>
              <span>
                Many browsers and mobile systems offer Do-Not-Track (DNT)
                settings, allowing users to signal their preference to avoid
                tracking of their online activities. However, since there is no
                established standard for DNT, we currently do not respond to
                these signals. If standards change, we will update this policy
                accordingly.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>13. Do We Update This Policy?</strong>
            </h3>
            <p>
              <em>In Short:</em>
              <span>
                {" "}
                Yes, we will update this policy as necessary to comply with
                changing laws or practices.
              </span>
            </p>
            <p>
              <span>
                We may revise this policy periodically. The updated policy will
                be marked with a new “Revised” date and take effect immediately.
                If material changes are made, we may notify you via email or
                prominently post a notice on our website. Please review this
                policy regularly to stay informed on how we are protecting your
                data.
              </span>
            </p>
            <h3 className={Styles.H3}>
              <strong>14. How Can You Contact Us About This Policy?</strong>
            </h3>
            <p>
              <span>
                If you have any questions or comments regarding this policy, you
                can reach us by email at info@testifyi.com
              </span>
            </p>
            <p>
              <strong>
                How Can You Review, Update, or Delete the Data We Collect?
              </strong>
            </p>
            <p>
              <span>
                In some jurisdictions, you have the right to request access to,
                change, or delete the personal data we have collected about you.
                To make such a request, email us at info@testifyi.com. We will
                respond within 30 days.
              </span>
            </p>
            <p>
              <strong>Infringing Material</strong>
            </p>
            <p>
              <span>
                If you find material on our website that infringes your or
                someone else’s copyright, report it by emailing us at
                info@testifyi.com.
              </span>
            </p>
          </div>
        </div>
      </section>

      {/* Top Footer Section*/}
      <section className={"Footer_Top"}>
        <div>
          <div>
            <h1 className={"Font_Rajdhani"}>
              Start Your IELTS Journey With The Best
            </h1>
          </div>
          <div>
            <button className={"Font_Rajdhani"}>Create Account</button>
            <button className={"Font_Rajdhani"}>Login Now</button>
          </div>
        </div>
      </section>
      {/* Footer Section */}
      <Footer />
    </>
  );
}

import { IoIosCloseCircle, IoMdArrowRoundBack } from "react-icons/io";
import "./BookADemo.css";
import { MdOutlineDone } from "react-icons/md";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function IELTSQ({ setQuestionSec }) {
  const Step1Q = [
    { Q: "IELTS", Option: "A", id: "670cdc063ac09dd1afe0b5af" },
    { Q: "PTE", Option: "B", id: "670cdc0a3ac09dd1afe0b5b4" },
    { Q: "Duolingo", Option: "C", id: "670cdc133ac09dd1afe0b5ba" },
    { Q: "General English", Option: "D", id: "670cdc1e87bfc3160986a2ab" },
  ];

  const Navigate = useNavigate();
  const [QuestionSelected, setQuestionSelected] = useState({
    IELTS_TEST: "",
    Step_1: "",
    Step_2: "",
    Step_3: "",
  });
  const Step2_Ielts = [
    {
      Q: "Academic",
      Option: "A",
      id: "Academic",
    },
    { Q: "General", Option: "B", id: "General" },
  ];

  const Step2_PTE = [
    {
      Q: "PTE Academic",
      Option: "A",
      id: "PTE Academic",
    },
    { Q: "PTE CORE", Option: "B", id: "PTE CORE" },
  ];
  const Step3 = [
    { Q: "Paper Based Test", Option: "A", id: 1 },
    { Q: "Computer Based Test", Option: "B", id: 2 },
  ];

  const [Step, setStep] = useState(1);

  const handleStep1 = (Q, id) => {
    setTimeout(() => {
      if (Q == "IELTS" || Q == "PTE") {
        setStep(2);
      } else {
        Navigate(`/course?courseid=${id}`);
      }
    }, 500);

    setQuestionSelected({ ...QuestionSelected, IELTS_TEST: Q, Step_1: id });
  };

  const handleStep2 = (Q, id) => {
    setTimeout(() => {
      setStep(3);
    }, 500);
    setQuestionSelected({ ...QuestionSelected, Step_2: Q });
  };

  const handleBack = (e) => {
    e.preventDefault();

    if (Step === 3) {
      setStep(2);
    } else if (Step === 2) {
      setStep(1);
    }
  };

  return (
    <>
      <div
        id="CreatContact"
        style={{ width: "100%" }}
        className={"overlay-note middle-hand w100 BookDemo"}
      >
        <div className="container cont2" id="booking-container">
          <div className="TopHeading_Poup">
            <h1>CHOOSE THE RIGHT COURSE FOR YOUR SUCCESS</h1>
            <p>HELP US UNDERSTAND YOUR COURSE PREFERENCES</p>
          </div>
          <div onClick={() => setQuestionSec(false)} className="closeBtn_Book">
            <span>
              <IoIosCloseCircle />
            </span>
          </div>
          <div onClick={handleBack} className="Back_btn_Popup">
            <span>
              <IoMdArrowRoundBack />
            </span>
          </div>

          {Step == 1 ? (
            <div className="Question_head">
              <h2 style={{ textAlign: "left" }}>
                {" "}
                <span>1</span>{" "}
                <span>
                  {" "}
                  <FaArrowRight />
                </span>{" "}
                Which Course are you Interested in?
              </h2>
            </div>
          ) : (
            ""
          )}

          {Step == 2 ? (
            <div className="Question_head">
              <h2 style={{ textAlign: "left" }}>
                {" "}
                <span>2</span>{" "}
                <span>
                  {" "}
                  <FaArrowRight />
                </span>{" "}
                What Are You looking for ?
              </h2>
            </div>
          ) : (
            ""
          )}

          {Step == 3 ? (
            <div className="Question_head">
              <h2 style={{ textAlign: "left" }}>
                {" "}
                <span>3</span>{" "}
                <span>
                  {" "}
                  <FaArrowRight />
                </span>{" "}
                Preferred Mode of Test ?
              </h2>
            </div>
          ) : (
            ""
          )}

          {Step === 1 ? (
            <div className="Options">
              {Step1Q?.map((ev) => (
                <div
                  onClick={() => handleStep1(ev?.Q, ev?.id)}
                  className={
                    ev?.Q === QuestionSelected?.IELTS_TEST
                      ? "optionCard active"
                      : "optionCard"
                  }
                >
                  <span>{ev?.Option}</span>
                  <span>{ev?.Q}</span>
                  {ev?.Q === QuestionSelected?.IELTS_TEST ? (
                    <span>
                      <MdOutlineDone />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}

          {Step === 2 ? (
            <div className="Options">
              {QuestionSelected?.IELTS_TEST === "IELTS"
                ? Step2_Ielts?.map((ev) => (
                    <div
                      onClick={() => handleStep2(ev?.Q, ev?.id)}
                      className={
                        ev?.Q === QuestionSelected?.Step_2
                          ? "optionCard active"
                          : "optionCard"
                      }
                    >
                      <span>{ev?.Option}</span>
                      <span>{ev?.Q}</span>
                      {ev?.Q === QuestionSelected?.Step_2 ? (
                        <span>
                          <MdOutlineDone />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                : Step2_PTE?.map((ev) => (
                    <div
                      onClick={() => {
                        setTimeout(() => {
                          Navigate(
                            `/course?courseid=${QuestionSelected?.Step_1}&subCategory=${ev?.id}`
                          );
                        }, 500);

                        setQuestionSelected({
                          ...QuestionSelected,
                          Step_2: ev?.Q,
                        });
                      }}
                      className={
                        ev?.Q === QuestionSelected?.Step_2
                          ? "optionCard active"
                          : "optionCard"
                      }
                    >
                      <span>{ev?.Option}</span>
                      <span>{ev?.Q}</span>
                      {ev?.Q === QuestionSelected?.Step_2 ? (
                        <span>
                          <MdOutlineDone />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
            </div>
          ) : (
            ""
          )}

          {Step === 3 ? (
            <div className="Options">
              {Step3?.map((ev) => (
                <div
                  onClick={() => {
                    setTimeout(() => {
                      Navigate(
                        `/course?SubCategory=${QuestionSelected?.Step_2}&testType=${ev?.id}`
                      );
                    }, 500);

                    setQuestionSelected({
                      ...QuestionSelected,
                      Step_3: ev?.Q,
                    });
                  }}
                  className={
                    ev?.Q === QuestionSelected?.Step_3
                      ? "optionCard active"
                      : "optionCard"
                  }
                >
                  <span>{ev?.Option}</span>
                  <span>{ev?.Q}</span>
                  {ev?.Q === QuestionSelected?.Step_3 ? (
                    <span>
                      <MdOutlineDone />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}

          <div id="message"></div>
        </div>
        {/* <div id="popup" className="popup sticky">
          <div className="popup-content">
            <div className="social-icons">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

import { useNavigate } from "react-router-dom";
import Styles from "./LandingPage.module.css";
import { PiInstagramLogoBold } from "react-icons/pi";
import { RiFacebookFill } from "react-icons/ri";
import AccordionItem from "../../Components/AccordianItem";
import { FAQS_TRT } from "../../Model/FaqModel";
import { useEffect, useRef, useState } from "react";
import Testimonial from "../Home/Testimonial";
import { Testimonials_Model } from "../../Model/HomeModel";
import Loading from "../../Components/Loading";
import {
  FaCalendarAlt,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaRegDotCircle,
} from "react-icons/fa";
import { IoHomeOutline } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { DetailsFormJSon, ValidatorForm } from "./LandingPageModel";
import { useDispatch, useSelector } from "react-redux";
import { Test_Book } from "../../Action/ContactAction";
import "./Landing.css";
import {
  TEST_BOOKING_FAILED,
  TEST_BOOKING_RESET,
} from "../../Constant/ContactConstant";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  const Navigate = useNavigate();
  const [active, setActive] = useState(null);
  const [DetailsForm, setDetailsForm] = useState(DetailsFormJSon);
  const TestmonialRef = useRef();
  const ScrollRef = useRef();
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((s) => s.Test_Book_Reducer);
  const [index, setIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("fadeInUp");
  const Heading = [1, 2, 3];

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setAnimationClass("");
        setIndex((index) => (index + 1) % Heading.length);
        setAnimationClass("fadeInUp");
      },
      5000 // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleChange = (e) => {
    if (
      e.target.name == "phone" &&
      (e.target.value?.length > 10 || e.target.value.includes(" "))
    ) {
    } else {
      setDetailsForm({ ...DetailsForm, [e.target.name]: e.target.value });
    }
  };

  const Scroll = () => {
    ScrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    Navigate("/ielts-pte-reality-test#RegisterForm");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const Validate = ValidatorForm(DetailsForm);
    if (Validate == "success") {
      dispatch(Test_Book({ ...DetailsForm, url: window.location.href }));
    } else {
      dispatch({ type: TEST_BOOKING_FAILED, payload: Validate });
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setDetailsForm({ ...DetailsFormJSon });
        dispatch({ type: TEST_BOOKING_RESET });
      }, 5000);
    }

    // if (error) {
    // }
  }, [success, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("stat1").classList.add("show");
      document.getElementById("JoinStat1").classList.add("show");
    }, 500);

    setTimeout(() => {
      document.getElementById("stat2").classList.add("show");
      document.getElementById("JoinStat2").classList.add("show");
    }, 1500);

    setTimeout(() => {
      document.getElementById("stat3").classList.add("show");
      document.getElementById("JoinStat3").classList.add("show");
    }, 2500);

    setTimeout(() => {
      document.getElementById("JoinStat4").classList.add("show");
    }, 3000);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Book Reality Test for IELTS & PTE | TRT | Testifyi Reality Test"
        />
        <meta
          name="description"
          content="Discover your IELTS/PTE level in a real test environment by Testifyi. Perfect for students aspiring to study abroad! Affordable: At just ₹99."
        />
        <meta
          name="keywords"
          content="Book Reality Test for IELTS & PTE, IELTS Reality Test, PTE Reality Test, IELTS & PTE reality test, testifyi reality test, TRT test"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Testifyi" />
        <title>
          Book Reality Test for IELTS & PTE | TRT | Testifyi Reality Test{" "}
        </title>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is TRT: Testifyi Reality Test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "TRT is a specially designed mock IELTS/PTE test that allows students to: Experience the real IELTS/PTE exam environment. Assess their current skill level. Receive detailed feedback to improve performance in Listening, Reading, Writing, and Speaking"
    }
  },{
    "@type": "Question",
    "name": "What is the cost of the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The test is priced at just ₹99—a highly affordable opportunity to experience a real exam-like environment."
    }
  },{
    "@type": "Question",
    "name": "When and where will the event be conducted?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The event is scheduled for January 18, 2025, at Nehru Place, Delhi, from 10:00 AM to 5:00 PM."
    }
  },{
    "@type": "Question",
    "name": "What documents should I bring along?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Please bring the following: A valid photo ID (Aadhaar card, passport, or student ID). Your registration confirmation email or message. Basic stationery like pens, pencils, and erasers."
    }
  },{
    "@type": "Question",
    "name": "What is included in the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The test includes all four sections of the IELTS/PTE exam: Listening, Reading, Writing, and Speaking. You’ll get a complete experience of the actual test format."
    }
  },{
    "@type": "Question",
    "name": "When will my Speaking module test be conducted?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Speaking test will be conducted on the same day as the event. Specific time slots will be assigned 2 days prior to the test day."
    }
  },{
    "@type": "Question",
    "name": "Will I be notified about my Speaking test time slot?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, your Speaking test time slot will be shared with you 2 days prior to the test day."
    }
  },{
    "@type": "Question",
    "name": "Who can participate in this event?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "This test is ideal for students preparing for IELTS/PTE, whether you're a beginner or looking to refine your skills before the actual exam."
    }
  },{
    "@type": "Question",
    "name": "Will I receive feedback on my performance?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, our IELTS/PTE experts will provide personalized feedback after the test, helping you understand your strengths and areas for improvement."
    }
  },{
    "@type": "Question",
    "name": "How can I register for the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Click the [Register Now for ₹99] button, fill in your details, and a Testifyi coordinator will reach out to help you secure your spot."
    }
  },{
    "@type": "Question",
    "name": "What happens if I miss the test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Unfortunately, the fee is non-refundable. However, you may contact us to explore any future test opportunities."
    }
  }]
}`}
        </script>
      </Helmet>

      {/* Header  start*/}
      {loading ? <Loading /> : ""}
      <header>
        <div className={Styles.Header}>
          <div>
            <img
              // onClick={() => Navigate("/")}
              src="/assets/image/Logos/Testify_White_Logo.svg"
              alt="logo"
            />
          </div>
          {/* <div>
            <button className={Styles.ActionBtn}>Book</button>
          </div> */}
        </div>
      </header>
      {/* Header end */}

      {/* Banner form Section start */}
      <section className={Styles.Margin2}>
        <div className={Styles.BannerTop}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent:
                  window.innerWidth <= 480 ? "flex-start" : "space-between",
                flexDirection: window.innerWidth <= 480 ? "column" : "row",
                // alignItems: "center",
              }}
            >
              <div className={Styles.EventDet}>
                <div>
                  <span className={Styles.clnd}>
                    <FaCalendarAlt />
                  </span>
                  <span>
                    18<sup>th</sup> Jan 2025, SAT
                  </span>
                </div>
                <div>
                  <span className={Styles.crl}>
                    <FaRegDotCircle />
                  </span>
                  <span>Delhi</span>
                </div>
              </div>

              {window.innerWidth <= 480 ? "" : <hr className={Styles.Line_5} />}
              <div className={Styles.EventDet}>
                <div>
                  <span className={Styles.clnd}>
                    <FaCalendarAlt />
                  </span>
                  <span>
                    1<sup>st</sup> Feb 2025, SAT
                  </span>
                  {/* <span>Coming Soon</span> */}
                </div>
                <div>
                  <span className={Styles.crl}>
                    <FaRegDotCircle />
                  </span>
                  <span>Chandigarh</span>
                </div>
              </div>
            </div>
            <div id="container2">
              {index == 0 ? (
                <AnimatePresence mode="wait">
                  <motion.div
                    key={index}
                    className="section"
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div
                      id="BannerText"
                      className={`HeadingBanner ${animationClass}`}
                    >
                      <h2>
                        Experience{" "}
                        <span style={{ color: "#0b3eff" }}>IELTS/PTE</span>{" "}
                        Before the Real{" "}
                        {window.innerWidth <= 480 ? " " : <br />}
                        Exam — For Just{" "}
                        <span style={{ color: "#0b3eff" }}>₹99/-</span>{" "}
                      </h2>

                      <h4>
                        Discover your IELTS/PTE level in a real test
                        environment. Perfect for students aspiring to study
                        abroad!
                      </h4>
                    </div>
                  </motion.div>
                </AnimatePresence>
              ) : index == 1 ? (
                <AnimatePresence mode="wait">
                  <motion.div
                    key={index}
                    className="section"
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div
                      id="BannerText"
                      className={`HeadingBanner hdr2 ${animationClass}`}
                    >
                      <h2>
                        <span
                          style={{
                            color: "#0b3eff",
                          }}
                        >
                          IELTS
                        </span>{" "}
                        <span>REALITY TEST</span>
                      </h2>

                      <h4>
                        Get the Full IELTS Exam Experience — Practice in a
                        Real-World Setting and Boost Your Success!
                      </h4>
                    </div>
                  </motion.div>
                </AnimatePresence>
              ) : (
                <AnimatePresence mode="wait">
                  <motion.div
                    key={index}
                    className="section"
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div
                      id="BannerText"
                      className={`HeadingBanner hdr2 ${animationClass}`}
                    >
                      <h2>
                        <span
                          style={{
                            color: "#0b3eff",
                          }}
                        >
                          PTE
                        </span>{" "}
                        <span>REALITY TEST</span>
                        {/* <br />
                        <span>TEST</span> */}
                        {/* <br /> Before the Real{" "}
                        {window.innerWidth <= 480 ? " " : <br />}
                        Exam — For Just{" "}
                        <span style={{ color: "#0b3eff" }}>₹99/-</span>{" "} */}
                      </h2>

                      <h4>
                        Experience the Real PTE Exam Before the Big Day —
                        Format, Timing, and Challenges Included!
                      </h4>
                    </div>
                  </motion.div>
                </AnimatePresence>
              )}
            </div>

            <div>
              <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                Register Now for ₹99/-
              </button>
            </div>
          </div>
          {/* Form Details Start */}

          <div ref={ScrollRef}>
            <AnimatePresence mode="wait">
              <motion.div
                className="section"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.5 }}
              >
                <div>
                  <div className={Styles.DetailsForm}>
                    <div>
                      <h4>Register Now for TRT: Testifyi Reality Test!</h4>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Name*"
                        name="name"
                        value={DetailsForm.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <input
                        type="email"
                        placeholder="Email*"
                        name="email"
                        value={DetailsForm.email}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <input
                        type="text"
                        placeholder="city*"
                        name="city"
                        value={DetailsForm.city}
                        onChange={handleChange}
                      />
                    </div>

                    <div className={Styles.MobilePhone}>
                      <div>
                        <select>
                          <option>91</option>
                        </select>
                      </div>
                      <div>
                        <input
                          type="number"
                          placeholder="Phone Number*"
                          name="phone"
                          value={DetailsForm.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className={Styles.Venue}>
                      <div>
                        <select
                          name="location"
                          value={DetailsForm.location}
                          onChange={handleChange}
                        >
                          <option value="">Select Location</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Chandigarh">Chandigarh</option>
                        </select>
                      </div>
                      <div>
                        <select
                          name="timeSlot"
                          value={DetailsForm.timeSlot}
                          onChange={handleChange}
                        >
                          <option value="">Select Time Slot</option>
                          <option value="10:30 am to 1:30 pm">
                            10:30 am to 1:30 pm
                          </option>
                          <option value="2:00 pm to 5:00 pm">
                            2:00 pm to 5:00 pm
                          </option>
                        </select>
                      </div>
                    </div>

                    <div>
                      {error ? (
                        <p style={{ color: "red" }}> {error}</p>
                      ) : success ? (
                        <p style={{ color: "green" }}>{success}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <button
                      style={{ marginTop: "15px" }}
                      className={Styles.ActionBtn2}
                      onClick={handleSubmit}
                    >
                      {loading ? "Loading..." : "SUBMIT"}
                    </button>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Form Details end */}
        </div>
      </section>
      {/* Banner form Section end */}

      {/* Video Section Start */}

      <section>
        <AnimatePresence mode="wait">
          <motion.div
            className="section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.5 }}
          >
            <div className={Styles.Margin2}>
              <div className="trust-section">
                <div>
                  <h1 className={Styles.Font_Rajdhani}>
                    Trusted by
                    <span style={{ color: "#0b3eff" }}> Thousands</span> of Test
                    Takers
                  </h1>
                  <p>
                    Over <strong>300+ Tests Conducted</strong> and{" "}
                    <strong>15000+ Test Takers</strong>. Join us to experience
                    the real test environment!
                  </p>
                </div>
                <div className="trust-stats">
                  <div className="stat-box fade-animation" id="stat1">
                    <h3>300+</h3>
                    <span>Tests Conducted</span>
                  </div>
                  <div className="stat-box fade-animation" id="stat2">
                    <h3>15000+</h3>
                    <span>Test Takers</span>
                  </div>
                  <div className="stat-box fade-animation" id="stat3">
                    <h3>100%</h3>
                    <span>Satisfied Students</span>
                  </div>
                </div>
              </div>
              <div className={Styles.VideoSection}>
                <video
                  preload="auto"
                  autobuffer
                  muted
                  width="100%"
                  height="100%"
                  autoPlay
                  loop
                  playsInline
                >
                  <source src="/assets/image/web-vd.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </section>

      {/* Video section End */}

      {/* why Join us start */}

      <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div>
              <h1 className={Styles.Font_Rajdhani}>
                What is the TRT (
                <span style={{ color: "#0b3eff" }}> Testifyi</span> Reality
                Test)?
              </h1>
              <p>
                TRT is a specially designed in-house event by Testifyi to help
                aspiring IELTS/PTE students.
              </p>
            </div>

            <div className="trt-grid">
              <div className="trt-card fade-animation1" id="JoinStat1">
                <div className="icon">
                  <i className="fas fa-chalkboard-teacher"></i>
                </div>
                <h3>Real Test Environment</h3>
                <p>
                  Experience the real IELTS/PTE test environment to reduce
                  test-day anxiety.
                </p>
              </div>
              <div className="trt-card fade-animation1" id="JoinStat2">
                <div className="icon">
                  <i className="fas fa-chart-line"></i>
                </div>
                <h3>Skill Assessment</h3>
                <p>
                  Understand your current skill level with a detailed
                  performance analysis.
                </p>
              </div>
              <div className="trt-card fade-animation1" id="JoinStat3">
                <div className="icon">
                  <i className="fas fa-lightbulb"></i>
                </div>
                <h3>Insights & Feedback</h3>
                <p>
                  Receive expert insights into areas of improvement to maximize
                  your score.
                </p>
              </div>
              <div className="trt-card fade-animation1" id="JoinStat4">
                <div className="icon">
                  <i className="fas fa-book-open"></i>
                </div>
                <h3>All IELTS/PTE Sections</h3>
                <p>
                  Prepare for Listening, Reading, Writing, and Speaking—just
                  like the real exam!
                </p>
              </div>
            </div>

            <div className="trt-cta">
              <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                Sign Up Today—Limited Seats Available
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* why Join us end */}

      {/* why Join us start */}
      <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div>
              <h1 className={Styles.Font_Rajdhani}>
                Why You Should
                <span style={{ color: "#0b3eff" }}> Take </span>This Test?
              </h1>
              {/* <p>Why You Should Take This Test.</p> */}
            </div>

            <div className={Styles.Why_Should_Section}>
              <div className={Styles.Why_Sub_1}>
                <div>
                  <i className="fas fa-chalkboard-teacher"></i>
                </div>
                <div>
                  <h3>Real Test Experience</h3>
                  <p>
                    Feel the pressure and format of an actual IELTS/PTE test.
                  </p>
                </div>
              </div>

              <div className={Styles.Why_Sub_2}>
                <div>
                  <h3>Affordable Fee</h3>
                  <p>At just ₹99, it's an unmissable opportunity.</p>
                </div>
                <div>
                  <i className="fas fa-wallet"></i>
                </div>
              </div>

              <div className={Styles.Why_Sub_1}>
                <div>
                  <i className="fas fa-chart-line"></i>
                </div>
                <div>
                  <h3>Skill Analysis</h3>
                  <p>Know where you stand and what to improve.</p>
                </div>
              </div>

              <div className={Styles.Why_Sub_2}>
                <div>
                  <h3>Expert Feedback</h3>
                  <p>Personalized insights from IELTS/PTE experts.</p>
                </div>
                <div>
                  <i className="fas fa-user-check"></i>
                </div>
              </div>
            </div>
            {/* <div className="trt-cta">
              <button className={Styles.ActionBtn}>
                Sign Up Today—Limited Seats Available
              </button>
            </div> */}
          </div>
        </div>
      </section>
      {/* why Join us end */}

      {/* Event Details css start */}

      <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div>
              <h1 className={Styles.Font_Rajdhani}>
                Testify Reality
                <span style={{ color: "#0b3eff" }}> Test Event </span>Schedule
              </h1>
              <p></p>
            </div>
            <div className={Styles.EventDetails}>
              <div>
                <h4>Delhi</h4>
                <p className={Styles.Date}>
                  <span style={{ color: "#0b3eff" }}>
                    <FaCalendarAlt />
                  </span>{" "}
                  <span>
                    {" "}
                    18<sup>th</sup> JAN 2025 (Saturday)
                  </span>
                </p>
                <div className={Styles.Slots}>
                  <div className={Styles.Slot}>
                    <h3>Slot-1</h3>
                    <p>10:30 AM to 1:30 PM</p>
                  </div>
                  <div className={Styles.Slot}>
                    <h3>Slot-2</h3>
                    <p>2:00 PM to 5:00 PM</p>
                  </div>
                </div>
                <p className={Styles.location}>
                  <span style={{ color: "#0b3eff" }}>
                    <FaMapMarkerAlt />
                  </span>{" "}
                  1409-1412, 14th Floor, 38, Ansal Tower, Nehru Place, New
                  Delhi, Delhi 110019
                </p>
              </div>
              <div>
                <h4>Chandigarh</h4>
                <p className={Styles.Date}>
                  {" "}
                  <span style={{ color: "#0b3eff" }}>
                    <FaCalendarAlt />
                  </span>{" "}
                  <span>
                    1<sup>st</sup> FEB 2025 (Saturday){" "}
                  </span>
                </p>
                <div className={Styles.Slots}>
                  <div className={Styles.Slot}>
                    <h3>Slot-1</h3>
                    <p>10:30 AM to 1:30 PM</p>
                  </div>
                  <div className={Styles.Slot}>
                    <h3>Slot-2</h3>
                    <p>
                      <span></span> 2:00 PM to 5:00 PM
                    </p>
                  </div>
                </div>
                <p className={Styles.location}>
                  <span style={{ color: "#0b3eff" }}>
                    <FaMapMarkerAlt />
                  </span>{" "}
                  SCO 491, First Floor, above Airtel Store, sector 35C,
                  Chandigarh, 160022
                </p>
              </div>
            </div>
            <div className="trt-cta">
              <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                Book Your Seat Now
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Event Details css end */}

      {/* Testimonials start */}
      <section style={{ background: "#f4f9ff" }} className={Styles.Margin2}>
        <div>
          <h1 className={Styles.Heading}>
            <span className={Styles.Font_Rajdhani}>
              {" "}
              Hear From Our Students
            </span>
          </h1>
        </div>

        <Testimonial
          TestmonialRef={TestmonialRef}
          Testimonials_Model={Testimonials_Model}
        />
        <div className={Styles.Center}>
          <button className={Styles.ActionBtn} onClick={() => Scroll()}>
            Join the Test Today
          </button>
        </div>
      </section>
      {/* Testimonials end */}

      {/* FAQs satrt */}

      <section style={{ background: "#ffffff" }} className=".Faqs">
        <div className={Styles.Margin2}>
          <div className="container-fluid mt-5 mb-5">
            <div className="card-body_Faq">
              <div className={Styles.Form_Heading}>
                <h1 className="">
                  Frequently Asked{" "}
                  <span style={{ color: "#0b3eff" }}>Questions</span>
                </h1>
                <p style={{ textAlign: "center" }}>
                  Got Questions? We’ve Got Answers!
                </p>
              </div>
              <div className="FAQ_ALL">
                {FAQS_TRT.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                    />
                  );
                })}
              </div>
              {/* <div className={Styles.Center}>
                <button className={Styles.ActionBtn} onClick={() => Scroll()}>
                  Join the Test Today
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* FAQs end */}

      <section className={Styles.Footer_Top}>
        <div>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className={Styles.LowFotterBanner}
          >
            <h1 className={"Font_Rajdhani"}>
              Don’t Miss Out on This Unique Opportunity!
            </h1>
            <p>
              Seats are filling fast—book yours today for just ₹99 and take the
              first step towards studying abroad.
            </p>
          </div>
          <div>
            <button className={"Font_Rajdhani"} onClick={() => Scroll()}>
              Register Now—Limited Seats Available
            </button>
            {/* <button
              className={"Font_Rajdhani"}
              onClick={() => Navigate("/login")}
            >
              Login Now
            </button> */}
          </div>
        </div>
      </section>

      {/* Footer start */}
      <footer className={Styles.Footer}>
        {/* {BookADemoActive ? (
        <BookADemo setBookADemoActive={setBookADemoActive} />
      ) : (
        ""
      )} */}
        <div>
          <div>
            <div>
              <img
                onClick={() => Navigate("/")}
                src="/assets/image/Logos/Testifyi_logo1.jpg"
                alt="logo"
              />
              <p>
                Facilitating students acquire perfect-fit education placements
                that ultimately changes their lives!
              </p>

              {/* <p>Contact No : +91 92891 92873</p> */}
            </div>
            <hr className={Styles.Hr1} />
            <div>
              <h3>My Profile</h3>
              <p onClick={() => Navigate("/signup", { replace: true })}>
                Create Account
              </p>
              <p onClick={() => Navigate("/login", { replace: true })}>
                Login Now
              </p>
              <p onClick={() => Navigate("/contact-us")}>Contact Us</p>{" "}
              <p onClick={() => Navigate("/about-us")}>About Us</p>
            </div>
            <hr className={Styles.Hr1} />
            <div>
              <h3>Important Links</h3>
              <p onClick={() => Navigate("/Course")}>Our Courses</p>
              {/* <p onClick={() => Navigate("/course")}>Courses</p> */}
              {/* <p onClick={() => setBookADemoActive(true)}>Book A Demo</p> */}
              <p onClick={() => Navigate("/dashboard")}>Dashboard</p>
              <p onClick={() => Navigate("/terms-and-conditions")}>
                Terms And Conditions
              </p>
              <p onClick={() => Navigate("/privacy-policy")}>Privacy Policy</p>
            </div>
            <hr className={Styles.Hr1} />
            <div>
              <h3>Contact Us</h3>
              <p style={{ display: "flex", gap: "8px", fontSize: "13px" }}>
                <span style={{ color: "white" }}>
                  <IoHomeOutline />{" "}
                </span>

                <span style={{ fontSize: "14px" }}>
                  Address : 1409-1412, 14th Floor, 38, Ansal Tower, Nehru Place,
                  New Delhi, Delhi 110019
                </span>
              </p>

              <p style={{ display: "flex", gap: "8px", fontSize: "13px" }}>
                <span style={{ color: "white" }}>
                  <IoIosCall />
                </span>
                <span style={{ fontSize: "14px" }}>+91 92891 92873</span>
              </p>
              <p style={{ display: "flex", gap: "8px", fontSize: "13px" }}>
                <span style={{ color: "white" }}>
                  <FaMapMarkedAlt />{" "}
                </span>

                <span style={{ fontSize: "14px" }}>
                  Map :{" "}
                  <a
                    href="https://maps.app.goo.gl/74ufjPk837JUPDcq8"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Locate Us{" "}
                  </a>
                </span>
              </p>
              {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.702078508757!2d77.25045209999999!3d28.5486732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce35adb1e9e1f%3A0xf8cd70d1f67432d3!2sTestifyi%20-%20IELTS%2FPTE%2FDUOLINGO%20%26%20General%20English%20Platform!5e0!3m2!1sen!2sin!4v1735282947328!5m2!1sen!2sin"
                    width="100%"
                    height="250"
                    style={{border:"0"}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe> */}
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "-20px",
                }}
              >
                Follow Us :{" "}
                <p style={{ display: "flex", gap: "8px", color: "white" }}>
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/testifyi.india?igsh=c3ZpN2N2ZDg4cWVm"
                      )
                    }
                  >
                    <PiInstagramLogoBold />
                  </span>
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/testifyi.india?mibextid=LQQJ4d"
                      )
                    }
                  >
                    <RiFacebookFill />
                  </span>
                  {/* <span>
                <TiSocialLinkedin />{" "}
              </span><FaMapMarkedAlt />
              <span>
                <BiLogoTwitter />
              </span> */}
                </p>{" "}
              </p>

              {/* <p onClick={() => Navigate("/signup", { replace: true })}>
                Create Account
              </p>
              <p onClick={() => Navigate("/login", { replace: true })}>
                Login Now
              </p> */}
            </div>
          </div>

          <div>
            <hr className={Styles.Hr2} />
            <p onClick={() => Navigate("/")}>
              @ 2024 Testifyi.com All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      {/* Footer end */}
    </>
  );
}

import { useSelector } from "react-redux";
import Styles from "./Attendance.module.css";
export default function CalendarBox({ month, year }) {
  const getMonthName = (monthIndex) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  };

  // const { loading, error, AttendanceRecs } = useSelector(
  //   (s) => s.AttendanceRecordsListReducer
  // );
  const loading = "";

  const AttendanceRecs = [
    {
      ContactId: "Jyoti Ranjan",
      TeacherId: "Jyotiranjan",
      Timings: "11:00 AM - 12:00 PM",
      Attendance_Marked: 1,
      Attendance_Marked_date: "2025-01-22",
      Remarks: "",
    },
    {
      ContactId: "Jyoti Ranjan",
      Attendance_Marked_date: "2025-01-18",
      Attendance_Marked: 2,
      TeacherId: "Jyotiranjan",
    },
    {
      ContactId: "Jyoti Ranjan",
      Attendance_Marked_date: "2025-01-20",
      Timings: "13:00 PM - 14:00 PM",
      Attendance_Marked: 1,
      TeacherId: "Jyotiranjan",
    },
    {
      ContactId: "Jyoti Ranjan",
      Attendance_Marked_date: "2025-01-31",
      Timings: "11:00 AM - 12:00 PM",
      Attendance_Marked: 2,
      TeacherId: "Jyotiranjan",
    },
  ];

  const daysName = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sat"];
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const days = Array.from({ length: daysInMonth }, (_, index) => index + 1);
  const paddingDays = Array.from({ length: firstDayOfMonth }, () => null);
  const isPresentDay = days.map((ev) => {
    let yr = new Date(year, +month, ev + 1).toISOString().split("T")[0];

    for (let i = 0; i < AttendanceRecs?.length; i++) {
      if (
        new Date(yr).getTime() ===
        new Date(AttendanceRecs[i]?.Attendance_Marked_date).getTime()
      ) {
        return { day: ev, Attendance: AttendanceRecs[i]?.Attendance_Marked };
      }
    }

    return { day: ev, Attendance: "" };
  });

  return (
    <div className={Styles.calendar_box}>
      <div className={Styles.calendar_header}>
        <span>
          {getMonthName(month)} {year}
        </span>
      </div>
      <div className={Styles.calendar_body}>
        <div className={Styles.calendar_grid}>
          {daysName.map((day) => (
            <div key={day} className={Styles.calendar_cell}>
              {day}
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.calendar_body}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Loading...
          </div>
        ) : (
          <div className={Styles.calendar_grid}>
            {paddingDays.map((_, index) => (
              <div
                key={`padding-${index}`}
                className={Styles.calendar_cell}
              ></div>
            ))}

            {isPresentDay.map((ev) => (
              <div
                key={ev?.day}
                className={
                  ev?.Attendance == 1
                    ? Styles.calendar_cell_present
                    : ev?.Attendance == 2
                    ? Styles.calendar_cell_absent
                    : Styles.calendar_cell
                }
              >
                {ev?.day}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export const RecordClassModel = [
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
  { Name: "record" },
];

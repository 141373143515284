import {
  PACKAGE_DETAILS_FAILED,
  PACKAGE_DETAILS_REQ,
  PACKAGE_DETAILS_SUCCESS,
  PACKAGE_LIST_FAILED,
  PACKAGE_LIST_REQ,
  PACKAGE_LIST_SUCCESS,
  PURCHASED_PACKAGES_LIST_FAILED,
  PURCHASED_PACKAGES_LIST_REQ,
  PURCHASED_PACKAGES_LIST_SUCCESS,
} from "../Constant/packageConstant";
import api from "../Core/api";

export const Package_List =
  (PackageFilter, MainPackages) => async (dispatch) => {
    try {
      dispatch({ type: PACKAGE_LIST_REQ });
      const { data } = await api.get(
        MainPackages
          ? `/student/packages/packageSection/main`
          : `/student/packages?page_no=${PackageFilter?.page_no}&page_size=${
              PackageFilter?.page_size
            }&courseId
=${PackageFilter?.courseid ? PackageFilter?.courseid : ""}&subCategory=${
              PackageFilter?.SubCategory ? PackageFilter?.SubCategory : ""
            }&testType=${
              PackageFilter?.testType ? PackageFilter?.testType : ""
            }`
      );

      const TotalRec = await api.get("/student/packages/total");

      dispatch({
        type: PACKAGE_LIST_SUCCESS,
        payload: data?.Result,
        Total: TotalRec?.data?.Result,
      });
    } catch (error) {
      dispatch({
        type: PACKAGE_LIST_FAILED,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const packageDetails = (PackageId) => async (dispatch) => {
  try {
    dispatch({ type: PACKAGE_DETAILS_REQ });
    const { data } = await api.get(`/student/packages/${PackageId}`);

    dispatch({ type: PACKAGE_DETAILS_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: PACKAGE_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const PurchasedPackages = () => async (dispatch) => {
  try {
    dispatch({ type: PURCHASED_PACKAGES_LIST_REQ });
    const { data } = await api.get("/student/purchasedPackage");
    dispatch({ type: PURCHASED_PACKAGES_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: PURCHASED_PACKAGES_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

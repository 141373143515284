import { PropagateLoader } from "react-spinners";

export default function Loading() {
  return (
    <>
      <div
        id="CreatContact"
        className={"overlay-note middle-hand w100"}
        style={{ width: "100%", zIndex: "99999" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
          }}
        >
          <PropagateLoader color="#0b3eff" />
        </div>
      </div>
    </>
  );
}

import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./Practice.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { Package_List } from "../../Action/PacakgeAction";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import PackageCard from "../../Components/PackageCard";
import Styles2 from "../Home/Home.module.css";
import Enroll from "../Home/Enroll";
import Loading from "../../Components/Loading";
import { GurullyDet } from "../../Action/GurullyAction";
import { ListOrders } from "../../Action/OrderPackageAction";
import { listMockTest } from "../../Action/mockTestAction";
import RightMenu from "./RightMenu";
import ReactHelmet from "../../Components/ReactHelmet";

export default function PracticeTest() {
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const [SidebarOpen2, setSideBarOpen2] = useState(false);
  const Mobile_Screen = window.innerWidth;
  const [CourseId, setCourseId] = useState("");
  const [TypeOfTest, setTypeOfTest] = useState("");
  const [EnrollActive, setEnrollActive] = useState(false);
  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );
  const { detGurully, loading: loadingGurulyDet } = useSelector(
    (s) => s.GurullyDetReducer
  );
  const { PackageData, loading: LoadingPackDet } = useSelector(
    (s) => s.SelectStudentPackageReducer
  );
  const { mockDesc } = useSelector((s) => s.mockTestDetailsReducer);
 

  const dispatch = useDispatch();
  const BrowsePackageRef = useRef();
  const [OpenMenuBar, setOpenMenuBar] = useState(false);

  const BrowsePacakgesettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 2
        ? 2
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const { MockTestRec, loading: loadingMock } = useSelector(
    (s) => s.mockTestListAllRecReducer
  );
  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 }, "MainPackages"));
  }, [dispatch]);

  // console.log(MockTestRec, "Mock Tests");

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  useEffect(() => {
    if (PackageData?.CourseId?.CourseName === "PTE") {
      setTypeOfTest("Gurully");
    } else if (PackageData?.CourseId?.CourseName === "IELTS") {
      if (PackageData?.TestType == 1) {
        setTypeOfTest("MockTest");
      } else {
        setTypeOfTest("Gurully");
      }
    } else {
      setTypeOfTest("MockTest");
    }
  }, [PackageData]);

  // console.log(TypeOfTest, "type of test");
  useEffect(() => {
    if (TypeOfTest === "MockTest") {
      dispatch(listMockTest(PackageData?._id));
    } else if (TypeOfTest === "Gurully") {
      dispatch(GurullyDet(PackageData?._id));
    }
  }, [dispatch, TypeOfTest, PackageData]);

  const OpenBarClick = (index) => {
    if (OpenMenuBar === index) {
      setOpenMenuBar(false);
    } else {
      setOpenMenuBar(index);
    }
  };

  useEffect(() => {
    dispatch(ListOrders());
  }, [dispatch]);

  return (
    <div className={Styles.Dashboard}>
      {/* SidePanel */}
      <ReactHelmet title={"Academy"} description={""} />

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Academy"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Academy"
        />
      ) : (
        ""
      )}

      {loadingPack || LoadingPackDet || loadingGurulyDet || loadingMock ? (
        <Loading />
      ) : (
        ""
      )}

      {EnrollActive ? (
        <Enroll
          setEnrollActive={setEnrollActive}
          Package_Id={EnrollActive}
          courseId={CourseId}
        />
      ) : (
        ""
      )}
      {/* Middle Section */}
      {!SidebarOpen && !SidebarOpen2 ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              SidebarOpen2={SidebarOpen2}
              SetSideBarOpen2={setSideBarOpen2}
              PageName={"Academy"}
            />
          </div>

          {detGurully && TypeOfTest === "Gurully" ? (
            ""
          ) : mockDesc && TypeOfTest == "MockTest" ? (
            <div
            className="DangeriousTextFrame"
              dangerouslySetInnerHTML={{ __html: mockDesc?.MockDescription }}
            ></div>
          ) : PackageData?._id && !MockTestRec?.length ? (
            <div className={Styles.OnlineClassTopSection}>
              <div>
                <h3 style={{ textAlign: "center", fontWeight: "500" }}>
                  This Package doesn't include Mock Test.
                </h3>
              </div>
            </div>
          ) : (
            <div className={Styles.OnlineClassTopSection}>
              <div>
                <h3 style={{ textAlign: "center", fontWeight: "500" }}>
                  Please buy the package to unlock this section.
                </h3>
              </div>
            </div>
          )}

          {/* <form
            id="registerForm"
            name="registerForm"
            method="post"
            target="_top"
            action="https://academy.testifyi.com/"
          >
            <input id="username1" name="username" type="email" value="examplelogin" />
            <input
              id="password1"
              name="password"
              type="password"
              value="examplepassword"
            />

            <input
              type="button"
              name="submit"
              id="btn-login1"
              value="Submit"
              onClick={()=>}
            />
          </form> */}

          {/* <div class="login-btn">
            <button
              onClick={handleClick}
              id="btn-login1"
              type="button"
              value="1"
            >
              Login
            </button>
          </div> */}

          {detGurully && TypeOfTest === "Gurully" ? (
            <section style={{ padding: "30px", paddingBottom: "60px" }}>
              <div className={Styles.Predicated_Score_Container}>
                <div>
                  {/* <h3>URL :-</h3> */}
                  <h5>
                    <span>
                      <button
                        className={"cst_btn1"}
                        onClick={() =>
                          window.open("https://academy.testifyi.com/", "_blank")
                        }
                      >
                        Start Practice Test
                      </button>
                      {/* <a
                        href="https://academy.testifyi.com/"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        https://academy.testifyi.com
                      </a> */}
                    </span>
                  </h5>
                  <h3>Credential :-</h3>
                  <h5>
                    <span className={Styles.Details_Con}> User Id:</span>
                    <span> {detGurully?.GurullyId?.UserId}</span>
                  </h5>
                  <h5>
                    <span className={Styles.Details_Con}> Password:</span>
                    <span> {detGurully?.GurullyId?.Password}</span>
                  </h5>
                </div>
              </div>
            </section>
          ) : mockDesc ? (
            ""
          ) : (
            <>
              <section style={{ background: "#f4f9ff", paddingBottom: "60px" }}>
                <div style={{ margin: "30px" }}>
                  <div className={Styles.Browse_Package_Section}>
                    <div>
                      {" "}
                      <h1 className={Styles.Heading}>
                        <span className="Font_Rajdhani">Browse Courses </span>
                      </h1>
                      {/* <p>
                    In this free interactive webinar, our IELTS expert will help
                    us be ready to answer
                    <br /> a range of common questions types for Part 1 of the
                    Speaking Test.
                  </p>{" "} */}
                    </div>
                    <div className={Styles.Browse_Package_Scroller}>
                      <div
                        onClick={() => BrowsePackageRef?.current?.slickPrev()}
                      >
                        <FaCircleArrowLeft />
                      </div>
                      <div
                        onClick={() => BrowsePackageRef?.current?.slickNext()}
                      >
                        {" "}
                        <FaCircleArrowRight />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ margin: "0 30px" }}>
                  <Slider
                    ref={BrowsePackageRef}
                    style={{ width: "100%" }}
                    {...BrowsePacakgesettings}
                  >
                    {PackageRec?.map((el, i) => (
                      <div key={el._id}>
                        <PackageCard
                          setEnrollActive={setEnrollActive}
                          EnrollActive={EnrollActive}
                          setCourseId={setCourseId}
                          el={el}
                          Styles={Styles2}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </section>
            </>
          )}
        </section>
      ) : (
        " "
      )}

      {Mobile_Screen <= 480 ? (
        SidebarOpen2 ? (
          TypeOfTest == "MockTest" && MockTestRec?.length ? (
            <div className={Styles.MainContainer}>
              <div className={Styles.RightSidePanel}>
                {MockTestRec?.map((ev, index) => (
                  <RightMenu
                    Styles={Styles}
                    OpenMenuBar={OpenMenuBar}
                    index={ev._id}
                    OpenBarClick={OpenBarClick}
                    Details={ev}
                    SidebarOpen={SidebarOpen2}
                    SetSideBarOpen={setSideBarOpen2}
                  />
                ))}
              </div>
            </div>
          ) : (
            <RightSidePanel
              SidebarOpen={SidebarOpen2}
              SetSideBarOpen={setSideBarOpen2}
            />
          )
        ) : (
          ""
        )
      ) : TypeOfTest == "MockTest" && MockTestRec?.length ? (
        <div className={Styles.MainContainer}>
          <div className={Styles.RightSidePanel}>
            {MockTestRec?.map((ev, index) => (
              <div key={ev?._id}>
                <RightMenu
                  Styles={Styles}
                  OpenMenuBar={OpenMenuBar}
                  index={ev?._id}
                  OpenBarClick={OpenBarClick}
                  Details={ev}
                  SidebarOpen={SidebarOpen2}
                  SetSideBarOpen={setSideBarOpen2}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <RightSidePanel
          SidebarOpen={SidebarOpen2}
          SetSideBarOpen={setSideBarOpen2}
        />
      )}
    </div>
  );
}

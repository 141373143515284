import {
  TAKE_A_TEST_FAILED,
  TAKE_A_TEST_REQ,
  TAKE_A_TEST_SUBMITTED_FAILED,
  TAKE_A_TEST_SUBMITTED_REQ,
  TAKE_A_TEST_SUBMITTED_SUCCESS,
  TAKE_A_TEST_SUCCESS,
  TEST_QUESTION_LIST_FAILED,
  TEST_QUESTION_LIST_REQ,
  TEST_QUESTION_LIST_SUCCESS,
} from "../Constant/TLatConstant";
import api from "../Core/api";

export const Take_Test_Det = (UserData) => async (dispatch) => {

  try {
    dispatch({ type: TAKE_A_TEST_REQ });
    const { data } = await api.post("/psychometric/createId", UserData);
   
    dispatch({ type: TAKE_A_TEST_SUCCESS, payload: data?.Result });
  } catch (error) {

    dispatch({
      type: TAKE_A_TEST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Test_Submitted = (TestData) => async (dispatch) => {
  try {
    dispatch({ type: TAKE_A_TEST_SUBMITTED_REQ });
    const { data } = await api.post("/psychometric", TestData);
    dispatch({ type: TAKE_A_TEST_SUBMITTED_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: TAKE_A_TEST_SUBMITTED_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Test_Question_List = (TestData) => async (dispatch) => {
  try {
    dispatch({ type: TEST_QUESTION_LIST_REQ });
    const { data } = await api.post("/psychometric", TestData);
    dispatch({ type: TEST_QUESTION_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: TEST_QUESTION_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

import { useEffect, useState } from "react";
import NavigationRoute from "./Routes/NavigationRoute";
import { Link } from "react-router-dom";
import Navbar2 from "./Components/Navbar2";
import Footer from "./Components/Footer";

export const SitemapGenerator = () => {
  const [PathRoute, setPathRoute] = useState([]);

  useEffect(() => {
    const Paths = [];
    for (let i = 0; i < NavigationRoute()?.props.children.length; i++) {
      Paths.push(NavigationRoute()?.props.children[i].props.path);
    }
    setPathRoute(Paths);
  }, []);

  return (
    <div>
      <Navbar2 Page={"Sitemap"} />
      <ol style={{ padding: "1% 5%" }}>
        {PathRoute?.map((el, i) => (
          <li key={i}>
            <Link to={el} target="_blank" rel="noreferrer">
              {el}
            </Link>
          </li>
          //   `<url>
          //   <loc>https://testifyi.com${el}</loc>
          //   <lastmod>2024-09-25T10:23:45+00:00</lastmod>
          // </url>`
        ))}
      </ol>
      <Footer />
    </div>
  );
};

import { IoIosCloseCircle } from "react-icons/io";
import "./BookADemo.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PACKAGE_ADD_CART_RESET } from "../../Constant/OrderPackageConstant";
import { AddToCart } from "../../Action/OrderPackageAction";
import ClassCenterDropDown from "../Components/ClassCenterDropDown";
import TimingsDropDown from "../Components/TimingsDropDown";
import ClassModeDropDown from "../Components/ClassModeDropDown";

const BookForm_Model = {
  packageId: "",
  courseId: "",
  Timings: "",
  CustomTimings: "",
};
export default function Enroll({ setEnrollActive, Package_Id, courseId }) {
  const [BookForm, setBookForm] = useState(BookForm_Model);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { loading, success, error } = useSelector((s) => s.AddToCartReducer);
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);

  const handleSubmitDemo = (e) => {
    e.preventDefault();
    if (Login_User) {
      localStorage.setItem("Cart_Package_Id", JSON.stringify(Package_Id));
      localStorage.setItem(
        "Package_Timing",
        JSON.stringify({
          Timings: BookForm?.Timings,
          CustomTimings: BookForm?.CustomTimings,
        })
      );
      dispatch(
        AddToCart(
          {
            packageId: Package_Id,
            Timings: BookForm?.Timings,
            CustomTimings: BookForm?.CustomTimings,
            ClassMode: BookForm?.ClassMode,
          },
          "student/cart"
        )
      );
    } else {
      Navigate("/signup", {
        state: {
          PackageId: Package_Id,
          Timings: BookForm?.Timings,
          CustomTimings: BookForm?.CustomTimings,
          ClassMode: BookForm?.ClassMode,
        },
      });
      //   dispatch({ packageId: "any", Timings: "any", CustomTimings: "any" });
    }
  };

  useEffect(() => {
    dispatch({ type: PACKAGE_ADD_CART_RESET });
    if (success && success !== "") {
      setTimeout(() => {
        setBookForm(BookForm_Model);
        Navigate("/Cart");
      }, 300);
    }
  }, [success, dispatch]);

  useEffect(() => {
    setBookForm({ ...BookForm, courseId });
  }, [courseId]);

  return (
    <>
      <div
        id="CreatContact"
        style={{ width: "100%" }}
        className={"overlay-note middle-hand w100 BookDemo"}
      >
        <div className="container" id="booking-container">
          <h1>Choose Your Slot</h1>
          <div
            onClick={() => {
              setEnrollActive(false);
              setBookForm(BookForm_Model);
            }}
            className="closeBtn_Book"
          >
            <span>
              <IoIosCloseCircle />
            </span>
          </div>
          <form
            id="appointment-form"
            onSubmit={handleSubmitDemo}
            className="Form_Appointment"
          >
            <div>
              <div className="form-group">
                <label for="ClassMode">Class Mode:</label>
                <select
                  id="ClassMode"
                  name="ClassMode"
                  value={BookForm?.ClassMode}
                  onChange={(e) => {
                    if (e.target.value == 1) {
                      setBookForm({
                        ...BookForm,
                        ClassMode: e.target.value,
                        classLocation: "",
                      });
                    } else {
                      setBookForm({ ...BookForm, ClassMode: e.target.value });
                    }
                  }}
                  required
                >
                  <option value="">select</option>
                  <ClassModeDropDown />
                </select>
              </div>
            </div>

            {BookForm?.ClassMode == 1 || !BookForm?.ClassMode ? (
              ""
            ) : (
              <div>
                <div className="form-group">
                  <label for="classLocation">Class Location:</label>
                  <select
                    id="classLocation"
                    name="classLocation"
                    value={BookForm?.classLocation}
                    onChange={(e) =>
                      setBookForm({
                        ...BookForm,
                        classLocation: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">select</option>
                    <ClassCenterDropDown />
                  </select>
                </div>
              </div>
            )}

            <div>
              <div className="form-group">
                <label for="name">Select Timings:</label>
                <select
                  id="name"
                  name="Timings"
                  value={BookForm?.Timings}
                  onChange={(e) =>
                    setBookForm({ ...BookForm, Timings: e.target.value })
                  }
                  required
                >
                  <option value="">select</option>
                  <TimingsDropDown
                    centerlocation={BookForm?.classLocation}
                    classmodeid={BookForm?.ClassMode}
                    courseid={BookForm?.courseId}
                  />
                  <option value="Custom">Custom</option>
                </select>
              </div>
            </div>

            {BookForm?.Timings == "Custom" ? (
              <div>
                <div className="form-group">
                  <label for="PreferredSlot">
                    Please Share Your Preferred Slot:
                  </label>
                  <input
                    type="text"
                    id="PreferredSlot"
                    name="CustomTimings"
                    value={BookForm?.CustomTimings}
                    onChange={(e) =>
                      setBookForm({
                        ...BookForm,
                        CustomTimings: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="Book_A_demo_btn">
              <button type="submit" disabled={loading}>
                {loading ? "Loading..." : "Continue"}
              </button>
            </div>
          </form>
          <div id="message">
            {error ? <p style={{ color: "red" }}>{error}</p> : ""}
            {success ? <p style={{ color: "green" }}>{success}</p> : ""}
          </div>
        </div>
        {/* <div id="popup" className="popup sticky">
          <div className="popup-content">
            <div className="social-icons">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

import { useCallback, useEffect, useState } from "react";
import Styles from "./LiveTest.module.css";
import { IoTimerSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AlertPopup from "../../Components/AlertPopup";
import Loading from "../../Components/Loading";
import {
  Question_Data_Submit,
  Question_List,
} from "../../Action/QuestionTestAction";
import { SUBMIT_QUESTION_RECORD_RESET } from "../../Constant/QuestionTestConstant";
import ReactHelmet from "../../Components/ReactHelmet";
export default function LiveTest() {
  const [CurrentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [QuestionData, setQuestionData] = useState([]);
  const [QuestionAnswerData, setQUestionAnserData] = useState([]);
  const { Id } = useParams();
  const [CurrentQuestion, setCurrentQuestion] = useState({});
  const [QuestionTimer, setQuestionTimer] = useState("00 : 00");
  const [TimerData, setTimerData] = useState({ minute: 15, seconds: 0 });
  const [UserData, setUserData] = useState({});
  const [OpenNext, setOpenNext] = useState(false);
  const [AlertData, setAlertData] = useState({ msg: "", Alert: () => {} });
  const [AttemptedQuestion, setAttemptedQuestion] = useState(0);

  const dispatch = useDispatch();
  const {
    loading,
    Questiondata: QuestionAll,
    error,
  } = useSelector((s) => s.QuestionListResucer);

  const { Login_User } = useSelector((s) => s.USER_LOGIN_REDUCER);

  const Navigate = useNavigate();

  const {
    loading: laodingS,
    success,
    error: errorS,
  } = useSelector((s) => s.QuestionRecordSubmitReducer);

  const handlePrev = () => {
    if (CurrentQuestionNumber >= 1) {
      setCurrentQuestion(QuestionData[CurrentQuestionNumber - 1]);
      setCurrentQuestionNumber(CurrentQuestionNumber - 1);
    }
  };

  const handleNext = () => {
    if (
      QuestionData?.length &&
      QuestionData?.length - 1 != CurrentQuestionNumber
    ) {
      setCurrentQuestion(QuestionData[CurrentQuestionNumber + 1]);
      setCurrentQuestionNumber(CurrentQuestionNumber + 1);
    }
  };

  const handleSubmitForm = () => {
    dispatch(Question_Data_Submit({ AnswerData: QuestionAnswerData }, Id));
  };

  const formatTime = (time) => {
    if (time) {
      return String(time).padStart(2, "0");
    } else {
      return String(0).padStart(2, "0");
    }
  };

  useEffect(() => {
    setQuestionTimer(
      `${formatTime(TimerData?.minute)} : ${formatTime(TimerData?.seconds)}`
    );
  }, [TimerData]);

  useEffect(() => {
    let CountDown = setInterval(() => {
      if (TimerData?.seconds === 0 && TimerData?.minute !== 0) {
        setTimerData({
          ...TimerData,
          minute: TimerData?.minute - 1,
          seconds: 59,
        });
      } else if (TimerData?.seconds === 0 && TimerData?.minute === 0) {
        clearInterval(CountDown);
        handleSubmitForm();
      } else {
        setTimerData({
          ...TimerData,
          seconds: TimerData?.seconds - 1,
        });
      }
    }, 1000);

    return () => {
      clearInterval(CountDown);
    };
  });

  useEffect(() => {
    if (QuestionAll?.length) {
      setQuestionData([...QuestionAll]);
      setQUestionAnserData([...QuestionAll]);
    }
  }, [QuestionAll]);

  useEffect(() => {
    if (QuestionData?.length) {
      setCurrentQuestion(QuestionData[CurrentQuestionNumber]);
    }
  }, [QuestionData]);

  useEffect(() => {
    if (success || success == 0) {
      Navigate("/T-LAT-submitted", { state: { Result: success } });
      dispatch({ type: SUBMIT_QUESTION_RECORD_RESET });
    }
  }, [success, dispatch]);

  const Get_Questions = useCallback(() => {
    if (Id) {
      dispatch(Question_List(Id));
    }
  }, [dispatch, Id]);

  useEffect(() => {
    if (Login_User) {
      setUserData(Login_User);
    }
  }, [Login_User]);
  useEffect(() => {
    Get_Questions();
  }, [Get_Questions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (QuestionAnswerData) {
      let sum = QuestionAnswerData?.reduce(
        (acc, curr) => {
          if (curr?.Answer) {
            acc += 1;
          }
          return acc;
        },

        0
      );
      setAttemptedQuestion(sum);
    }
  }, [QuestionAnswerData]);

  useEffect(() => {
    if (error) {
      Navigate("/error", { state: { error: "Invalid Url !" } });
    }
  }, [error]);

  return (
    <>
      <ReactHelmet
        title={"Live Test"}
        description={""}
      />
      {/* navbar Section */}
      <AlertPopup
        OpenNext={OpenNext}
        setOpenNext={setOpenNext}
        AlertData={AlertData}
      />
      <navbar>
        <div className={Styles.Navbar}>
          <div>
            <img src="/assets/image/Logos/Testifyi_logo1.jpg" alt="logo" />
          </div>
          {window.innerWidth <= 490 ? (
            ""
          ) : (
            <div>
              <h3>T-LAT</h3>
            </div>
          )}

          <div className={Styles.Coordinator}>
            <div className={Styles.Coordinator_top}>
              <div>
                <img src="/assets/image/user.svg" alt="profile" />
              </div>
              <div>
                <h5>
                  {UserData?.First_Name
                    ? UserData?.First_Name +
                      " " +
                      (UserData?.Last_Name ? UserData?.Last_Name : "")
                    : ""}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </navbar>
      {/* Test Patter Details */}
      {/* Loading... */}
      {loading || laodingS ? <Loading /> : ""}

      <section className={Styles.Test_Live_Height}>
        {error ? (
          <p>{error}</p>
        ) : (
          <div className={Styles.Margin2}>
            <div>
              {window.innerWidth <= 480 ? (
                <div>
                  <div>
                    <h3 style={{ textAlign: "center", margin: "20px 0" }}>
                      T-LAT
                    </h3>
                  </div>
                  <table className={Styles.QuestionSec2}>
                    <tbody>
                      <tr>
                        <td colspan={2}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <h4 style={{ fontSize: "20px" }}>
                                Q:{CurrentQuestionNumber + 1}
                              </h4>
                              <div>
                                <h4 className={Styles.TimerHead}>
                                  <span>
                                    <IoTimerSharp />
                                  </span>
                                  <span>{QuestionTimer} min</span>
                                </h4>
                              </div>
                            </div>
                            <p className={Styles.Questiondet}>
                              {CurrentQuestion?.Question}
                            </p>
                          </div>
                          <div className={Styles.OptionContainer}>
                            {CurrentQuestion?.Options?.map((ev, j) => (
                              <div className={Styles.Options} key={j + 367}>
                                <input
                                  type="radio"
                                  name="Options"
                                  checked={
                                    QuestionAnswerData[CurrentQuestionNumber]
                                      ?.Answer == ev
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let answerData = [...QuestionData];
                                    answerData[CurrentQuestionNumber].Answer =
                                      ev;

                                    setQUestionAnserData([...answerData]);
                                  }}
                                />
                                <p>{ev}</p>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colspan={2}>
                          <div className={Styles.Attempted_Questions2}>
                            {QuestionData?.map((ev, i) => (
                              <div
                                onClick={() => {
                                  setCurrentQuestion(QuestionData[i]);
                                  setCurrentQuestionNumber(i);
                                }}
                                className={
                                  i === CurrentQuestionNumber
                                    ? Styles.QuestionNumber_Progress
                                    : QuestionAnswerData[i]?.Answer
                                    ? Styles.QuestionNumber_Attempted
                                    : Styles.QuestionNumber
                                }
                              >
                                {i + 1}
                              </div>
                            ))}{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan={2}>
                          <div className={Styles.NumberQuestionData}>
                            <span>
                              Number of Questions : <b> 30</b>
                            </span>
                            <span>
                              Time : <b>15 min</b>
                            </span>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colspan={2}>
                          <div>
                            <div className={Styles.NumberQuestionData}>
                              <span>
                                Number of Attempted :{" "}
                                <b style={{ color: "rgb(132, 225, 132)" }}>
                                  {" "}
                                  {AttemptedQuestion}
                                </b>
                              </span>
                              <span>
                                Number of Pending :{" "}
                                <b style={{ color: "blue" }}>
                                  {QuestionAnswerData?.length
                                    ? QuestionAnswerData?.length -
                                      AttemptedQuestion
                                    : 0}
                                </b>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colspan={2}>
                          <div className={Styles.QuestionBtns}>
                            <button
                              disabled={CurrentQuestionNumber === 0}
                              className={
                                CurrentQuestionNumber === 0
                                  ? Styles.disabled
                                  : Styles.active
                              }
                              onClick={() => handlePrev()}
                            >
                              PREV
                            </button>
                            {QuestionData?.length &&
                            QuestionData?.length - 1 == CurrentQuestionNumber &&
                            CurrentQuestionNumber !== 0 ? (
                              <button
                                onClick={() => {
                                  setAlertData({
                                    msg: "Are You sure want to Submit your test ?",
                                    Alert: () => handleSubmitForm(),
                                  });
                                  setOpenNext(true);
                                }}
                              >
                                SUBMIT
                              </button>
                            ) : (
                              <button onClick={() => handleNext()}>NEXT</button>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <table className={Styles.QuestionSec2}>
                  <tbody>
                    <tr>
                      <td rowspan={2}>
                        <div>
                          <h4 style={{ fontSize: "20px" }}>
                            Q:{CurrentQuestionNumber + 1}
                          </h4>
                          <p className={Styles.Questiondet}>
                            {CurrentQuestion?.Question}
                          </p>
                        </div>
                        <div className={Styles.OptionContainer}>
                          {CurrentQuestion?.Options?.map((ev, j) => (
                            <div className={Styles.Options} key={j + 367}>
                              <input
                                type="radio"
                                name="Options"
                                checked={
                                  QuestionAnswerData[CurrentQuestionNumber]
                                    ?.Answer == ev
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let answerData = [...QuestionData];
                                  answerData[CurrentQuestionNumber].Answer = ev;

                                  setQUestionAnserData([...answerData]);
                                }}
                              />
                              <p>{ev}</p>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div>
                          <h4 className={Styles.TimerHead}>
                            <span>
                              <IoTimerSharp />
                            </span>{" "}
                            <span>{QuestionTimer} min</span>
                          </h4>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {" "}
                        <div className={Styles.Attempted_Questions2}>
                          {QuestionData?.map((ev, i) => (
                            <div
                              onClick={() => {
                                setCurrentQuestion(QuestionData[i]);
                                setCurrentQuestionNumber(i);
                              }}
                              className={
                                i === CurrentQuestionNumber
                                  ? Styles.QuestionNumber_Progress
                                  : QuestionAnswerData[i]?.Answer
                                  ? Styles.QuestionNumber_Attempted
                                  : Styles.QuestionNumber
                              }
                            >
                              {i + 1}
                            </div>
                          ))}{" "}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div className={Styles.NumberQuestionData}>
                            <span>
                              Number of Attempted :{" "}
                              <b style={{ color: "rgb(132, 225, 132)" }}>
                                {AttemptedQuestion}
                              </b>
                            </span>
                            <span>
                              Number of Pending :{" "}
                              <b style={{ color: "blue" }}>
                                {QuestionAnswerData?.length
                                  ? QuestionAnswerData?.length -
                                    AttemptedQuestion
                                  : 0}
                              </b>
                            </span>
                            <div className={Styles.QuestionBtns}>
                              <button
                                disabled={CurrentQuestionNumber === 0}
                                className={
                                  CurrentQuestionNumber === 0
                                    ? Styles.disabled
                                    : Styles.active
                                }
                                onClick={() => handlePrev()}
                              >
                                PREV
                              </button>
                              {QuestionData?.length &&
                              QuestionData?.length - 1 ==
                                CurrentQuestionNumber &&
                              CurrentQuestionNumber !== 0 ? (
                                <button
                                  onClick={() => {
                                    setAlertData({
                                      msg: "Are You sure want to Submit your test ?",
                                      Alert: () => handleSubmitForm(),
                                    });
                                    setOpenNext(true);
                                  }}
                                >
                                  SUBMIT
                                </button>
                              ) : (
                                <button onClick={() => handleNext()}>
                                  NEXT
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={Styles.NumberQuestionData}>
                          <span>
                            Number of Questions : <b> 30</b>
                          </span>
                          <span>
                            Time : <b>15 min</b>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {/* <div className={Styles.TopQuestionTime}>
            
              <div>
                <h4 className={Styles.Heading}>
                  <span>
                    <IoTimerOutline />
                  </span>{" "}
                  <span>{QuestionTimer} min</span>
                </h4>
              </div>
            </div>
            <table className={Styles.QuestionSecTable}>
              <tbody className={Styles.QuestionSecTable}>
                {CurrentQuestion ? (
                  <tr className={Styles.QuestionSec}>
                    <td>
                      <div>
                        <h4 style={{ fontSize: "20px" }}>
                          Q:{CurrentQuestionNumber + 1}
                        </h4>
                        <p>
                          In publishing and graphic design, Lorem ipsum is a
                          placeholder text commonly used to demonstrate the
                          visual form of a document or a typeface without
                          relying on meaningful content. Lorem ipsum may be used
                          as a
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h4>Options :</h4>
                        <div className={Styles.Options}>
                          <input type="radio" />
                          <p>test option 1</p>
                        </div>
                        <div className={Styles.Options}>
                          <input type="radio" />
                          <p>test option 1</p>
                        </div>
                        <div className={Styles.Options}>
                          <input type="radio" />
                          <p>test option 1</p>
                        </div>
                        <div className={Styles.Options}>
                          <input type="radio" />
                          <p>test option 1</p>
                        </div>
                      </div>
                      <div className={Styles.QuestionBtns}>
                        <button
                          disabled={CurrentQuestionNumber === 0}
                          className={
                            CurrentQuestionNumber === 0
                              ? Styles.disabled
                              : Styles.active
                          }
                          onClick={() => handlePrev()}
                        >
                          PREV
                        </button>
                        {QuestionData?.length &&
                        QuestionData?.length - 1 == CurrentQuestionNumber &&
                        CurrentQuestionNumber !== 0 ? (
                          <button onClick={() => handleSubmitForm()}>
                            SUBMIT
                          </button>
                        ) : (
                          <button onClick={() => handleNext()}>NEXT</button>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>

            <div className={Styles.Attempted_Questions}>
              <div className={Styles.Help}>
                <div>
                  {/* <span>
                    <IoIosHelpCircleOutline />
                  </span>
                  <span>Help</span> 
                </div>

                <div>
                  <span>
                    Number Questions : <b> 30</b>
                  </span>
                  <span>
                    Time : <b>15 min</b>
                  </span>
                </div>
              </div>

              <div>
                {QuestionData?.map((ev, i) => (
                  <div
                    onClick={() => {
                      setCurrentQuestion(QuestionData[i]);
                      setCurrentQuestionNumber(i);
                    }}
                    className={
                      i === CurrentQuestionNumber
                        ? Styles.QuestionNumber_Progress
                        : Styles.QuestionNumber
                    }
                  >
                    {i + 1}
                  </div>
                ))}
              </div>
            </div> */}
            </div>
          </div>
        )}
      </section>

      {/* Test Patter Details */}
      {/* Top Footer Section*/}
      {/* <TopFooter /> */}
      {/* Footer Section */}
    </>
  );
}

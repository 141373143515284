import {
  MOCKTEST_DETAILS_FAILED,
  MOCKTEST_DETAILS_REQ,
  MOCKTEST_DETAILS_SUCCESS,
  MOCKTEST_LIST_ALL_FAIL,
  MOCKTEST_LIST_ALL_REQUEST,
  MOCKTEST_LIST_ALL_SUCCESS,
} from "../Constant/mockTestConstant";
import api from "../Core/api";

export const listMockTest = (PackageId) => async (dispatch) => {
  try {
    dispatch({
      type: MOCKTEST_LIST_ALL_REQUEST,
    });
    const { data } = await api.get(`/student/mocktest/${PackageId}`);

    const res = data.Result;

    dispatch({
      type: MOCKTEST_LIST_ALL_SUCCESS,
      payload: res,
      // total: data?.Total_Material,
    });
  } catch (error) {
    dispatch({
      type: MOCKTEST_LIST_ALL_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const mockTestDetails = (Id) => async (dispatch) => {
  try {
    dispatch({
      type: MOCKTEST_DETAILS_REQ,
    });
    const { data } = await api.get(`/student/mocktest/desc/${Id}`);
    const res = data.Result;
    dispatch({
      type: MOCKTEST_DETAILS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: MOCKTEST_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./Material.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { Package_List } from "../../Action/PacakgeAction";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import PackageCard from "../../Components/PackageCard";
import Styles2 from "../Home/Home.module.css";
import Enroll from "../Home/Enroll";
import Loading from "../../Components/Loading";
import MenuBarRight from "./MenuBarRight";
import { listMaterial } from "../../Action/materialAction";
import RightSidePanel from "../../Components/RightSidePanel";
import ReactHelmet from "../../Components/ReactHelmet";

export default function Materilas() {
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const [SidebarOpen2, SetSideBarOpen2] = useState(false);
  const [OpenMenuBar, setOpenMenuBar] = useState(false);
  const [OpenMenuBar2, setOpenMenuBar2] = useState(false);
  const [MaterialList, setMaterialList] = useState([]);
  const Mobile_Screen = window.innerWidth;
  const [CourseId, setCourseId] = useState("");
  const [EnrollActive, setEnrollActive] = useState(false);

  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );

  const { PackageData, loading: LoadingPackDet } = useSelector(
    (s) => s.SelectStudentPackageReducer
  );

  const { detailsMatertial } = useSelector((s) => s.materialdetReducer);
  const { materialListRec } = useSelector((s) => s.materialListAllRecReducer);
  const dispatch = useDispatch();
  const BrowsePackageRef = useRef();

  const BrowsePacakgesettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 2
        ? 2
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 }, "MainPackages"));
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  useEffect(() => {
    dispatch(listMaterial(PackageData?._id));
  }, [dispatch, PackageData]);

  const OpenBarClick = (index) => {
    if (OpenMenuBar === index) {
      setOpenMenuBar(false);
    } else {
      setOpenMenuBar(index);
    }
  };

  useEffect(() => {
    let Recs = [];
    if (materialListRec?.length) {
      for (let i = 0; i < materialListRec?.length; i++) {
        let x = false;

        for (let j = 0; j < Recs.length; j++) {
          if (Recs[j].materialTitle == materialListRec[i]?.materialTitle) {
            Recs[j].SubTitle.push({
              materialSubTitleId: materialListRec[i]?.materialSubTitleId,
              materialSubTitle: materialListRec[i]?.materialSubTitle,
            });
            x = true;
          }
        }

        if (x == false) {
          Recs.push({
            ...materialListRec[i],
            SubTitle: [
              {
                materialSubTitleId: materialListRec[i]?.materialSubTitleId,
                materialSubTitle: materialListRec[i]?.materialSubTitle,
              },
            ],
          });
        }
      }
    }
    setMaterialList([...Recs]);
  }, [materialListRec]);

  return (
    <div className={Styles.Dashboard}>
      {/*--- SidePanel--- */}
      <ReactHelmet title={"Material"} description={""} />

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Materials"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Materials"
        />
      ) : (
        ""
      )}

      {loadingPack ? <Loading /> : ""}

      {EnrollActive ? (
        <Enroll
          setEnrollActive={setEnrollActive}
          Package_Id={EnrollActive}
          courseId={CourseId}
        />
      ) : (
        ""
      )}

      {/* Middle Section */}

      {!SidebarOpen && !SidebarOpen2 ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              SetSideBarOpen2={SetSideBarOpen2}
              SidebarOpen2={SidebarOpen2}
              PageName={"Materials"}
            />
          </div>

          {materialListRec?.length ? (
            <div
              className="DangeriousTextFrame"
              dangerouslySetInnerHTML={{
                __html: detailsMatertial?.MaterialDescription,
              }}
            ></div>
          ) : (
            <div className={Styles.OnlineClassTopSection}>
              <div>
                <h3 style={{ textAlign: "center", fontWeight: "500" }}>
                  Please buy the package to unlock this section.
                </h3>
              </div>
            </div>
          )}

          {materialListRec?.length ? (
            ""
          ) : (
            <section style={{ background: "#f4f9ff", paddingBottom: "60px" }}>
              <div style={{ margin: "30px" }}>
                <div className={Styles.Browse_Package_Section}>
                  <div>
                    {" "}
                    <h1 className={Styles.Heading}>
                      <span className="Font_Rajdhani">Browse Courses </span>
                    </h1>
                    {/* <p>
                    In this free interactive webinar, our IELTS expert will help
                    us be ready to answer
                    <br /> a range of common questions types for Part 1 of the
                    Speaking Test.
                  </p>{" "} */}
                  </div>
                  <div className={Styles.Browse_Package_Scroller}>
                    <div onClick={() => BrowsePackageRef?.current?.slickPrev()}>
                      <FaCircleArrowLeft />
                    </div>
                    <div onClick={() => BrowsePackageRef?.current?.slickNext()}>
                      {" "}
                      <FaCircleArrowRight />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ margin: "0 30px" }}>
                <Slider
                  ref={BrowsePackageRef}
                  style={{ width: "100%" }}
                  {...BrowsePacakgesettings}
                >
                  {PackageRec?.map((el, i) => (
                    <div key={el._id}>
                      <PackageCard
                        setEnrollActive={setEnrollActive}
                        EnrollActive={EnrollActive}
                        setCourseId={setCourseId}
                        el={el}
                        Styles={Styles2}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          )}
        </section>
      ) : (
        " "
      )}

      {Mobile_Screen <= 480 ? (
        materialListRec?.length ? (
          <div className={Styles.MainContainer}>
            <div className={Styles.RightSidePanel}>
              {MaterialList?.map((ev, index) => (
                <MenuBarRight
                  Styles={Styles}
                  OpenMenuBar={OpenMenuBar}
                  OpenMenuBar2={OpenMenuBar2}
                  setOpenMenuBar2={setOpenMenuBar2}
                  index={ev?._id}
                  Details={ev}
                  OpenBarClick={OpenBarClick}
                />
              ))}
            </div>
          </div>
        ) : (
          <RightSidePanel
            SetSideBarOpen={SidebarOpen2}
            SidebarOpen={SidebarOpen2}
          />
        )
      ) : materialListRec?.length ? (
        <div className={Styles.MainContainer}>
          <div className={Styles.RightSidePanel}>
            {MaterialList?.map((ev, index) => (
              <MenuBarRight
                Styles={Styles}
                OpenMenuBar={OpenMenuBar}
                OpenMenuBar2={OpenMenuBar2}
                setOpenMenuBar2={setOpenMenuBar2}
                index={ev?._id}
                Details={ev}
                OpenBarClick={OpenBarClick}
              />
            ))}
          </div>
        </div>
      ) : (
        <RightSidePanel
          SetSideBarOpen={SidebarOpen2}
          SidebarOpen={SidebarOpen2}
        />
      )}
      {/* Right Section  */}
    </div>
  );
}

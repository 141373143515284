import { MdModeEdit } from "react-icons/md";
import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./Notification.module.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Package_List } from "../../Action/PacakgeAction";
import Loading from "../../Components/Loading";
import Enroll from "../Home/Enroll";
import Slider from "react-slick";
import PackageCard from "../../Components/PackageCard";
import Styles2 from "../Home/Home.module.css";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import { Take_Test_Det } from "../../Action/TLastAction";
import { TAKE_A_TEST_RESET } from "../../Constant/TLatConstant";
import { Test_Result } from "../../Action/QuestionTestAction";
import { ListOrders } from "../../Action/OrderPackageAction";
import { GET_TIME_ZONE } from "../../Model/HomeModel";
import ReactHelmet from "../../Components/ReactHelmet";
export default function Notification() {
  const [Score, setScore] = useState("");
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const Navigate = useNavigate();
  const Mobile_Screen = window.innerWidth;
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);
  const { loading, Test_Dets, error } = useSelector(
    (s) => s.Take_Test_Det_Reducer
  );
  const { TLatResult } = useSelector((s) => s.TestResultReducer);
  const [isBuyPackage, setisBuyPackage] = useState(false);
  const [CourseId, setCourseId] = useState("");
  const [EnrollActive, setEnrollActive] = useState(false);
  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );

  const { OrdersRec } = useSelector((s) => s.ListOrdersReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ListOrders());
  }, [dispatch]);
  const BrowsePackageRef = useRef();

  const BrowsePacakgesettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 2
        ? 2
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 }, "MainPackages"));
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  useEffect(() => {
    if (OrdersRec) {
      for (let i = 0; i < OrdersRec?.length; i++) {
        if (OrdersRec[i]?.isPaid) {
          setisBuyPackage(true);
          break;
        }
      }
    }
  }, [OrdersRec]);

  useMemo(() => {
    dispatch(Test_Result());
    const PsychometricTestScore = localStorage.getItem("PsychometricTestScore");
    if (PsychometricTestScore) {
      setScore(PsychometricTestScore);
    }
  }, [dispatch]);

  useEffect(() => {
    if (Test_Dets) {
      Navigate(`/T-LAT-acknowledge/${Test_Dets}`);
      dispatch({ type: TAKE_A_TEST_RESET });
    }

    if (error) {
      Navigate("/error", { state: { error } });
    }
  }, [Test_Dets, dispatch, error]);

  return (
    <div className={Styles.Dashboard}>
       <ReactHelmet
                    title={"Notification"}
                    description={"Student Dashboard Notification"}
                  />
      {/* SidePanel */}
      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Notification"
        />
      ) : (
        ""
      )}

      {loadingPack || loading ? <Loading /> : ""}

      {EnrollActive ? (
        <Enroll
          setEnrollActive={setEnrollActive}
          Package_Id={EnrollActive}
          courseId={CourseId}
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Notification"
        />
      ) : (
        ""
      )}

      {/* Middle Section */}

      {/* Middle Section */}
      {!SidebarOpen ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              PageName={"Notification"}
            />
          </div>
          <div className={Styles.OnlineClassTopSection}>
            <div>
              <h3 style={{ textAlign: "center", fontWeight: "500" }}>
                Please buy the package to unlock this section.
              </h3>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* Middle Section */}
      {Mobile_Screen >= 780 ? <RightSidePanel /> : ""}

      {/* Right Section  */}
    </div>
  );
}

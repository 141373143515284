export const TAKE_A_TEST_REQ = "TAKE_A_TEST_REQ";
export const TAKE_A_TEST_SUCCESS = "TAKE_A_TEST_SUCCESS";
export const TAKE_A_TEST_FAILED = "TAKE_A_TEST_FAILED";
export const TAKE_A_TEST_RESET = "TAKE_A_TEST_RESET";

export const TAKE_A_TEST_SUBMITTED_REQ = "TAKE_A_TEST_SUBMITTED_REQ";
export const TAKE_A_TEST_SUBMITTED_SUCCESS = "TAKE_A_TEST_SUBMITTED_SUCCESS";
export const TAKE_A_TEST_SUBMITTED_FAILED = "TAKE_A_TEST_SUBMITTED_FAILED";
export const TAKE_A_TEST_SUBMITTED_RESET = "TAKE_A_TEST_SUBMITTED_RESET";

export const TEST_QUESTION_LIST_REQ = "TEST_QUESTION_LIST_REQ";
export const TEST_QUESTION_LIST_SUCCESS = "TEST_QUESTION_LIST_SUCCESS";
export const TEST_QUESTION_LIST_FAILED = "TEST_QUESTION_LIST_FAILED";
export const TEST_QUESTION_LIST_RESET = "TEST_QUESTION_LIST_REQ";

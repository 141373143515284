import React, { useEffect, useState } from "react";
// import { IoMdNotifications } from "react-icons/io";
// import { GrMail } from "react-icons/gr";
import Styles from "./SidePanel.module.css";
import { TfiAlignJustify } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { SelectStudentPackage } from "../Action/CourseAction";
// import { useNavigate } from "react-router-dom";
import { PurchasedPackages } from "../Action/PacakgeAction";

const TopBar = ({
  PageName,
  SetSideBarOpen,
  SidebarOpen,
  SidebarOpen2,
  SetSideBarOpen2,
}) => {
  const [IsPaidPackages, setIsPaidPackages] = useState([]);
  const [SelectPack, setSelectPack] = useState("");
  const MobileScreen = window.innerWidth;
  const { OrdersRec } = useSelector((s) => s.ListOrdersReducer);
  const dispatch = useDispatch();
  const { PackageData } = useSelector((s) => s.SelectStudentPackageReducer);
  // const { purchasedPack } = useSelector((s) => s.PurchasedPackagesReducer);

  useEffect(() => {
    // if (!PackageData?._id && purchasedPack?.length) {
    //   dispatch(SelectStudentPackage(purchasedPack[purchasedPack?.length - 1]?.PackageId));
    //   setSelectPack(purchasedPack[purchasedPack?.length - 1]?.PackageId?._id);
    // }
    let PackagesBuy = [];
    let Package_Id = "";
    let Pack = {};
    if (OrdersRec) {
      for (let i = 0; i < OrdersRec?.length; i++) {
        if (OrdersRec[i]?.isPaid) {
          PackagesBuy.push({ ...OrdersRec[i] });
          Package_Id = OrdersRec[i]?.PackageId?._id;
          Pack = OrdersRec[i]?.PackageId;
        }
      }
    }

    setIsPaidPackages([...PackagesBuy]);
    if (!PackageData?._id) {
      dispatch(SelectStudentPackage(Pack));
      setSelectPack(Package_Id);
    }
  }, [OrdersRec]);

  useEffect(() => {
    dispatch(PurchasedPackages());
  }, [dispatch]);

  // const Navigate = useNavigate();

  return (
    <div>
      <div style={{ marginTop: "-25px" }} className={Styles.TopBar}>
        {MobileScreen <= 780 ? (
          <h2
            onClick={() => SetSideBarOpen(!SidebarOpen)}
            style={{ cursor: "pointer" }}
          >
            <TfiAlignJustify />
          </h2>
        ) : (
          ""
        )}
        <div>
          <h2>{PageName}</h2>
        </div>
        {window.innerWidth <= 480 ? (
          ""
        ) : (
          <div className={Styles.PackageSelect}>
            <select
              name="SelectPack"
              value={PackageData?._id || SelectPack}
              onChange={(e) => {
                let Pack = IsPaidPackages?.find((ev) => {
                  if (ev.PackageId?._id == e.target.value) return ev;
                });
                dispatch(SelectStudentPackage(Pack?.PackageId));
                setSelectPack(e.target.value);
              }}
            >
              <option>My Packages</option>
              {IsPaidPackages?.map((eh) => (
                <option key={eh?.PackageId?._id} value={eh?.PackageId?._id}>
                  {eh?.PackageId?.Name}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* <div>
          <p onClick={() => Navigate("/Notification")}>
            <IoMdNotifications />
          </p>
          <p>
            <GrMail />
          </p>
        </div> */} 
        {MobileScreen <= 780 ? (
          <h2
            onClick={() => SetSideBarOpen2(!SidebarOpen2)}
            style={{ cursor: "pointer" }}
          >
            <TfiAlignJustify />
          </h2>
        ) : (
          ""
        )}
      </div>
      {window.innerWidth >= 481 ? (
        ""
      ) : (
        <div className={Styles.PackageSelect}>
          <select
            name="SelectPack"
            value={PackageData?._id || SelectPack}
            onChange={(e) => {
              let Pack = IsPaidPackages?.find((ev) => {
                if (ev.PackageId?._id == e.target.value) return ev;
              });
              dispatch(SelectStudentPackage(Pack?.PackageId));
              setSelectPack(e.target.value);
            }}
          >
            <option>Packages</option>
            {IsPaidPackages?.map((eh) => (
              <option key={eh?.PackageId?._id} value={eh?.PackageId?._id}>
                {eh?.PackageId?.Name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default TopBar;

import {
  GURULLY_DETAILS_FAIL,
  GURULLY_DETAILS_REQUEST,
  GURULLY_DETAILS_SUCCESS,
} from "../Constant/GurullyConstant";
import api from "../Core/api";

export const GurullyDet = (PackageId) => async (dispatch) => {
  try {
    dispatch({ type: GURULLY_DETAILS_REQUEST });
    const { data } = await api.get(`/student/gurully/${PackageId}`);
    dispatch({ type: GURULLY_DETAILS_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: GURULLY_DETAILS_FAIL,
      payload:
        error.response && error?.response?.data?.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

import {
  MOCKTEST_DETAILS_FAILED,
  MOCKTEST_DETAILS_REQ,
  MOCKTEST_DETAILS_SUCCESS,
  MOCKTEST_LIST_ALL_FAIL,
  MOCKTEST_LIST_ALL_REQUEST,
  MOCKTEST_LIST_ALL_SUCCESS,
} from "../Constant/mockTestConstant";

export const mockTestListAllRecReducer = (
  state = {},
  { type, payload, total }
) => {
  switch (type) {
    case MOCKTEST_LIST_ALL_REQUEST: {
      return { loading: true };
    }

    case MOCKTEST_LIST_ALL_SUCCESS: {
      return { loading: false, MockTestRec: payload, total };
    }

    case MOCKTEST_LIST_ALL_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const mockTestDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MOCKTEST_DETAILS_REQ: {
      return { loading: true };
    }

    case MOCKTEST_DETAILS_SUCCESS: {
      return { loading: false, mockDesc: payload };
    }

    case MOCKTEST_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const USER_SIGNUP_REQ = "USER_SIGNUP_REQ";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAILED = "USER_SIGNUP_FAILED";
export const USER_SIGNUP_RESET = "USER_SIGNUP_RESET";

export const USER_LOGIN_REQ = "USER_LOGIN_REQ";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";

export const USER_RESET_PASSWORD_REQ = "USER_RESET_PASSWORD_REQ";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAILED = "USER_RESET_PASSWORD_FAILED";
export const USER_RESET_PASSWORD_RESET = "USER_RESET_PASSWORD_RESET";

export const USER_VERIFY_URL_REQ = "USER_VERIFY_URL_REQ";
export const USER_VERIFY_URL_SUCCESS = "USER_VERIFY_URL_SUCCESS";
export const USER_VERIFY_URL_FAILED = "USER_VERIFY_URL_FAILED";
export const USER_VERIFY_URL_RESET = "USER_VERIFY_URL_RESET";

export const USER_PASSWORD_CHANGE_REQ = "USER_PASSWORD_CHANGE_REQ";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAILED = "USER_PASSWORD_CHANGE_FAILED";
export const USER_PASSWORD_CHANGE_RESET = "USER_PASSWORD_CHANGE_RESET";

export const USER_UPDATE_DETAILS_REQ = "USER_UPDATE_DETAILS_REQ";
export const USER_UPDATE_DETAILS_SUCCESS = "USER_UPDATE_DETAILS_SUCCESS";
export const USER_UPDATE_DETAILS_FAILED = "USER_UPDATE_DETAILS_FAILED";
export const USER_UPDATE_DETAILS_RESET = "USER_UPDATE_DETAILS_RESET";

import { useEffect, useState } from "react";
import Styles from "./LiveTest.module.css";
import { useSelector } from "react-redux";
import Footer from "../../Components/Footer";
import { useLocation, useNavigate } from "react-router-dom";

export default function TestSubmitted() {
  const [UserData, setUserData] = useState({});
  const { Login_User_Loading, Login_User, Login_User_Error } = useSelector(
    (s) => s.USER_LOGIN_REDUCER
  );
  const Navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (Login_User) {
      setUserData(Login_User);
    }
  }, [Login_User]);

  useEffect(()=>{

    if(!state?.Result){
   
    }

  },[state])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <navbar>
        <div className={Styles.Navbar}>
          <div>
            <img src="/assets/image/Logos/Testifyi_logo1.jpg" alt="logo" />
          </div>
          <div>{/* <h3>Psychometric Test</h3> */}</div>

          <div className={Styles.Coordinator}>
            <div className={Styles.Coordinator_top}>
              <div>
                <img src="/assets/image/user.svg" alt="profile" />
              </div>
              <div>
                <h5>
                  {" "}
                  {UserData?.First_Name
                    ? UserData?.First_Name +
                      " " +
                      (UserData?.Last_Name ? UserData?.Last_Name : "")
                    : "User"}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </navbar>

      {/* Acknowledge */}
      <section className={Styles.Margin4}>
        <div className={Styles.Container_Ack}>
          <h4
            style={{
              textAlign: "Center",
              fontSize: window.innerWidth <= 480 ? "30px" : "35px",
              margin: "15px 0",
            }}
          >
            YOU HAVE SCORED
          </h4>

          <h4
            style={{
              textAlign: "Center",
              fontSize: "45px",
              color: "rgb(90, 191, 90)",
              margin: "4px 0",
              padding: "0",
            }}
          >
            {state?.Result}
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <button
              onClick={() => Navigate("/dashboard")}
              className={Styles.custom_btn}
            >
              BACK TO DASHBOARD
            </button>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <Footer />
    </div>
  );
}

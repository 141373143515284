import {
  GET_ATTENDANCE_FAILED,
  GET_ATTENDANCE_REQ,
  GET_ATTENDANCE_SUCCESS,
} from "../Constant/AttendanceConstatnt";
import api from "../Core/api";

export const AttendanceRecordsList = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ATTENDANCE_REQ });
    const { data } = await api.get("/attendance");
    dispatch({ type: GET_ATTENDANCE_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: GET_ATTENDANCE_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

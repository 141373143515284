import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./Attendance.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { Package_List } from "../../Action/PacakgeAction";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import PackageCard from "../../Components/PackageCard";
import Styles2 from "../Home/Home.module.css";
import Enroll from "../Home/Enroll";
import Loading from "../../Components/Loading";
import ReactHelmet from "../../Components/ReactHelmet";
import {
  FaCalendarAlt,
  FaCheckCircle,
  FaTimesCircle,
  FaPercentage,
} from "react-icons/fa";
import CalendarBox from "./CalenderBox";

import { AttendanceRecordsList } from "../../Action/AttendanceAction";

export default function Attendance() {
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const [OpenMenuBar2, setOpenMenuBar2] = useState(false);
  const Mobile_Screen = window.innerWidth;
  const [CourseId, setCourseId] = useState("");
  const [EnrollActive, setEnrollActive] = useState(false);
  const [prevMonth, setprevMonth] = useState(0);
  const [prevYear, setprevYear] = useState(0);

  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );
  const dispatch = useDispatch();

  // console.log(
  //   new Date("10-12-2025").getDate() == new Date("10/12/2025").getDate()
  // );

  const currentDate = new Date();

  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  useEffect(() => {
    setprevYear(currentMonth == 0 ? currentYear - 1 : currentYear);
    setprevMonth(currentMonth == 0 ? 11 : currentMonth - 1);
  }, [currentMonth, currentYear]);

  // const prevMonth = currentMonth == 0 ? 11 : currentMonth - 1;
  // const prevYear = currentMonth == 0 ? currentYear - 1 : currentYear;

  const BrowsePackageRef = useRef();
  const BrowsePacakgesettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 2
        ? 2
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const handleChnage = (e) => {};

  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 }, "MainPackages"));
    dispatch(AttendanceRecordsList());
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  return (
    <div className={Styles.Dashboard}>
      <ReactHelmet title={"Attendance"} description={"Student Attendance"} />
      {/* SidePanel */}

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Attendance"
        />
      ) : (
        ""
      )}

      {loadingPack ? <Loading /> : ""}

      {EnrollActive ? (
        <Enroll
          setEnrollActive={setEnrollActive}
          Package_Id={EnrollActive}
          courseId={CourseId}
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Attendance"
        />
      ) : (
        ""
      )}

      {/* Middle Section */}

      {!SidebarOpen && !OpenMenuBar2 ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              SetSideBarOpen2={setOpenMenuBar2}
              SidebarOpen2={OpenMenuBar2}
              PageName={"Attendance"}
            />
          </div>

          <div className={Styles.TopAttendance}>
            <div>
              <p>
                <FaCalendarAlt />
              </p>
              <h4>54</h4>
              <p className={Styles.text}>Total Days</p>
            </div>
            <div>
              <p className={Styles.Present}>
                <FaCheckCircle />
              </p>
              <h4>34</h4>
              <p className={Styles.text}>Present</p>
            </div>
            <div>
              <p className={Styles.Absent}>
                <FaTimesCircle />
              </p>
              <h4>45</h4>
              <p className={Styles.text}>Absent</p>
            </div>
            <div>
              <p>
                <FaPercentage />
              </p>
              <h4>50</h4>
              <p className={Styles.text}>% Attendance</p>
            </div>
          </div>

          {/* <div className={Styles.AttendanceTable}>
            <table>
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>TIMING</th>
                  <th>TEACHER</th>
                  <th>STATUS</th>
                  <th>REMARKS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>10-04-2024</td>
                  <td>10:00 AM to 11:00 PM</td>
                  <td>Teacher</td>
                  <td className={Styles.present}>Present</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>10-04-2024</td>
                  <td>10:00 AM to 11:00 PM</td>
                  <td>Teacher</td>
                  <td className={Styles.present}>Present</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>10-04-2024</td>
                  <td>10:00 AM to 11:00 PM</td>
                  <td>Teacher</td>
                  <td className={Styles.present}>Present</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>10-04-2024</td>
                  <td>10:00 AM to 11:00 PM</td>
                  <td>Teacher</td>
                  <td className={Styles.absent}>Absent</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>10-04-2024</td>
                  <td>10:00 AM to 11:00 PM</td>
                  <td>Teacher</td>
                  <td className={Styles.absent}>Absent</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <div className={Styles.AttendanceCalender}>
            <div>
              <div>
              
              </div>
              <div>
                <button
                  onClick={() => goToPreviousMonth()}
                  className="arrow-button prev-button"
                >
                  &#8592;
                </button>

                <button
                  onClick={() => goToNextMonth()}
                  className="arrow-button next-button"
                >
                  &#8594;
                </button>
              </div>
            </div>
            <div>
              <CalendarBox month={prevMonth} year={prevYear} />
              <CalendarBox month={currentMonth} year={currentYear} />
            </div>
          </div>

            {/* <div>
                  <select
                    value={currentMonth}
                    onChange={(e) => {
                      setCurrentMonth(e.target.value);
                    }}
                  >
                   
                    {monthJson?.map((ev, i) => (
                      <option key={i} value={i}>
                        {ev}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    value={currentYear}
                    onChange={(e) => setCurrentYear(e.target.value)}
                  >
                
                    {YearJson?.map((ev) => (
                      <option key={ev?.id} value={ev?.Year}>
                        {ev?.Year}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* <div>
                  <button onClick={()=>} >search</button>
                </div>
                <div>
                  <button>clear</button>
                </div> */}

        

          {/* <div className={Styles.OnlineClassTopSection}>
            <div>
              <h3 style={{ textAlign: "center", fontWeight: "500" }}>
                Please buy the package to unlock this section.
              </h3>
            </div>
          </div>

          <section style={{ background: "#f4f9ff", paddingBottom: "60px" }}>
            <div style={{ margin: "30px" }}>
              <div className={Styles.Browse_Package_Section}>
                <div>
                  <h1 className={Styles.Heading}>
                    <span className="Font_Rajdhani">Browse Courses </span>
                  </h1>

                  
                </div>
                <div className={Styles.Browse_Package_Scroller}>
                  <div onClick={() => BrowsePackageRef?.current?.slickPrev()}>
                    <FaCircleArrowLeft />
                  </div>
                  <div onClick={() => BrowsePackageRef?.current?.slickNext()}>
                    <FaCircleArrowRight />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ margin: "0 30px" }}>
              <Slider
                ref={BrowsePackageRef}
                style={{ width: "100%" }}
                {...BrowsePacakgesettings}
              >
                {PackageRec?.map((el, i) => (
                  <div key={el._id}>
                    <PackageCard
                      setEnrollActive={setEnrollActive}
                      EnrollActive={EnrollActive}
                      setCourseId={setCourseId}
                      el={el}
                      Styles={Styles2}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </section> */}
          {/* <div className={Styles.OnlineClassTopSection}>
            <div>
              <h3>Upcoming Mock Test</h3>
              <p>
                IELTS is an English language test for study, migration or work.
                Over three million people take out test every year. IELTS is
                accepted by more than 11,000 employers, universities & schools
                around the world.
              </p>
            </div>
            <div>
              <select>
                <option>This Week</option>
              </select>
            </div>
          </div> */}

          {/* <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            className={Styles.DashboardBtn}
          >
            <button
              onClick={() => Navigate("/T-LAT")}
              className={Styles.custom_btn}
            >
              Start Test
            </button>
          </div>
          <div>
            <div className={Styles.UpcomingMock_Test_Card}>
              <div className={Styles.TopSection}>
                <div>
                  <p>Package Name</p>
                  <h4>
                    IELTS Academic and IELTS General <br />
                    Training Mock Test.
                  </h4>
                </div>
                <div>
                  <p>Other Links</p>
                  <div className={Styles.DashboardBtn}>
                    <button>Course Material</button>
                    <button>Cheat Sheet</button>
                  </div>
                </div>
              </div>

              <div className={Styles.IncludeTest}>
                <p>Included Mock Test:</p>
              </div>

              <div className={Styles.MockCardTest}>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div></div>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>
                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*  2nd design section card mock test */}

          {/* <div>
            <div className={Styles.UpcomingMock_Test_Card}>
              <div className={Styles.TopSection}>
                <div>
                  <p>Package Name</p>
                  <h4>
                    IELTS Academic and IELTS General <br />
                    Training Mock Test.
                  </h4>
                </div>
                <div>
                  <p>Other Links</p>
                  <div className={Styles.DashboardBtn}>
                    <button>Course Material</button>
                    <button>Cheat Sheet</button>
                  </div>
                </div>
              </div>
              <div className={Styles.IncludeTest}>
                <p>Included Mock Test:</p>
              </div>
              <div className={Styles.MockCardTest}>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>
                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div></div>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      ) : (
        " "
      )}

      {Mobile_Screen <= 480 ? (
        <RightSidePanel
          SetSideBarOpen={setOpenMenuBar2}
          SidebarOpen={OpenMenuBar2}
        />
      ) : (
        <RightSidePanel
          SetSideBarOpen={setOpenMenuBar2}
          SidebarOpen={OpenMenuBar2}
        />
      )}
      {/* Right Section  */}
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import Styles from "./Navbar.module.css";

export default function BottomMenu() {
  const Navigate = useNavigate();
  return (
    <div className={Styles.Menus}>
      <div className={Styles.BottomMenu}>
        <div>
          <p> IELTS Courses</p>
        </div>
        <div>
          <p> Mock Papers</p>
        </div>
        <div>
          <p> Online Lectures</p>
        </div>
        <div onClick={() => Navigate("/dashboard")}>
          <p> My Dashboard</p>
        </div>
      </div>
    </div>
  );
}

// Timings

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Timings } from "../../Action/CommonEnumsAction";

const TimingsDropDown = React.memo(
  ({ courseid, classmodeid, centerlocation }) => {
    const { classTimingRec } = useSelector((s) => s.listClassTimingReducer);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(Timings({ courseid, classmodeid, centerlocation }));
    }, [dispatch, courseid, classmodeid, centerlocation]);

    return (
      <>
        {classTimingRec ? (
          classTimingRec?.map((ev, i) => (
            <option key={ev?._id} value={ev?._id}>
              {ev?.Timings}
            </option>
          ))
        ) : (
          <></>
        )}
      </>
    );
  }
);

export default TimingsDropDown;

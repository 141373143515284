import {
  USER_LOGIN_FAILED,
  USER_LOGIN_REQ,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_PASSWORD_CHANGE_FAILED,
  USER_PASSWORD_CHANGE_REQ,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_RESET_PASSWORD_FAILED,
  USER_RESET_PASSWORD_REQ,
  USER_RESET_PASSWORD_SUCCESS,
  USER_SIGNUP_FAILED,
  USER_SIGNUP_REQ,
  USER_SIGNUP_RESET,
  USER_SIGNUP_SUCCESS,
  USER_UPDATE_DETAILS_FAILED,
  USER_UPDATE_DETAILS_REQ,
  USER_UPDATE_DETAILS_SUCCESS,
  USER_VERIFY_URL_FAILED,
  USER_VERIFY_URL_REQ,
  USER_VERIFY_URL_SUCCESS,
} from "../Constant/UserConstant";
import { GET_LOCAL, REMOVE_LOCAL, SET_LOCAL } from "../Model/LocalData";
import api from "../Core/api";

export const USER_LOGIN = (user_data) => async (dispatch) => {
  try {
    SET_LOCAL(
      "Sattmpt",
      localStorage.getItem("Sattmpt") ? +localStorage.getItem("Sattmpt") + 1 : 1
    );
    dispatch({ type: USER_LOGIN_REQ });
    const { data } = await api.post("/student/user/login", user_data);

    if (data && data?.accesstoken) {
      REMOVE_LOCAL("SignUpAttempt");
      REMOVE_LOCAL("Sattmpt");
      localStorage.setItem("_testifyiTkn", data?.accesstoken);
      SET_LOCAL("IELTS_USER", data?.userdata);
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data?.userdata });
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAILED,
      payload:
        error.response && error?.response?.data?.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const LOGOUT = () => async (dispatch) => {
  REMOVE_LOCAL("IELTS_USER");
  REMOVE_LOCAL("_testifyiTkn");
  dispatch({ type: USER_LOGIN_RESET });
};

export const RESET_LOGIN_USER = () => (dispatch) => {
  dispatch({ type: USER_LOGIN_RESET });
};

export const USER_SIGNUP = (user_data) => async (dispatch) => {
  try {
    const AttemptSignup = GET_LOCAL("SignUpAttempt")
      ? JSON.parse(GET_LOCAL("SignUpAttempt"))
      : 0;
    SET_LOCAL("SignUpAttempt", AttemptSignup ? +AttemptSignup + 1 : 1);
    dispatch({ type: USER_SIGNUP_REQ });
    const { data } = await api.post("/student/user", user_data);
    REMOVE_LOCAL("SignUpAttempt");
    dispatch({ type: USER_SIGNUP_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: USER_SIGNUP_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const RESET_SIGNUP_USER = () => (dispatch) => {
  dispatch({ type: USER_SIGNUP_RESET });
};

export const USER_FORGOT_PASSWORD = (user_data) => async (dispatch) => {
  try {
    dispatch({ type: USER_RESET_PASSWORD_REQ });
    const { data } = await api.post("/student/user/forgotpassword", user_data);
    dispatch({ type: USER_RESET_PASSWORD_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const VERIFY_PASSWORD_URL = (Url_Data) => async (dispatch) => {
  try {
    dispatch({ type: USER_VERIFY_URL_REQ });
    const { data } = await api.post("/student/user/verify", Url_Data);
    dispatch({ type: USER_VERIFY_URL_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: USER_VERIFY_URL_FAILED,
      payload:
        error.response && error.response?.data?.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const CHANGE_PASSWORD = (user_data) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_CHANGE_REQ });
    const { data } = await api.post("/student/user/set", user_data);

    dispatch({ type: USER_PASSWORD_CHANGE_SUCCESS, payload: data.Result });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_CHANGE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const UPDATE_USER_DET = (Details) => async (dispatch) => {
  try {
    dispatch({ type: USER_UPDATE_DETAILS_REQ });
    const { data } = await api.post("/", Details);
    dispatch({ type: USER_UPDATE_DETAILS_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const ORDER_PACKAGE_REQ = "ORDER_PACKAGE_REQ";
export const ORDER_PACKAGE_SUCCESS = "ORDER_PACKAGE_SUCCESS";
export const ORDER_PACKAGE_FAILED = "ORDER_PACKAGE_FAILED";
export const ORDER_PACKAGE_RESET = "ORDER_PACKAGE_RESET";

export const PACKAGE_ADD_CART_REQ = "PACKAGE_ADD_CART_REQ";
export const PACKAGE_ADD_CART_SUCCESS = "PACKAGE_ADD_CART_SUCCESS";
export const PACKAGE_ADD_CART_FAILED = "PACKAGE_ADD_CART_FAILED";
export const PACKAGE_ADD_CART_RESET = "PACKAGE_ADD_CART_RESET";

export const PACKAGE_REMOVE_CART_REQ = "PACKAGE_REMOVE_CART_REQ";
export const PACKAGE_REMOVE_CART_SUCCESS = "PACKAGE_REMOVE_CART_SUCCESS";
export const PACKAGE_REMOVE_CART_FAILED = "PACKAGE_REMOVE_CART_FAILED";
export const PACKAGE_REMOVE_CART_RESET = "PACKAGE_REMOVE_CART_RESET";

export const CART_DETAILS_LIST_REQ = "CART_DETAILS_LIST_REQ";
export const CART_DETAILS_LIST_SUCCESS = "CART_DETAILS_LIST_SUCCESS";
export const CART_DETAILS_LIST_FAILED = "CART_DETAILS_LIST_FAILED";
export const CART_DETAILS_LIST_RESET = "CART_DETAILS_LIST_RESET";

export const ORDERS_LIST_REQ = "ORDERS_LIST_REQ";
export const ORDERS_LIST_SUCCESS = "ORDERS_LIST_SUCCESS";
export const ORDERS_LIST_FAILED = "ORDERS_LIST_FAILED";
export const ORDERS_LIST_RESET = "ORDERS_LIST_RESET";

export const ORDERS_STATUS_REQ = "ORDERS_STATUS_REQ";
export const ORDERS_STATUS_SUCCESS = "ORDERS_STATUS_SUCCESS";
export const ORDERS_STATUS_FAILED = "ORDERS_STATUS_FAILED";
export const ORDERS_STATUS_RESET = "ORDERS_STATUS_RESET";

export const COUPON_APPLY_REQ = "COUPON_APPLY_REQ";
export const COUPON_APPLY_SUCCESS = "COUPON_APPLY_SUCCESS";
export const COUPON_APPLY_FAILED = "COUPON_APPLY_FAILED";
export const COUPON_APPLY_RESET = "COUPON_APPLY_RESET";

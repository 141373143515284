import React, { useEffect, useRef, useState } from "react";
import TopFooter from "../../Components/TopFooterSection";
import Footer from "../../Components/Footer";
import Styles from "./Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Login_Form_Model } from "../../Model/UserModel";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { USER_LOGIN } from "../../Action/UserAction";
import Loading from "../../Components/Loading";
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   validateCaptcha,
// } from "react-simple-captcha";
// import { GET_LOCAL } from "../../Model/LocalData";
// import { USER_LOGIN_FAILED } from "../../Constant/UserConstant";
import Navbar2 from "../../Components/Navbar2";
import ReactHelmet from "../../Components/ReactHelmet";
const Login = () => {
  const [LoginForm, setLoginForm] = useState(Login_Form_Model);
  const [PasswordVisible, setPasswordVisible] = useState(false);
  const { Login_User_Loading, Login_User, Login_User_Error } = useSelector(
    (state) => state.USER_LOGIN_REDUCER
  );
  
  // const LoginAttmpt = GET_LOCAL("Sattmpt");

  const PasswordRef = useRef();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const handleChange = (e) => {
    setLoginForm({ ...LoginForm, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // if (LoginAttmpt) {
    //   let user_captcha = document.getElementById("user_captcha_input").value;
    //   if (validateCaptcha(user_captcha) === true) {
    //     dispatch(USER_LOGIN(LoginForm));
    //   } else {
    //     dispatch({
    //       type: USER_LOGIN_FAILED,
    //       payload: "Error - Captcha doesn't matched !",
    //     });
    //   }
    // } else {
    dispatch(USER_LOGIN(LoginForm));
    // }

    setTimeout(() => {
      PasswordRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 700);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Login_User) {
      Navigate("/dashboard");
    }
  }, [dispatch, Navigate, Login_User]);

  // useEffect(() => {
  //   if (LoginAttmpt) {
  //     loadCaptchaEnginge(4, "grey", "white", "symbols");
  //   }
  // }, [LoginAttmpt, Login_User_Error]);

  return (
    <>
          <ReactHelmet
            title={"Login"}
            description={""}
          />
      {/* navbar Section */}
      <Navbar2 Page={"Login"} />
      {Login_User_Loading ? <Loading /> : ""}
      {/* Fixed Menu Section */}
      <section className={Styles.Login}>
        <div className={Styles.container}>
          <div className={Styles.wrapper}>
            <div className={Styles.title}>
              <h1 className="Font_Rajdhani">Login </h1>
              <p className="Font_Rajdhani_normal">Access to our dashboard</p>
            </div>
            <form onSubmit={handleLogin}>
              <div className={Styles.row}>
                <i className="fas fa-user"></i>
                <input
                  name="EmailAddress"
                  value={LoginForm.EmailAddress}
                  onChange={handleChange}
                  type="text"
                  placeholder="Email or Username"
                  required
                />
              </div>
              <div className={Styles.row}>
                <i className="fas fa-lock"></i>
                <input
                  name="Password"
                  value={LoginForm.Password}
                  onChange={handleChange}
                  type={PasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  required
                />
                {PasswordVisible ? (
                  <span
                    className={Styles.VisiblePass}
                    onClick={() => setPasswordVisible(!PasswordVisible)}
                  >
                    <AiOutlineEye />
                  </span>
                ) : (
                  <span
                    className={Styles.VisiblePass}
                    onClick={() => setPasswordVisible(!PasswordVisible)}
                  >
                    <AiOutlineEyeInvisible />
                  </span>
                )}
              </div>
              {/* {LoginAttmpt ? (
                <div className={Styles.Captach}>
                  <div className="col mt-3 ">
                    <LoadCanvasTemplate reloadText={"↻"} reloWdColor="black" />
                  </div>
                  <div className={Styles.row}>
                    <input
                      placeholder="Enter Captcha Value"
                      id="user_captcha_input"
                      name="user_captcha_input"
                      type="text"
                      required
                    />
                  </div>
                </div>
              ) : (
                ""
              )} */}
              <div className={Styles.pass}>
                <Link to="/forgot/password">Forgot password?</Link>
              </div>

              <div className={(Styles.row, Styles.button)}>
                <input
                  type="submit"
                  value={Login_User_Loading ? "Loading..." : "Login"}
                />
              </div>
              {Login_User_Error ? (
                <p
                  ref={PasswordRef}
                  style={{ color: "red", textAlign: "center" }}
                >
                  {Login_User_Error}
                </p>
              ) : (
                ""
              )}
              <div className={Styles.signup_link}>
                Not a member ? <Link to="/signup">Signup now</Link>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* Top Footer Section*/}
      <TopFooter />
      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default Login;

import { Route, Routes } from "react-router-dom";
import Home from "../Screens/Home/Index";
import Package from "../Screens/Package/Index";
import MockTest from "../Screens/MockTest/Index";
import RecordedClass from "../Screens/RecordedClasses/Index";
import Dashboard from "../Screens/Dashboard";
import OnlineClass from "../Screens/OnlineClass/Index";
import MockTestDashboard from "../Screens/MockTestDashboard/Index";
import Login from "../Screens/Login/Index";
import SignUp from "../Screens/Signup/Index";
import PrivateRoute from "./PrivateRoute";
// import CourseMaterial from "../Screens/CourseMaterial/Index";
import AccessCourseMaterial from "../Screens/CourseMaterial/AccessCourseMaterial";
import TestPaper from "../Screens/TestPaper";
import PackageDetails from "../Screens/PackageDetails";
import LiveTest from "../Screens/LiveTest";
import TestAcknowledge from "../Screens/LiveTest/TestAcknowledge";
import TestSubmitted from "../Screens/LiveTest/TestSubmitted";
import LiveTestAttempted from "../Screens/LiveTestAttempted";
import PsychometricTest from "../Screens/PsychometricTest";
import PasswordForgot from "../Screens/FogotPassword/PasswordForgot";
import SetPassword from "../Screens/FogotPassword";
import { SitemapGenerator } from "../SitemapGenerator";
import Home2 from "../Screens/Home/index2";
import AboutUs from "../Screens/AboutUs";
import ContactUs from "../Screens/ContactUs";
import TermsAndCondition from "../Screens/TermsAndCondition";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import ErrorPage from "../Screens/ErrorPage";
import Cart from "../Screens/Cart";
import Order from "../Screens/Order";
import Purchases from "../Screens/Purchases";
import PracticeTest from "../Screens/PracticeTest";
import Material from "../Screens/Material";
import Attendance from "../Screens/Attendance";
// import OrderStatus from "../Screens/OrderStatus";
import LiveClass from "../Screens/LiveClasses";
import ErrorPage2 from "../Screens/ErrorPage2";
import Notification from "../Screens/Notification";
import OrderStatus from "../Screens/OrderStatus";
import LandingPage from "../Screens/LandingPage";
import StudentDetailsEdit from "../Screens/StudentEditDetails";
import LandingPage2 from "../Screens/LandingPage/LandingPage2";

export const Routes_React = (
  <Routes>
    <Route exact path="/pg1" element={<Home />} />
    <Route exact path="/" element={<Home2 />} />
    <Route exact path="/contact-us" element={<ContactUs />} />
    <Route exact path="/about-us" element={<AboutUs />} />
    <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route exact path="/terms-and-conditions" element={<TermsAndCondition />} />
    <Route path="/login" element={<Login />} />
    <Route path="/forgot/password" element={<PasswordForgot />} />
    <Route path="/set" element={<SetPassword />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/course" element={<Package />} />
    <Route path="/course/details/:id" element={<PackageDetails />} />
    <Route path="/test-papers" element={<TestPaper />} />
    <Route path="/mock-test" element={<MockTest />} />
    <Route path="/recorded-classes" element={<RecordedClass />} />
    <Route
      path="/dashboard"
      element={<PrivateRoute> {<Dashboard />} </PrivateRoute>}
    />

    {/* <Route
      path="/online-classes"
      element={<PrivateRoute>{<OnlineClass />} </PrivateRoute>}
    /> */}

    <Route
      path="/mock-test-dashboard"
      element={<PrivateRoute> {<MockTestDashboard />}</PrivateRoute>}
    />

    <Route
      path="/course-material"
      element={<PrivateRoute> {<Material />}</PrivateRoute>}
    />

    <Route
      path="/Attendance"
      element={<PrivateRoute> {<Attendance />}</PrivateRoute>}
    />
    <Route
      path="/course-material/access"
      element={<PrivateRoute> {<AccessCourseMaterial />}</PrivateRoute>}
    />
    <Route path="/T-LAT-acknowledge/:Id" element={<TestAcknowledge />} />
    <Route path="/T-LAT/:Id" element={<LiveTest />} />
    <Route path="/T-LAT-submitted" element={<TestSubmitted />} />
    <Route
      path="/Psychometric-Test"
      element={<PrivateRoute> {<PsychometricTest />}</PrivateRoute>}
    />

    <Route
      path="/T-LAT-answers"
      element={<PrivateRoute> {<LiveTestAttempted />}</PrivateRoute>}
    />

    <Route path="/cart" element={<Cart />} />

    <Route path="/Order/:OrderId" element={<Order />} />

    <Route
      path="/Purchases"
      element={<PrivateRoute> {<Purchases />}</PrivateRoute>}
    />

    <Route
      path="/Notification"
      element={<PrivateRoute> {<Notification />}</PrivateRoute>}
    />

    <Route
      path="/Academy"
      element={<PrivateRoute> {<PracticeTest />}</PrivateRoute>}
    />

    <Route
      path="/live-classes"
      element={<PrivateRoute> {<LiveClass />}</PrivateRoute>}
    />
    <Route path="/student/order/status/:id" element={<OrderStatus />} />
    <Route
      path="/student/details-update/:id"
      element={<StudentDetailsEdit />}
    />

    <Route path="/error" element={<ErrorPage />} />
    <Route path="/sitemap" element={<SitemapGenerator />} />

    <Route path="/ielts-pte-reality-test" element={<LandingPage />} />
    <Route path="/testifyi-ielts-webinar" element={<LandingPage2 />} />
    <Route path="/error" element={<ErrorPage />} />
    <Route path="/*" element={<ErrorPage2 />} />
  </Routes>
);

export default function NavigationRoute() {
  return <>{Routes_React}</>;
}

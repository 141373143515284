import {
  CONTACT_BOOKING_FAILED,
  CONTACT_BOOKING_REQ,
  CONTACT_BOOKING_RESET,
  CONTACT_BOOKING_SUCCESS,
  TEST_BOOKING_FAILED,
  TEST_BOOKING_REQ,
  TEST_BOOKING_RESET,
  TEST_BOOKING_SUCCESS,
} from "../Constant/ContactConstant";

export const Contact_Us_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_BOOKING_REQ: {
      return { loading: true };
    }
    case CONTACT_BOOKING_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CONTACT_BOOKING_FAILED: {
      return { loading: false, error: payload };
    }

    case CONTACT_BOOKING_RESET: {
      return { loading: false, success: "", error: "" };
    }
    default:
      return state;
  }
};

export const Test_Book_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TEST_BOOKING_REQ: {
      return { loading: true };
    }

    case TEST_BOOKING_SUCCESS: {
      return { loading: false, success: payload };
    }

    case TEST_BOOKING_FAILED: {
      return { loading: false, error: payload };
    }

    case TEST_BOOKING_RESET: {
      return { loading: false, error: "", payload: "" };
    }
    default:
      return state;
  }
};

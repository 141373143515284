import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import Styles from "./StudentEditDetails.module.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loading";
import Enroll from "../Home/Enroll";
import ReactHelmet from "../../Components/ReactHelmet";
import {
  USER_UPDATE_DETAILS_FAILED,
  USER_UPDATE_DETAILS_RESET,
} from "../../Constant/UserConstant";

import { Package_List } from "../../Action/PacakgeAction";
import { ListOrders } from "../../Action/OrderPackageAction";
import { Student_Validate_Json } from "../../Model/UserModel";
import { UPDATE_USER_DET } from "../../Action/UserAction";
// import { FaCamera } from "react-icons/fa";

export default function StudentDetailsEdit() {
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const [OpenMenuBar2, setOpenMenuBar2] = useState(false);

  const [ProfileDetails, setProfileDetails] = useState({
    First_Name: "",
    Last_Name: "",
    EmailAddress: "",
    PhoneNo: "",
  });
  //   const [ProfilePic, setProfilePic] = useState("");

  const Mobile_Screen = window.innerWidth;
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);
  const [EnrollActive, setEnrollActive] = useState(false);

  const { loading, success, error } = useSelector(
    (s) => s.UPDATE_USER_DET_REDUCER
  );

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setProfileDetails({ ...ProfileDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (Login_User) {
      setProfileDetails({ ...Login_User });
    }
  }, [Login_User]);

  const handleUpdate = () => {
    const Validate = Student_Validate_Json({ ...ProfileDetails });

    if (Validate === "success") {
      dispatch(UPDATE_USER_DET({ ...ProfileDetails }));
    } else {
      dispatch({ type: USER_UPDATE_DETAILS_FAILED, payload: Validate });
    }
  };

  const handleCancel = () => {
    dispatch({ type: USER_UPDATE_DETAILS_RESET });
  };

  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 }, "MainPackages"));
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        handleCancel();
      }, 5000);
    }
  }, [success, dispatch]);

  useEffect(() => {
    dispatch(ListOrders());
  }, [dispatch]);

  return (
    <div className={Styles.Dashboard}>
      <ReactHelmet title={"Dashboard"} description={"Student Dashboard"} />
      {/* SidePanel */}
      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          SetSideBarOpen2={setOpenMenuBar2}
          SidebarOpen2={OpenMenuBar2}
          Page="Profile"
          active={true}
        />
      ) : (
        ""
      )}

      {loading ? <Loading /> : ""}

      {EnrollActive ? (
        <Enroll setEnrollActive={setEnrollActive} Package_Id={EnrollActive} />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Profile"
          active={true}
        />
      ) : (
        ""
      )}

      {/* Middle Section */}

      {!SidebarOpen && !OpenMenuBar2 ? (
        <section className={Styles.MiddleSection}>
          {/* <div style={{ marginTop: "30px" }}>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              SetSideBarOpen2={setOpenMenuBar2}
              SidebarOpen2={OpenMenuBar2}
              PageName={"Profile Details"}
            />
          </div> */}

          <div className={Styles.StudentUpdate}>
            <div>
              <div className={Styles.Main_Container}>
                <div>
                  <img
                    src="/assets/image/Profile-Background.jpg"
                    alt="background banner"
                  />
                </div>
                <div className={Styles.ProfileDet}>
                  <div>
                    <img src="/assets/image/user.svg" alt="profile" />
                    {/* <div className={Styles.Upload}>
                      <input type="file" />
                      <FaCamera />
                    </div> */}
                  </div>

                  <h4>
                    {Login_User?.First_Name
                      ? Login_User?.First_Name + " " + Login_User?.Last_Name
                      : "New User"}
                  </h4>
                  <p>{Login_User?.EmailAddress}</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h4>Personal Information :- </h4>
              </div>
              <div className={Styles.StudentRow}>
                <div>
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="First_Name"
                    value={ProfileDetails?.First_Name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="Last_Name"
                    value={ProfileDetails?.Last_Name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={Styles.StudentRow}>
                <div>
                  <label>Phone No</label>
                  <input
                    type="number"
                    placeholder="Phone No"
                    name="PhoneNo"
                    value={ProfileDetails?.PhoneNo}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Email Address</label>
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="EmailAddress"
                    value={ProfileDetails?.EmailAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={Styles.ErrorSuccessMsg}>
                {error ? (
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                ) : success ? (
                  <p style={{ color: "green", textAlign: "center" }}>
                    {success}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className={Styles.Submit}>
                <button
                  className="custom_btn custom_btn1"
                  onClick={() => handleUpdate()}
                >
                  Update
                </button>
                <button
                  className="cancel_btn cancel_btn1"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* Middle Section */}
      {Mobile_Screen <= 480 ? (
        <RightSidePanel
          SetSideBarOpen={setOpenMenuBar2}
          SidebarOpen={OpenMenuBar2}
        />
      ) : (
        <RightSidePanel
          SetSideBarOpen={setOpenMenuBar2}
          SidebarOpen={OpenMenuBar2}
        />
      )}
      {/* Right Section  */}
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import Styles from "./Navbar.module.css";
import { FiUser } from "react-icons/fi";
import { RxDashboard, RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";

import { PiShoppingCartLight } from "react-icons/pi";
import { BiLogOut } from "react-icons/bi";
import { LOGOUT } from "../Action/UserAction";

export default function Navbar2({ Page, setQuestionSec }) {
  const [HideMenu, setHideMenu] = useState(false);
  const [ActiveDashboard, setActiveDashboard] = useState(false);
  const { Login_User_Loading, Login_User, Login_User_Error } = useSelector(
    (state) => state.USER_LOGIN_REDUCER
  );
  const ref = useRef();
  const Mobile_Screen = window.innerWidth;
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const handleMenu = (e) => {
    e.preventDefault();
    setHideMenu(!HideMenu);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setActiveDashboard(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  return (
    <navbar>
      <div className={Styles.Navbar}>
        <div>
          <img
            onClick={() => Navigate("/")}
            src="/assets/image/Logos/Testify_White_Logo.svg"
            alt="logo"
          />
        </div>
        <Drawer
          style={{ zIndex: "99990" }}
          open={HideMenu}
          onClose={handleMenu}
          direction="right"
          className="bla bla bla"
        >
          <div className={Styles.Navbar_Section3}>
            <div>
              <p
                onClick={(e) => {
                  if (Page === "Home") {
                    setQuestionSec(true);
                    handleMenu(e);
                  } else {
                    Navigate("/");
                  }
                }}
              >
                Find A Course
              </p>
            </div>
            <div>
              <p
                onClick={() => {
                  Navigate("/Course");
                }}
                style={
                  Page === "Course"
                    ? {
                        backgroundColor: "#0b3eff",
                        color: "white",
                      }
                    : { textDecoration: "none" }
                }
              >
                All Courses
              </p>
            </div>
            <div>
              <p
                onClick={() => {
                  Navigate("/about-us");
                }}
                style={
                  Page === "About Us"
                    ? {
                        textDecoration: "none",
                        backgroundColor: "#0b3eff",
                        color: "white",
                      }
                    : { textDecoration: "none" }
                }
              >
                About Us
              </p>
            </div>
            <div>
              <p
                onClick={() => {
                  Navigate("/contact-us");
                }}
                style={
                  Page === "Contact Us"
                    ? {
                        textDecoration: "none",
                        backgroundColor: "#0b3eff",
                        color: "white",
                      }
                    : { textDecoration: "none" }
                }
              >
                Contact Us
              </p>
            </div>
            {Login_User ? (
              <div ref={ref} className={Styles.Coordinator}>
                <div
                  className={Styles.Coordinator_top}
                  onClick={() => setActiveDashboard(!ActiveDashboard)}
                >
                  <div>
                    <h5 style={{ color: "white" }}>
                      {Login_User?.First_Name
                        ? Login_User?.First_Name +
                          " " +
                          (Login_User?.Last_Name ? Login_User?.Last_Name : "")
                        : ""}
                    </h5>
                  </div>
                  <div>
                    <img src="/assets/image/user.svg" alt="profile" />
                  </div>
                </div>
                <div
                  className={
                    ActiveDashboard
                      ? `${Styles.Navbar_DropDown} ${Styles.active}`
                      : Styles.Navbar_DropDown
                  }
                >
                  <p onClick={(e) => Navigate("/dashboard")}>
                    <span>
                      {" "}
                      <RxDashboard />{" "}
                    </span>
                    <span>Dashboard</span>
                  </p>
                  <p onClick={(e) => dispatch(LOGOUT())}>
                    <span>
                      {" "}
                      <BiLogOut />
                    </span>
                    <span>Logout</span>
                  </p>
                </div>
              </div>
            ) : (
              <div className={Styles.Login}>
                <div
                  onClick={(e) => {
                    e.preventDefault();

                    Navigate("/login");
                  }}
                  style={
                    Page === "Login"
                      ? { backgroundColor: "#0b3eff", color: "white" }
                      : { background: "none" }
                  }
                >
                  <span style={{ fontSize: "15px" }}>
                    <FiUser />
                  </span>
                  <span> Login </span>
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();

                    Navigate("/signup");
                  }}
                  style={
                    Page === "SignUp"
                      ? { backgroundColor: "#0b3eff", color: "white" }
                      : { background: "none" }
                  }
                >
                  Sign up
                </div>
              </div>
            )}
            <div
              onClick={() => Navigate("/cart")}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: "0",
                cursor: "pointer",
              }}
            >
              <span
                style={{ color: "white", marginBottom: "-5px", padding: "0" }}
              >
                <PiShoppingCartLight />
              </span>
              <span>Cart</span>
            </div>
          </div>
        </Drawer>
        {Mobile_Screen >= 780 ? (
          <div className={Styles.Navbar_Section2}>
            <div>
              <a
                onClick={() => {
                  if (Page === "Home") {
                    setQuestionSec(true);
                  } else {
                    Navigate("/");
                  }
                }}
                style={{ textDecoration: "none" }}
              >
                <p>Find A Course</p>
              </a>
            </div>
            <div>
              <Link style={{ textDecoration: "none" }} to="/Course">
                <p>Our Courses</p>
              </Link>
            </div>
            <div>
              <Link style={{ textDecoration: "none" }} to="/about-us">
                <p>About Us</p>
              </Link>
            </div>
            <div>
              <Link style={{ textDecoration: "none" }} to="/contact-us">
                <p>Contact Us</p>
              </Link>
            </div>
            {Login_User ? (
              <div ref={ref} className={Styles.Coordinator}>
                <div
                  onClick={() => setActiveDashboard(!ActiveDashboard)}
                  className={Styles.Coordinator_top}
                >
                  <div>
                    <h5>
                      {Login_User?.First_Name
                        ? Login_User?.First_Name +
                          " " +
                          (Login_User?.Last_Name ? Login_User?.Last_Name : "")
                        : ""}
                    </h5>
                  </div>
                  <div>
                    <img src="/assets/image/user.svg" alt="profile" />
                  </div>
                </div>
                <div
                  className={
                    ActiveDashboard
                      ? `${Styles.Navbar_DropDown} ${Styles.active}`
                      : Styles.Navbar_DropDown
                  }
                >
                  <p onClick={(e) => Navigate("/dashboard")}>
                    <span>
                      {" "}
                      <RxDashboard />{" "}
                    </span>
                    <span>Dashboard</span>
                  </p>
                  <p onClick={(e) => dispatch(LOGOUT())}>
                    <span>
                      {" "}
                      <BiLogOut />
                    </span>
                    <span>Logout</span>
                  </p>
                </div>
              </div>
            ) : (
              <div className={Styles.Login_Signup}>
                <div onClick={() => Navigate("/login")}>
                  <span style={{ fontSize: "15px" }}>
                    <FiUser />
                  </span>
                  <span> Login </span>
                </div>
                <div
                  onClick={() => Navigate("/signup")}
                  style={{ backgroundColor: "#333333", color: "white" }}
                >
                  Sign up
                </div>
              </div>
            )}
            <div
              onClick={() => Navigate("/cart")}
              style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "0",
                cursor: "pointer",
              }}
            >
              <span
                style={{ color: "#333333", marginBottom: "-5px", padding: "0" }}
              >
                <PiShoppingCartLight />
              </span>
              <span>Cart</span>
            </div>
          </div>
        ) : (
          <div>
            <span onClick={handleMenu}>
              <RxHamburgerMenu />
            </span>
          </div>
        )}
      </div>
    </navbar>
  );
}

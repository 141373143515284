import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import { mockTestDetails } from "../../Action/mockTestAction";

export default function RightMenu({
  Styles,
  OpenMenuBar,
  index,
  OpenBarClick,
  SetSideBarOpen,
  SidebarOpen,
  Details,
}) {
  const MobileScreen = window.innerWidth;
  const dispatch = useDispatch();
  return (
    <>
      <div className={Styles.MainContainer2}>
        {MobileScreen <= 780 ? (
          <h2
            onClick={() => SetSideBarOpen(!SidebarOpen)}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "0px",
              right: "20px",
            }}
          >
            <IoIosCloseCircleOutline />
          </h2>
        ) : (
          ""
        )}
        <div
          onClick={() => {
            dispatch(mockTestDetails(Details?._id));
            OpenBarClick(index);
          }}
          className={
            OpenMenuBar === index
              ? `${Styles.RightSideMaterialMenu} ${Styles.active}`
              : Styles.RightSideMaterialMenu
          }
        >
          {/* {OpenMenuBar === index ? (
            <h5>
              <IoIosArrowDown />
            </h5>
          ) : (
            <IoIosArrowForward />
          )} */}
          <h5>{Details?.MockNo}</h5>
        </div>
      </div>
    </>
  );
}

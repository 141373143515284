import {
  CART_DETAILS_LIST_FAILED,
  CART_DETAILS_LIST_REQ,
  CART_DETAILS_LIST_RESET,
  CART_DETAILS_LIST_SUCCESS,
  COUPON_APPLY_FAILED,
  COUPON_APPLY_REQ,
  COUPON_APPLY_RESET,
  COUPON_APPLY_SUCCESS,
  ORDER_PACKAGE_FAILED,
  ORDER_PACKAGE_REQ,
  ORDER_PACKAGE_RESET,
  ORDER_PACKAGE_SUCCESS,
  ORDERS_LIST_FAILED,
  ORDERS_LIST_REQ,
  ORDERS_LIST_RESET,
  ORDERS_LIST_SUCCESS,
  ORDERS_STATUS_FAILED,
  ORDERS_STATUS_REQ,
  ORDERS_STATUS_RESET,
  ORDERS_STATUS_SUCCESS,
  PACKAGE_ADD_CART_FAILED,
  PACKAGE_ADD_CART_REQ,
  PACKAGE_ADD_CART_RESET,
  PACKAGE_ADD_CART_SUCCESS,
  PACKAGE_REMOVE_CART_FAILED,
  PACKAGE_REMOVE_CART_REQ,
  PACKAGE_REMOVE_CART_RESET,
  PACKAGE_REMOVE_CART_SUCCESS,
} from "../Constant/OrderPackageConstant";

export const PackageOrderReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDER_PACKAGE_REQ: {
      return { loading: true };
    }

    case ORDER_PACKAGE_SUCCESS: {
      return { loading: false, OrderDet: payload };
    }

    case ORDER_PACKAGE_FAILED: {
      return { loading: false, error: payload };
    }

    case ORDER_PACKAGE_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const AddToCartReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PACKAGE_ADD_CART_REQ: {
      return { loading: true };
    }
    case PACKAGE_ADD_CART_SUCCESS: {
      return { loading: false, success: payload };
    }

    case PACKAGE_ADD_CART_FAILED: {
      return { loading: false, error: payload };
    }

    case PACKAGE_ADD_CART_RESET: {
      return { loading: false, error: "" };
    }
    default:
      return state;
  }
};

export const DeleteToCartReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PACKAGE_REMOVE_CART_REQ: {
      return { loading: true };
    }

    case PACKAGE_REMOVE_CART_SUCCESS: {
      return { loading: false, success: payload };
    }

    case PACKAGE_REMOVE_CART_FAILED: {
      return { loading: false, error: payload };
    }

    case PACKAGE_REMOVE_CART_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const ListCartReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CART_DETAILS_LIST_REQ: {
      return { loading: false };
    }

    case CART_DETAILS_LIST_SUCCESS: {
      return { loading: false, CartDetails: payload };
    }

    case CART_DETAILS_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case CART_DETAILS_LIST_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};

export const ListOrdersReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDERS_LIST_REQ: {
      return { loading: true };
    }

    case ORDERS_LIST_SUCCESS: {
      return { loading: false, OrdersRec: payload };
    }

    case ORDERS_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case ORDERS_LIST_RESET: {
      return { loading: false, error: "" };
    }

    default:
      return state;
  }
};

export const orderStatusReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDERS_STATUS_REQ: {
      return { loading: true };
    }

    case ORDERS_STATUS_SUCCESS: {
      return { loading: false, OrderDet: payload };
    }

    case ORDERS_STATUS_FAILED: {
      return { loading: false, error: payload };
    }

    case ORDERS_STATUS_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const ApplyCouponReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COUPON_APPLY_REQ: {
      return { loading: true };
    }
    case COUPON_APPLY_SUCCESS: {
      return { loading: false, success: payload };
    }

    case COUPON_APPLY_FAILED: {
      return { loading: false, error: payload };
    }

    case COUPON_APPLY_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};

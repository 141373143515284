import React, { useEffect, useRef, useState } from "react";
import TopFooter from "../../Components/TopFooterSection";
import Footer from "../../Components/Footer";
import Styles from "./Signup.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Signup_Form_Model } from "../../Model/UserModel";
import { useDispatch, useSelector } from "react-redux";
//import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RESET_SIGNUP_USER, USER_SIGNUP } from "../../Action/UserAction";
import {
  loadCaptchaEnginge,
  // LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { USER_SIGNUP_FAILED } from "../../Constant/UserConstant";
import Loading from "../../Components/Loading";
import Navbar2 from "../../Components/Navbar2";
import { Take_Test_Det } from "../../Action/TLastAction";
import { AddToCart } from "../../Action/OrderPackageAction";
import { PACKAGE_ADD_CART_RESET } from "../../Constant/OrderPackageConstant";
import { TAKE_A_TEST_RESET } from "../../Constant/TLatConstant";
import ReactHelmet from "../../Components/ReactHelmet";

const SignUp = () => {
  const [SignupForm, setSignupForm] = useState(Signup_Form_Model);
  const [SignupAttempt, setSignupAttempt] = useState(0);

  const { Signup_User_Loading, Signup_User_Success, Signup_User_Error } =
    useSelector((state) => state.USER_SIGNUP_REDUCER);

  const { loading, Test_Dets, error } = useSelector(
    (s) => s.Take_Test_Det_Reducer
  );

  const {
    loading: loadingCart,
    success: successCart,
    error: errorCart,
  } = useSelector((s) => s.AddToCartReducer);

  const AttemptSignup = localStorage.getItem("SignUpAttempt")
    ? JSON.parse(localStorage.getItem("SignUpAttempt"))
    : 0;

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const PasswordRef = useRef();
  const { state } = useLocation();
  // const recaptchaRef = useRef();
  const handleChange = (e) => {
    setSignupForm({ ...SignupForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const Validator = Signup_Validator(SignupForm);
    if (SignupAttempt && SignupAttempt >= 4) {
      let user_captcha = document.getElementById("user_captcha_input").value;
      if (validateCaptcha(user_captcha) === true) {
        dispatch(USER_SIGNUP(SignupForm));
        // loadCaptchaEnginge(6);
        // document.getElementById("user_captcha_input").value = "";
      } else {
        dispatch({
          type: USER_SIGNUP_FAILED,
          payload: "Captcha doesn't matched !",
        });
      }
    } else {
      dispatch(USER_SIGNUP(SignupForm));
    }

    setTimeout(() => {
      PasswordRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 500);
  };

  const handleTestSubmit = (e) => {
    e.preventDefault();
    // const Validator = Signup_Validator(SignupForm);
    // if (SignupAttempt && SignupAttempt >= 4) {
    //   let user_captcha = document.getElementById("user_captcha_input").value;
    //   if (validateCaptcha(user_captcha) === true) {
    //     dispatch(USER_SIGNUP(SignupForm));
    //   } else {
    //     dispatch({
    //       type: USER_SIGNUP_FAILED,
    //       payload: "Captcha doesn't matched !",
    //     });
    //   }
    // } else {
    dispatch(USER_SIGNUP(SignupForm));
    // if (state?.TLAT === "Test_Dets") {
    //   dispatch(Take_Test_Det(SignupForm));
    // } else if (state?.PackageId) {
    //   localStorage.setItem(
    //     "Cart_Package_Id",
    //     JSON.stringify(state?.PackageId)
    //   );

    //   localStorage.setItem(
    //     "Package_Timing",
    //     JSON.stringify({
    //       Timings: state?.Timings,
    //       CustomTimings: state?.CustomTimings,
    //       EmailAddress: SignupForm?.EmailAddress,
    //     })
    //   );
    // }
    // }

    setTimeout(() => {
      PasswordRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 500);
  };

  useEffect(() => {
    if (Signup_User_Success) {
      if (state?.TLAT === "Take_Test") {
        dispatch(Take_Test_Det(SignupForm));
      } else if (state?.PackageId) {
        localStorage.setItem(
          "Cart_Package_Id",
          JSON.stringify(state?.PackageId)
        );

        localStorage.setItem(
          "Package_Timing",
          JSON.stringify({
            Timings: state?.Timings,
            CustomTimings: state?.CustomTimings,
            EmailAddress: SignupForm?.EmailAddress,
          })
        );
      }

      if (state?.PackageId) {
        dispatch(
          AddToCart(
            {
              packageId: state?.PackageId,
              EmailAddress: SignupForm?.EmailAddress,
              Timings: state?.Timings,
              CustomTimings: state?.CustomTimings,
              ClassMode: state?.ClassMode,
            },
            "student/cart/withEmail"
          )
        );
      }
      setTimeout(() => {
        setSignupForm(Signup_Form_Model);
        dispatch(RESET_SIGNUP_USER());
        if (!state) {
          Navigate("/login");
        }
      }, 4500);
    }

    if (Signup_User_Error === "Error - You are already registered!") {
      if (state?.TLAT === "Take_Test") {
        dispatch(Take_Test_Det(SignupForm));
      } else if (state?.PackageId) {
        localStorage.setItem(
          "Cart_Package_Id",
          JSON.stringify(state?.PackageId)
        );

        localStorage.setItem(
          "Package_Timing",
          JSON.stringify({
            Timings: state?.Timings,
            CustomTimings: state?.CustomTimings,
            EmailAddress: SignupForm?.EmailAddress,
          })
        );
      }
      if (state?.PackageId) {
        dispatch(
          AddToCart(
            {
              packageId: state?.PackageId,
              EmailAddress: SignupForm?.EmailAddress,
              Timings: state?.Timings,
              CustomTimings: state?.CustomTimings,
              ClassMode: state?.ClassMode,
            },
            "student/cart/withEmail"
          )
        );
      }
    }
  }, [Signup_User_Success, Navigate, dispatch, Signup_User_Error]);

  useEffect(() => {
    if (state?.PackageId && successCart) {
      dispatch({ type: PACKAGE_ADD_CART_RESET });
      Navigate(`/cart`);
    }
  }, [successCart, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (SignupAttempt && SignupAttempt >= 4) {
      loadCaptchaEnginge(8, "grey", "white", "symbols");
      let canvas = document.getElementById("canv");
      let ctx = canvas.getContext("2d");
      ctx.letterSpacing = "0.1px";
      ctx.textDecoration = "line-through";
    }
  }, [SignupAttempt]);

  useEffect(() => {
    if (state?.TLAT == "Take_Test" && Test_Dets) {
      Navigate(`/T-LAT-acknowledge/${Test_Dets}`);
      dispatch({ type: TAKE_A_TEST_RESET });
    }

    if (error) {
      Navigate("/error", { state: { error } });
    }
  }, [Test_Dets, error]);

  useEffect(() => {
    setSignupAttempt(AttemptSignup);
  }, [AttemptSignup]);

  return (
    <>
      <ReactHelmet
        title={"Sign Up"}
        description={"Signup for Access Testifyi."}
      />
      {/* navbar Section */}
      <Navbar2 Page={"SignUp"} />
      {/* Fixed Menu Section */}
      {Signup_User_Loading || loadingCart ? <Loading /> : ""}
      <section className={Styles.Login}>
        <div className={Styles.container}>
          <div className={Styles.wrapper}>
            <div className={Styles.title}>
              <h1 className="Font_Rajdhani">Sign Up </h1>
              <p className="Font_Rajdhani_normal">Access to our dashboard</p>
            </div>
            <form onSubmit={state ? handleTestSubmit : handleSubmit}>
              <div className={Styles.row}>
                <input
                  type="text"
                  name="First_Name"
                  value={SignupForm.First_Name}
                  onChange={handleChange}
                  placeholder="First Name"
                  required
                />
              </div>

              <div className={Styles.row}>
                <input
                  type="text"
                  name="Last_Name"
                  value={SignupForm.Last_Name}
                  onChange={handleChange}
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className={Styles.row}>
                <input
                  type="email"
                  name="EmailAddress"
                  value={SignupForm.EmailAddress}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                />
              </div>
              <div className={Styles.row}>
                <input
                  type="number"
                  name="PhoneNo"
                  value={SignupForm.PhoneNo}
                  onChange={handleChange}
                  placeholder="Phone No"
                  required
                />
              </div>

              {/* <div className={Styles.row}>
                <input
                  type={PasswordVisible ? "text" : "password"}
                  name="Password"
                  value={SignupForm.Password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
                {PasswordVisible ? (
                  <span
                    className={Styles.VisiblePass}
                    onClick={() => setPasswordVisible(!PasswordVisible)}
                  >
                    <AiOutlineEye />
                  </span>
                ) : (
                  <span
                    className={Styles.VisiblePass}
                    onClick={() => setPasswordVisible(!PasswordVisible)}
                  >
                    <AiOutlineEyeInvisible />
                  </span>
                )}
              </div> */}

              {/* <ReCAPTCHA
                ref={recaptchaRef}
                type="image"
                onChange={(e) => console.log(e)}
                sitekey="6LeMolEqAAAAAJDlrVoIWPWrl6haR6hnu5dJ9rBG"
              /> */}

              {/* {SignupAttempt && SignupAttempt >= 4 ? (
                <div className={Styles.Captach}>
                  <div className="col mt-3 ">
                    <LoadCanvasTemplate reloadText={"↻"} reloWdColor="black" />
                  </div>
                  <div className={Styles.row}>
                    <input
                      placeholder="Enter Captcha Value"
                      id="user_captcha_input"
                      name="user_captcha_input"
                      type="text"
                      required
                    />
                  </div>
                </div>
              ) : (
                ""
              )} */}
              <div className={(Styles.row, Styles.button)}>
                <input
                  type="submit"
                  disabled={Signup_User_Loading || loading}
                  value={
                    Signup_User_Loading || loading ? "loading..." : "Signup"
                  }
                />
              </div>
              {Signup_User_Error || errorCart ? (
                <p
                  ref={PasswordRef}
                  style={{ color: "red", textAlign: "center" }}
                >
                  {Signup_User_Error || errorCart}
                </p>
              ) : (
                ""
              )}

              {Signup_User_Success ? (
                <p
                  ref={PasswordRef}
                  style={{ color: "green", textAlign: "center" }}
                >
                  {Signup_User_Success}
                </p>
              ) : (
                ""
              )}
              <div className={Styles.signup_link}>
                already a member ? <Link to="/login">Login</Link>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* Banner Section */}
      {/* Top Footer Section*/}
      <TopFooter />
      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default SignUp;

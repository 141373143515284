import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Components/Footer";
import Navbar2 from "../../Components/Navbar2";
import { useCallback, useEffect, useState } from "react";
import { packageDetails } from "../../Action/PacakgeAction";
import Loading from "../../Components/Loading";
import { AiFillSafetyCertificate } from "react-icons/ai";
import {
  ApplyCoupon,
  DeleteToCart,
  ListCarts,
  PackageOrder,
} from "../../Action/OrderPackageAction";
import { useNavigate } from "react-router-dom";
import {
  COUPON_APPLY_RESET,
  ORDER_PACKAGE_FAILED,
  ORDER_PACKAGE_RESET,
  PACKAGE_REMOVE_CART_RESET,
} from "../../Constant/OrderPackageConstant";
import TermsAndCondition from "./T&C";
import { PACKAGE_DETAILS_RESET } from "../../Constant/packageConstant";
import ReactHelmet from "../../Components/ReactHelmet";

const OrderModel = {
  amount: "",
  CouponUsed: "",
  ActualCost: "",
  AmountDiscounted: "",
};

export default function Cart() {
  const { loading, DetailsPack, error } = useSelector(
    (s) => s.packageDetailsReducer
  );
  const [Coupon, setCoupon] = useState("");
  const [TermsVisible, setTermsVisible] = useState(false);
  const [AcceptTerms, setAcceptTerms] = useState(false);
  const [OrderData, setOrderData] = useState(OrderModel);
  const { CartDetails, error: errorCartDetails } = useSelector(
    (s) => s.ListCartReducer
  );

  const {
    loading: loadingCapply,
    error: errorCapply,
    success: successCapply,
  } = useSelector((s) => s.ApplyCouponReducer);

  const {
    loading: loadingOrder,
    error: errorOrder,
    OrderDet,
  } = useSelector((s) => s.PackageOrderReducer);
  const Package_Timing = JSON.parse(localStorage.getItem("Package_Timing"));
  const {
    loading: laodingRmv,
    success: successRmv,
    error: errorRmv,
  } = useSelector((s) => s.DeleteToCartReducer);

  const Navigate = useNavigate();
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);

  const PackageDet = JSON.parse(localStorage.getItem("Cart_Package_Id"));

  const dispatch = useDispatch();
  const handleRemove = (Id) => {
    dispatch(DeleteToCart(Id));
  };

  const Get_Package_Det = useCallback(() => {
    if (Login_User) {
      if (CartDetails?.length) {
        dispatch(packageDetails(CartDetails[0]?.PackageId));
      }
    } else {
      if (PackageDet) {
        dispatch(packageDetails(PackageDet));
      }

      // else {
      //   Navigate("/course");
      // }
    }
  }, [dispatch, PackageDet, CartDetails]);

  const handleCouponApply = () => {
    if (Coupon) {
      dispatch({
        type: ORDER_PACKAGE_RESET,
      });
      dispatch(ApplyCoupon(Coupon));
    }
  };

  const handlePlaceOder = () => {
    if (AcceptTerms) {
      if (Login_User) {
        dispatch(
          PackageOrder({
            amount: OrderData?.amount,
            PackageId: DetailsPack?.PackageId?._id,
            CouponUsed: OrderData?.CouponUsed,
          })
        );
      } else {
        dispatch(
          PackageOrder(
            {
              amount: OrderData?.amount,
              PackageId: DetailsPack?.PackageId?._id,
              CouponUsed: OrderData?.CouponUsed,
            },
            Package_Timing?.EmailAddress
          )
        );
      }
    } else {
      dispatch({
        type: ORDER_PACKAGE_FAILED,
        payload: "Please Accept the T&C !",
      });
    }
  };

  useEffect(() => {
    Get_Package_Det();
  }, [Get_Package_Det, CartDetails]);

  useEffect(() => {
    if (Login_User) {
      dispatch(ListCarts());
    }
  }, [dispatch]);

  useEffect(() => {
    // if (Login_User && errorCartDetails) {
    //   Navigate("/Course");
    // }
  }, [errorCartDetails]);

  useEffect(() => {
    if (successRmv) {
      localStorage.removeItem("Package_Timing");
      localStorage.removeItem("Cart_Package_Id");
      setOrderData(OrderModel);
      dispatch(ListCarts());
      dispatch({ type: COUPON_APPLY_RESET });
      dispatch({ type: PACKAGE_DETAILS_RESET });
      dispatch({ type: PACKAGE_REMOVE_CART_RESET });
    }
  }, [successRmv, dispatch]);

  useEffect(() => {
    if (DetailsPack) {
      if (DetailsPack?.PackageId?.DiscountedPrice) {
        let DiscountPrice =
          +DetailsPack?.PackageId?.ActualCost -
          +DetailsPack?.PackageId?.DiscountedPrice;
        setOrderData({
          ...OrderData,
          amount: DetailsPack?.PackageId?.DiscountedPrice,
          AmountDiscounted: DiscountPrice.toFixed(2),
        });
      } else {
        setOrderData({
          ...OrderData,
          amount: DetailsPack?.PackageId?.ActualCost,
          AmountDiscounted: 0,
        });
      }
    }
  }, [DetailsPack]);

  useEffect(() => {
    if (successCapply) {
      let DiscountPrice =
        (+DetailsPack?.PackageId?.ActualCost * +successCapply?.Percentage) /
        100;
      let FinalPrice = DetailsPack?.PackageId?.ActualCost - DiscountPrice;
      if (FinalPrice && FinalPrice < 0) {
        FinalPrice = 0;
      }
      setOrderData({
        ...OrderData,
        amount: FinalPrice.toFixed(2),
        AmountDiscounted: DiscountPrice.toFixed(2),
        CouponUsed: Coupon,
      });
    } else {
      if (DetailsPack?.PackageId?.DiscountedPrice) {
        let DiscountPrice =
          +DetailsPack?.PackageId?.ActualCost -
          +DetailsPack?.PackageId?.DiscountedPrice;
        setOrderData({
          ...OrderData,
          amount: DetailsPack?.PackageId?.DiscountedPrice,
          AmountDiscounted: DiscountPrice.toFixed(2),
        });
      } else {
        setOrderData({
          ...OrderData,
          amount: DetailsPack?.PackageId?.ActualCost,
          AmountDiscounted: 0,
        });
      }
    }
  }, [successCapply]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (OrderDet) {
      window.location.href = OrderDet;
    }
  }, [OrderDet]);

  return (
    <>
       <ReactHelmet title={"Cart"} description={"Product Cart"} />
      <Navbar2 />
      {loading || laodingRmv || loadingOrder || loadingCapply ? (
        <Loading />
      ) : (
        ""
      )}

      {TermsVisible ? (
        <TermsAndCondition
          setTermsVisible={setTermsVisible}
          setAcceptTerms={setAcceptTerms}
        />
      ) : (
        ""
      )}
      <section className="CartMargin">
        <div className="Cart_section">
          <div className="CartSection1">
            <div>
              <h4> CART DETAILS </h4>
            </div>
            {DetailsPack ? (
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>{DetailsPack?.PackageId?.Name}</h4>
                  {Login_User ? (
                    <div>
                      <button
                        onClick={() => handleRemove(CartDetails[0]?._id)}
                        className="rmv_btn"
                      >
                        REMOVE
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <p
                  style={{
                    fontSize: "14px",
                    margin: "0",
                    padding: "2px 0 4px 0",
                  }}
                >
                  {DetailsPack?.PackageId?.Detail}
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    margin: "0",
                    padding: "2px 0",
                    fontWeight: "600",
                  }}
                >
                  Duration: {DetailsPack?.PackageId?.Duration} Weeks
                </p>
                <hr />
                <div>
                  <p>
                    <strong>This package Includes : </strong>
                  </p>
                  <div className={"SpeakingWriting"}>
                    {DetailsPack?.LIVE_CLASSES ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_LIVE_CLASSES.svg"
                            alt="icon_LIVE_CLASSES"
                          />
                        </span>
                        <span>{DetailsPack?.LIVE_CLASSES} LIVE CLASSES </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.One_On_One_DOUBT_SESSIONS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                            alt="icon_LIVE_CLASSES"
                          />
                        </span>
                        <span>1:1 DOUBT SESSIONS </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.DOUBT_SESSIONS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                            alt="icon_LIVE_CLASSES"
                          />
                        </span>
                        <span> DOUBT SESSIONS</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.SESSION_TESTS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_SESSION_TESTS.svg"
                            alt="icon_SESSION_TESTS"
                          />
                        </span>
                        <span>SESSION TESTS </span>
                      </p>
                    ) : (
                      ""
                    )}
                    {DetailsPack?.STUDY_MATERIAL_ACCESS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_STUDY_MATERIAL_ACCESS.svg"
                            alt="icon_STUDY_MATERIAL_ACCESS"
                          />
                        </span>
                        <span> STUDY MATERIAL ACCESS</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.COMPLETE_MOCK_TEST ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_MOCK_TEST.svg"
                            alt="icon_MOCK_TEST"
                          />
                        </span>
                        <span>
                          {DetailsPack?.COMPLETE_MOCK_TEST} COMPLETE MOCK TEST
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.COMPLETE_MOCK_TEST_AI ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_MOCK_TEST_AI.svg"
                            alt="icon_MOCK_TEST"
                          />
                        </span>
                        <span>
                          {DetailsPack?.COMPLETE_MOCK_TEST_AI} COMPLETE MOCK
                          TEST (POWERED BY AI)
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                    {DetailsPack?.TESTIFYI_REALITY_TEST ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_reality_test.svg"
                            alt="icon_reality_test"
                          />
                        </span>
                        <span>
                          {DetailsPack?.TESTIFYI_REALITY_TEST} TESTIFYI REALITY
                          TEST
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.MASTER_CLASS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_Master_class.svg"
                            alt="icon_Master_class"
                          />
                        </span>
                        <span> MASTER CLASS</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.One_On_One_SPEAKING_SESSIONS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_Speaking_Session.svg"
                            alt="icon_Speaking_Session"
                          />
                        </span>
                        <span>1:1 SPEAKING SESSIONS</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.LANGUAGE_PRACTICE_TEST ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_LANGUAGE_PRACTICE_TEST.svg"
                            alt="icon_LANGUAGE_PRACTICE_TEST"
                          />
                        </span>
                        <span>
                          {DetailsPack?.LANGUAGE_PRACTICE_TEST} TESTIFYI
                          PRACTICE TEST
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.GRAMMAR_TEST === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_GRAMMAR_TEST.svg"
                            alt="icon_GRAMMAR_TEST"
                          />
                        </span>
                        <span>GRAMMAR TEST</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.MOCK_INTERVIEW === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_Mock_INTERVIEW.svg"
                            alt="icon_Mock_INTERVIEW"
                          />
                        </span>
                        <span>MOCK INTERVIEW</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.GROUP_DISCUSSIONS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_GROUP_DISCUSSIONS.svg"
                            alt="icon_GROUP_DISCUSSIONS"
                          />
                        </span>
                        <span>GROUP DISCUSSIONS</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.BUSINESS_ENGLISH_SESSIONS === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_BUSINESS_ENGLISH_SESSIONS.svg"
                            alt="icon_BUSINESS_ENGLISH_SESSIONS"
                          />
                        </span>
                        <span>BUSINESS ENGLISH SESSIONS</span>
                      </p>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.SOFT_SKILLS_SESSION === "Yes" ? (
                      <p>
                        <span>
                          <img
                            src="/assets/image/ProductCard-Icon/icon_SOFT_SKILLS_SESSION.svg"
                            alt="icon_SOFT_SKILLS_SESSION"
                          />
                        </span>
                        <span>SOFT SKILLS SESSION</span>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h4>Empty Cart</h4>
                {/* <p>
                  search courses  <button className="custom_btn"></button>
                </p> */}
              </div>
            )}
          </div>

          <div>
            <div className="Price_Det_Cart">
              <div>
                <h4>PRICE DETAILS</h4>
              </div>
              {DetailsPack ? (
                <div>
                  <div className="Prices">
                    <p>Price</p>
                    <p>₹ {DetailsPack?.PackageId?.ActualCost}</p>
                  </div>
                  {OrderData?.AmountDiscounted ? (
                    <div className="Prices">
                      <p> Discount for you</p>
                      <p style={{ color: "green" }}>
                        -{OrderData?.AmountDiscounted}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <hr className="dotted" />
                  </div>

                  <div className="Prices">
                    <h5>Total Amount</h5>
                    <h5>₹ {OrderData?.amount}</h5>
                  </div>

                  <div>
                    <hr className="dotted" />
                  </div>

                  <div>
                    {OrderData?.AmountDiscounted ? (
                      <h6
                        style={{
                          color: "green",
                          padding: "0",
                          margin: "0",
                          marginBottom: DetailsPack?.PackageId?.isVisible
                            ? 0
                            : "5px",
                        }}
                      >
                        You will save{" "}
                        {OrderData.AmountDiscounted
                          ? OrderData.AmountDiscounted
                          : 0}{" "}
                        on this order.{" "}
                      </h6>
                    ) : (
                      ""
                    )}

                    {DetailsPack?.PackageId?.isVisible ? (
                      <div className="Coupon_Apply">
                        <input
                          placeholder="APPLY COUPON"
                          disabled={successCapply}
                          value={Coupon}
                          onChange={(e) =>
                            setCoupon(e.target.value?.toUpperCase())
                          }
                        />
                        {successCapply ? (
                          <button
                            className="RemoveC"
                            loading={loadingCapply}
                            disabled={loadingCapply}
                            onClick={() => {
                              dispatch({ type: COUPON_APPLY_RESET });
                              setCoupon("");
                            }}
                          >
                            REMOVE
                          </button>
                        ) : (
                          <button
                            loading={loadingCapply}
                            disabled={loadingCapply}
                            onClick={() => handleCouponApply()}
                          >
                            APPLY
                          </button>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {errorCapply ? (
                      <p
                        style={{
                          color: "red",
                          padding: "0 15px 10px 15px",
                          textAlign: "center",
                        }}
                      >
                        {errorCapply}
                      </p>
                    ) : (
                      ""
                    )}

                    {successCapply ? (
                      <p
                        style={{
                          color: "green",
                          padding: "0 15px 10px 15px",
                          textAlign: "center",
                          fontWeight: "700",
                        }}
                      >
                        Coupon Applied Successfully !
                      </p>
                    ) : (
                      ""
                    )}
                    <h6
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        padding: "0",

                        margin: DetailsPack?.PackageId?.isVisible ? 0 : "8px 0",
                      }}
                    >
                      <span>
                        <input
                          type="checkbox"
                          checked={AcceptTerms}
                          onClick={() => setTermsVisible(true)}
                          required
                        />
                      </span>
                      <span>I Agree with the Terms & Conditions *</span>
                    </h6>
                    {Package_Timing?.CustomTimings ? (
                      <h6 style={{ color: "blue" }}>
                        We have received your request and our team will get back
                        to you.
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="Prices">
                    <p>Price</p>
                    <p>₹ 0</p>
                  </div>
                  <div className="Prices">
                    <p> Discount for you</p>
                    <p style={{ color: "green" }}>- 0</p>
                  </div>
                  <div>
                    <hr className="dotted" />
                  </div>

                  <div className="Prices">
                    <h5>Total Amount</h5>
                    <h5>₹ 0</h5>
                  </div>

                  <div>
                    <hr className="dotted" />
                  </div>

                  <div>
                    {/* <h6 style={{ color: "green" }}>
                  You will save{" "}
                  {+DetailsPack?.PackageId?.ActualCost -
                    +DetailsPack?.PackageId?.DiscountedPrice}{" "}
                  on this order.{" "}
                </h6> */}
                  </div>
                </div>
              )}

              <div className="PlaceOrder">
                {Package_Timing?.CustomTimings ? (
                  <a
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    href="tel:+919289192873"
                    className="custom_btn"
                  >
                    Call Now
                  </a>
                ) : (
                  <button
                    disabled={!DetailsPack || loadingCapply}
                    onClick={() => handlePlaceOder()}
                    className="custom_btn"
                  >
                    {loadingOrder ? "Loading..." : "PLACE ORDER"}
                  </button>
                )}
              </div>
            </div>

            {errorOrder ? (
              <p
                style={{
                  color: "red",
                  padding: "0 15px 10px 15px",
                  textAlign: "center",
                  fontSize: "13px",
                }}
              >
                {errorOrder}
              </p>
            ) : (
              ""
            )}

            <div className="Safe_Secure">
              <p>
                <span>
                  <AiFillSafetyCertificate />
                </span>
                <span>Safe and Secure Payments 100%.</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

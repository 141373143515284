import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useDispatch } from "react-redux";
import { materialdet } from "../../Action/materialAction";
import { useState } from "react";

export default function MenuBarRight({
  Styles,
  OpenMenuBar,
  index,
  OpenBarClick,
  Details,
  setOpenMenuBar2,
  OpenMenuBar2,
}) {
  const [OpenSubMenu, setOpenSubMenu] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div className={Styles.MainContainer2}>
        <div
          onClick={() => {
            setOpenSubMenu(index);
            setOpenMenuBar2(index);
          }}
          className={Styles.RightSideMaterialMenu}
        >
          {OpenMenuBar2 == index ? (
            <h5>
              <IoIosArrowDown />
            </h5>
          ) : (
            <h5>
              <IoIosArrowForward />
            </h5>
          )}
          <h5>{Details?.materialTitle}</h5>
        </div>
        {OpenMenuBar2 == index
          ? Details?.SubTitle?.map((ev) => (
              <div
                style={{
                  padding: "10px 10px",
                  border: "0.1px solid bottom",
                  cursor: "pointer",
                  backgroundColor:
                    OpenMenuBar == ev?.materialSubTitleId ? "blue" : "white",
                  color:
                    OpenMenuBar == ev?.materialSubTitleId ? "white" : "black",
                }}
                onClick={() => {
                  OpenBarClick(ev?.materialSubTitleId);
                  dispatch(materialdet(ev?.materialSubTitleId));
                }}
              >
                <h5 style={{ padding: "0", margin: "0" }}>
                  {ev?.materialSubTitle}
                </h5>
              </div>
            ))
          : ""}
      </div>
    </>
  );
}

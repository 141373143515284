import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCalssMode } from "../../Action/CommonEnumsAction";

const ClassModeDropDown = React.memo(() => {
  const { ClassModeRec } = useSelector((s) => s.listCalssModeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listCalssMode());
  }, [dispatch]);

  return (
    <>
      {ClassModeRec ? (
        ClassModeRec?.map((ev, i) => (
          <option key={i + 900} value={ev?.id}>
            {ev?.name}
          </option>
        ))
      ) : (
        <></>
      )}
    </>
  );
});

export default ClassModeDropDown;

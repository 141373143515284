import {
  MATERIAL_DETAILS_FAIL,
  MATERIAL_DETAILS_REQUEST,
  MATERIAL_DETAILS_SUCCESS,
  MATERIAL_LIST_ALL_FAIL,
  MATERIAL_LIST_ALL_REQUEST,
  MATERIAL_LIST_ALL_SUCCESS,
} from "../Constant/materialConstant";

export const materialListAllRecReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case MATERIAL_LIST_ALL_REQUEST: {
      return { loading: true };
    }

    case MATERIAL_LIST_ALL_SUCCESS: {
      return { loading: false, materialListRec: payload };
    }

    case MATERIAL_LIST_ALL_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const materialdetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_DETAILS_REQUEST: {
      return { loading: true };
    }

    case MATERIAL_DETAILS_SUCCESS: {
      return { loading: false, detailsMatertial: payload };
    }

    case MATERIAL_DETAILS_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

// import Image from "./images/Amrita.jpeg";
// import Image2 from "./images/Mohan Mathur.jpeg";
// import Image3 from "./images/Abhishek.jpeg";
// import Image4 from "./images/Saurabh.png";
import { ReactComponent as LeftQuote } from "./svgs/quote-left-solid.svg";
import Slider from "react-slick";
import { MdArrowCircleLeft, MdArrowCircleRight } from "react-icons/md";
import Styles from "./Home.module.css"

export default function Testimonial({ TestmonialRef,Testimonials_Model }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: window.innerWidth <= 790 ? 1 : 3,
    slidesToScroll: 1,
    // nextArrow: <SliderArrow1 />,
    // prevArrow: <SliderArrow2 />,
  };
  return (
    <>
      <Slider ref={TestmonialRef} {...settings}>
        {Testimonials_Model?.map((el, index) => (
          <div key={index + 678}>
            <div className="testimonialContainer">
              <div className="testimonialHeader">
                <div className="imageContainer">
                  <img
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "70%" }}
                    src={el?.Image}
                    alt="krittibas"
                  />
                </div>
                <div className="nameContainer">
                  <h4>{el?.Name}</h4>
                  <p style={{ padding: "0", margin: "0", fontSize: "11px" }}>
                    {el?.extra}
                  </p>
                </div>
                <div className="iconContainer">
                  <LeftQuote
                    style={
                      index % 2 === 0 ? { fill: "grey" } : { fill: "#395ad3" }
                    }
                    className="icon"
                  />
                </div>
              </div>
              <div className="testimonialBody">
                <p className="testimonialBodyText Line_Space_elipis">
                  “{el?.Details}”
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div style={{ padding: "20px 0" }} className={Styles.SliderArrow}>
        <span onClick={() => TestmonialRef?.current?.slickPrev()}>
          <MdArrowCircleLeft />
        </span>
        <span onClick={() => TestmonialRef?.current?.slickNext()}>
          <MdArrowCircleRight />
        </span>
      </div>
    </>
  );
}

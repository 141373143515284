import { useState } from "react";
import Styles from "./Navbar.module.css";
import { FiUser } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useSelector } from "react-redux";

export default function Navbar({ Page }) {
  const [HideMenu, setHideMenu] = useState(false);
  const { Login_User_Loading, Login_User, Login_User_Error } = useSelector(
    (state) => state.USER_LOGIN_REDUCER
  );

  const Mobile_Screen = window.innerWidth;
  const Navigate = useNavigate();

  const handleMenu = (e) => {
    e.preventDefault();
    setHideMenu(!HideMenu);
  };

  return (
    <navbar>
      <div className={Styles.Navbar}>
        <div>
          <img
            onClick={() => Navigate("/")}
            src="/assets/image/Logos/Testify_White_Logo.svg"
            alt="logo"
          />
        </div>
        <Drawer
          style={{ zIndex: "99990" }}
          open={HideMenu}
          onClose={handleMenu}
          direction="right"
          className="bla bla bla"
        >
          <div className={Styles.Navbar_Section3}>
            <div>
              <p onClick={() => Navigate("/package")}>Find A Package</p>
            </div>
            <div>
              <p
                onClick={() => {
                  Navigate("/package");
                }}
                style={
                  Page === "Package"
                    ? {
                        backgroundColor: "#0b3eff",
                        color: "white",
                      }
                    : { textDecoration: "none" }
                }
              >
                Packages
              </p>
            </div>
            <div>
              <p
                onClick={() => {
                  Navigate("/test-papers");
                }}
                style={
                  Page === "PracticePaper"
                    ? {
                        textDecoration: "none",
                        backgroundColor: "#0b3eff",
                        color: "white",
                      }
                    : { textDecoration: "none" }
                }
              >
                Practice Papers
              </p>
            </div>
            <div>
              <p
                onClick={() => {
                  Navigate("/mock-test");
                }}
                style={
                  Page === "MockTest"
                    ? {
                        textDecoration: "none",
                        backgroundColor: "#0b3eff",
                        color: "white",
                      }
                    : { textDecoration: "none" }
                }
              >
                Mock Test
              </p>
            </div>
            {Login_User ? (
              <div className={Styles.Coordinator}>
                <div className={Styles.Coordinator_top}>
                  <div>
                    <h5>
                      {Login_User?.First_Name
                        ? Login_User?.First_Name +
                          " " +
                          (Login_User?.Last_Name ? Login_User?.Last_Name : "")
                        : ""}
                    </h5>
                  </div>
                  <div>
                    <img src="/assets/image/user.avif" alt="profile" />
                  </div>
                </div>
              </div>
            ) : (
              <div className={Styles.Login}>
                <div
                  onClick={(e) => {
                    e.preventDefault();

                    Navigate("/login");
                  }}
                  style={
                    Page === "Login"
                      ? { backgroundColor: "#0b3eff", color: "white" }
                      : { background: "none" }
                  }
                >
                  <span style={{ fontSize: "15px" }}>
                    <FiUser />
                  </span>
                  <span> Login </span>
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();

                    Navigate("/signup");
                  }}
                  style={
                    Page === "SignUp"
                      ? { backgroundColor: "#0b3eff", color: "white" }
                      : { background: "none" }
                  }
                >
                  Sign up
                </div>
              </div>
            )}
          </div>
        </Drawer>
        {Mobile_Screen >= 780 ? (
          <div className={Styles.Navbar_Section2}>
            <div>
              <Link style={{ textDecoration: "none" }} to="/package">
                <p>Find A Package</p>
              </Link>
            </div>
            <div>
              <Link style={{ textDecoration: "none" }} to="/package">
                <p>Packages</p>
              </Link>
            </div>
            <div>
              <Link style={{ textDecoration: "none" }} to="/test-papers">
                <p>Practice Papers</p>
              </Link>
            </div>
            <div>
              <Link style={{ textDecoration: "none" }} to="/mock-test">
                <p>Mock Test</p>
              </Link>
            </div>
            {Login_User ? (
              <div className={Styles.Coordinator}>
                <div
                  onClick={(e) => Navigate("/dashboard")}
                  className={Styles.Coordinator_top}
                >
                  <div>
                    <h5>
                      {Login_User?.First_Name
                        ? Login_User?.First_Name +
                          " " +
                          (Login_User?.Last_Name ? Login_User?.Last_Name : "")
                        : ""}
                    </h5>
                  </div>
                  <div>
                    <img src="/assets/image/user.svg" alt="profile" />
                  </div>
                </div>
              </div>
            ) : (
              <div className={Styles.Login_Signup}>
                <div onClick={() => Navigate("/login")}>
                  <span style={{ fontSize: "15px" }}>
                    <FiUser />
                  </span>
                  <span> Login </span>
                </div>
                <div
                  onClick={() => Navigate("/signup")}
                  style={{ backgroundColor: "#333333", color: "white" }}
                >
                  Sign up
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <span onClick={handleMenu}>
              <RxHamburgerMenu />
            </span>
          </div>
        )}
      </div>
    </navbar>
  );
}

import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRoute({ children }) {
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);
  const location = useLocation();

  if (!Login_User) {
    return <Navigate to="/login" state={location.pathname} replace />;
  }

  //   axiosConfig.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${accessToken}`;

  return children;
}

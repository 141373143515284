import {
  USER_LOGIN_FAILED,
  USER_LOGIN_REQ,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_PASSWORD_CHANGE_FAILED,
  USER_PASSWORD_CHANGE_REQ,
  USER_PASSWORD_CHANGE_RESET,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_RESET_PASSWORD_FAILED,
  USER_RESET_PASSWORD_REQ,
  USER_RESET_PASSWORD_RESET,
  USER_RESET_PASSWORD_SUCCESS,
  USER_SIGNUP_FAILED,
  USER_SIGNUP_REQ,
  USER_SIGNUP_RESET,
  USER_SIGNUP_SUCCESS,
  USER_UPDATE_DETAILS_FAILED,
  USER_UPDATE_DETAILS_REQ,
  USER_UPDATE_DETAILS_RESET,
  USER_UPDATE_DETAILS_SUCCESS,
  USER_VERIFY_URL_FAILED,
  USER_VERIFY_URL_REQ,
  USER_VERIFY_URL_RESET,
  USER_VERIFY_URL_SUCCESS,
} from "../Constant/UserConstant";

export const USER_LOGIN_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_LOGIN_REQ: {
      return { Login_User_Loading: true };
    }

    case USER_LOGIN_SUCCESS: {
      return { Login_User_Loading: false, Login_User: payload };
    }

    case USER_LOGIN_FAILED: {
      return { Login_User_Loading: false, Login_User_Error: payload };
    }

    case USER_LOGIN_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const USER_SIGNUP_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_SIGNUP_REQ: {
      return { Signup_User_Loading: true };
    }

    case USER_SIGNUP_SUCCESS: {
      return { Signup_User_Loading: false, Signup_User_Success: payload };
    }

    case USER_SIGNUP_FAILED: {
      return { Signup_User_Loading: false, Signup_User_Error: payload };
    }

    case USER_SIGNUP_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const USER_FORGOT_PASSWORD_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_RESET_PASSWORD_REQ: {
      return { loading: true };
    }

    case USER_RESET_PASSWORD_SUCCESS: {
      return { loading: false, success: payload };
    }

    case USER_RESET_PASSWORD_FAILED: {
      return { loading: false, error: payload };
    }

    case USER_RESET_PASSWORD_RESET: {
      return { success: "", error: "" };
    }

    default:
      return state;
  }
};

export const VERIFY_PASSWORD_URL_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_VERIFY_URL_REQ: {
      return { loading: true };
    }

    case USER_VERIFY_URL_SUCCESS: {
      return { laoding: false, success: payload };
    }

    case USER_VERIFY_URL_FAILED: {
      return { loading: false, error: payload };
    }

    case USER_VERIFY_URL_RESET: {
      return { success: "", error: "" };
    }
    default:
      return state;
  }
};

export const CHANGE_PASSWORD_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_PASSWORD_CHANGE_REQ: {
      return { loading: true };
    }

    case USER_PASSWORD_CHANGE_SUCCESS: {
      return { laoding: false, success: payload };
    }
    case USER_PASSWORD_CHANGE_FAILED: {
      return { laoding: false, error: payload };
    }
    case USER_PASSWORD_CHANGE_RESET: {
      return { success: "", error: "" };
    }
    default:
      return state;
  }
};

export const UPDATE_USER_DET_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_UPDATE_DETAILS_REQ: {
      return { loading: true };
    }
    case USER_UPDATE_DETAILS_SUCCESS: {
      return { loading: false, success: payload };
    }

    case USER_UPDATE_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    case USER_UPDATE_DETAILS_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

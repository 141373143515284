// import { TfiAlignJustify } from "react-icons/tfi";
import Styles from "./SidePanel.module.css";
import { IoIosCloseCircleOutline } from "react-icons/io";

export default function RightSidePanel({ SetSideBarOpen, SidebarOpen }) {
  const MobileScreen = window.innerWidth;
  return (
    <div
      className={
        SidebarOpen
          ? `${Styles.MainContainer} ${Styles.active}`
          : Styles.MainContainer
      }
    >
      {MobileScreen <= 780 ? (
        <h2
          onClick={() => SetSideBarOpen(!SidebarOpen)}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            right: "20px",
          }}
        >
          <IoIosCloseCircleOutline />
        </h2>
      ) : (
        ""
      )}
      <div className={Styles.RightSidePanel}>
        <div className={Styles.Coordinator}>
          <div className={Styles.Coordinator_top}>
            <div>
              <img src="/assets/image/Neha_Sagar.png" alt="profile" />
            </div>
            <div>
              <p>Coordinator</p>
              <h5>Neha Sagar</h5>
            </div>
          </div>
          <hr />
          <div>
            <p>info@testifyi.com</p>
          </div>
          <hr />
          <div>
            <p>+91 9310410737</p>
          </div>
        </div>
        <div className={Styles.Coordinator}>
          <div className={Styles.Coordinator_top}>
            <div>
              <img src="/assets/image/Chitranshi_Rastogi.png" alt="profile" />
            </div>
            <div>
              <p className={Styles.EducationTrainer}>Trainer</p>
              <h5>Chitranshi Rastogi</h5>
            </div>
          </div>
          <hr />
          <div>
            <p>info@testifyi.com</p>
          </div>
          <hr />
          <div>
            <p>+91 8851767054</p>
          </div>
        </div>
        <div className={Styles.Coordinator}>
          <div className={Styles.Coordinator_top}>
            <div>
              <img src="/assets/image/Vivek_Sharma.png" alt="profile" />
            </div>
            <div>
              <p className={Styles.EducationCoordinator}>Manager</p>
              <h5>Vivek Sharma</h5>
            </div>
          </div>
          <hr />
          <div>
            <p>info@testifyi.com</p>
          </div>
          <hr />
          <div>
            <p>+91 7982476900</p>
          </div>
        </div>
      </div>
    </div>
  );
}

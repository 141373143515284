import {
  GET_ATTENDANCE_FAILED,
  GET_ATTENDANCE_REQ,
  GET_ATTENDANCE_SUCCESS,
} from "../Constant/AttendanceConstatnt";

export const AttendanceRecordsListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_ATTENDANCE_REQ: {
      return { loading: true };
    }

    case GET_ATTENDANCE_SUCCESS: {
      return { loading: false, AttendanceRecs: payload };
    }
    case GET_ATTENDANCE_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

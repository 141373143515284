import Footer from "../../Components/Footer";
import TopFooter from "../../Components/TopFooterSection";
import {
  BsChevronLeft,
  BsChevronRight,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import Styles from "./MockTest.module.css";
import { FaCalendarAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { useState } from "react";
import Navbar2 from "../../Components/Navbar2";
import ReactHelmet from "../../Components/ReactHelmet";

export default function MockTest() {
  const [MockTestList, setMockTestList] = useState([1, 2, 3, 4, 5]);
  // const [OpenMenuBar, setOpenMenuBar] = useState(false);
  return (
    <div>
       <ReactHelmet
                    title={"Mock Test"}
                    description={""}
                  />
      <Navbar2 Page={"MockTest"} />
      {/*Banner Section 1*/}
      <div className={Styles.MockTest}>
        <section className={Styles.showcase}>
          <img src={"/assets/image/Package_Banner2.jpeg"} alt="Banner" />
          <div className={Styles.overlay}>
            <div>
              <div>
                <p>
                  <span> HOME</span> /<span> ALL COURSES </span>
                </p>
              </div>
              <div>
                <h1 className="Font_Rajdhani">All Upcoming Mock Exams</h1>
              </div>
              <div>
                <p>
                  We help internationals to get enrolled into the best possible
                  career-oriented courses <br /> along with the selection of
                  colleges and universities across Australia.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/*Section 2*/}
        <section>
          <div className={Styles.Custom_Margin}>
            <div>
              <div>
                <div className={Styles.FilterCard}>
                  <div>
                    <h4>Filter</h4>
                    <button>Clear Filters</button>
                  </div>
                  <hr />
                  <div>
                    <h4>Sort By</h4>
                    <div>
                      <input type="checkbox" /> <p>Earliest First</p>
                    </div>
                    <div>
                      <input type="checkbox" />
                      <p>Oldest First</p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <h4>Medium</h4>
                    <div>
                      <input type="checkbox" /> <p>Online</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>On Permises</p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <h4>Module</h4>
                    <div>
                      <p>Test Papers</p>
                      <p>
                        <BsFillArrowRightCircleFill />
                      </p>
                    </div>

                    <div>
                      <p>One To One Counselling</p>
                      <p>
                        <BsFillArrowRightCircleFill />
                      </p>
                    </div>
                  </div>
                </div>
                <div className={Styles.Expert}>
                  <img src="/assets/image/Expert.png" alt="expert_contact_us" />
                </div>
              </div>
              <div className={Styles.PackageCard_Container}>
                <div>
                  {MockTestList.map((el, i) => (
                    <div key={i + 34} className={Styles.MockTestCard}>
                      <div
                        className={
                          i % 2 === 0
                            ? Styles.MockCard1
                            : i % 3 === 0
                            ? Styles.MockCard2
                            : Styles.MockCard3
                        }
                      >
                        <div>
                          <h1>
                            Get 7-9 in IELTS Speaking : <br /> IELTS Speaking
                            Mock Exam
                          </h1>
                        </div>
                        <div>
                          <div>
                            <p>Exam Date</p>
                            <p>
                              <span style={{ color: "white" }}>
                                <FaCalendarAlt />
                              </span>
                              <span>10 Jul 2020</span>
                            </p>
                          </div>
                          <hr />
                          <div>
                            <p>Location</p>
                            <p>
                              <span>
                                <MdLocationOn />
                              </span>
                              <span>Naroji Nagar, Delhi</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={Styles.Mock_Card2}>
                        <div>
                          <div className={Styles.MockSpeaking}>
                            <p>Subject :</p>
                            <p>Speaking</p>
                            <p>Reading</p>
                            <p>Writing</p>
                          </div>
                          <div>
                            <p>
                              <strong> IELTS Syllabus in Details :</strong>
                              Listening, Speaking, Reading
                              <br /> (Academic and General Training), Writing
                              (Academic),
                              <br />
                              Writing (General Training)
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div>
                          <div>
                            <p>
                              Medium : <strong>MCQs, Paper & Pen</strong>
                            </p>
                          </div>
                          <div className={Styles.MockBtn}>
                            <div>
                              <p>₹ 299 /-</p>
                            </div>
                            <div>
                              <button>Enroll Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <div className={Styles.Pagination}>
                    <div>
                      <hr />
                    </div>
                    <div>
                      <p className={Styles.Page}>
                        <BsChevronLeft />
                      </p>
                      <p className={Styles.active}>1</p>
                      <p className={Styles.Page}>2</p>
                      <p className={Styles.Page}>3</p>
                      <p className={Styles.Page}>4</p>
                      <p className={Styles.Page}>5</p>
                      <p className={Styles.Page}>
                        <BsChevronRight />
                      </p>
                    </div>
                    <div>
                      <hr />
                    </div>
                  </div>
                  <div className={Styles.FAQ}>
                    <h1 className="Font_Rajdhani">
                      Frequently Asked Questions
                    </h1>
                    <div className={Styles.faq}>
                      <input id="faq-a" type="checkbox" />
                      <label for="faq-a">
                        <p className={Styles.faq_heading}>
                          <span>1</span>
                          Can a Person extend their 482 visa ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          It is completely safe and totally legal! There will is
                          no record of this process to your shared Dropbox
                          users.
                        </p>
                      </label>
                      <input id="faq-b" type="checkbox" />
                      <label for="faq-b">
                        <p className={Styles.faq_heading}>
                          <span>2</span> Is a person holding a 482 visa eligible
                          to work in Australia ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          Does a Person need to have an employer to sponsor them
                          so as to apply for a TSS visa ?
                          <strong>
                            In order to control the risk and secure the space
                            you earned, we will gradually process it.
                          </strong>
                          during this time you can still use your account as
                          normal as usual.
                        </p>
                      </label>
                      <input id="faq-c" type="checkbox" />
                      <label for="faq-c">
                        <p className={Styles.faq_heading}>
                          <span>3</span> How does one apply for a TSS visa ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          NO ACCESS TO YOUR PERSONAL ACCOUNT OR INFO IS
                          REQUIRED! All I need from you is your Dropbox referral
                          link.
                        </p>
                      </label>
                    </div>
                    <div>
                      <hr />
                    </div>
                  </div>

                  <div className={Styles.BrowseService}>
                    <h1 className="Font_Rajdhani">Browse Other Services</h1>
                    <div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>Live Classes</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>Upcoming Mock Test</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>All Test papers</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <TopFooter />
      <Footer />
    </div>
  );
}

import {
  GURULLY_DETAILS_FAIL,
  GURULLY_DETAILS_REQUEST,
  GURULLY_DETAILS_SUCCESS,
} from "../Constant/GurullyConstant";

export const GurullyDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case GURULLY_DETAILS_REQUEST: {
      return { loading: true };
    }

    case GURULLY_DETAILS_SUCCESS: {
      return { loading: false, detGurully: payload };
    }

    case GURULLY_DETAILS_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

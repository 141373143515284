import {
  MATERIAL_DETAILS_FAIL,
  MATERIAL_DETAILS_REQUEST,
  MATERIAL_DETAILS_SUCCESS,
  MATERIAL_LIST_ALL_FAIL,
  MATERIAL_LIST_ALL_REQUEST,
  MATERIAL_LIST_ALL_SUCCESS,
} from "../Constant/materialConstant";
import api from "../Core/api";

export const listMaterial = (PackageId) => async (dispatch) => {
  try {
    dispatch({
      type: MATERIAL_LIST_ALL_REQUEST,
    });
    const { data } = await api.get(`/student/material/${PackageId}`);
    const res = data.Result;

    dispatch({
      type: MATERIAL_LIST_ALL_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: MATERIAL_LIST_ALL_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const materialdet = (materialId) => async (dispatch) => {
  try {
    dispatch({
      type: MATERIAL_DETAILS_REQUEST,
    });
    const { data } = await api.get(`/student/material/desc/${materialId}`);
    const res = data.Result;

    dispatch({
      type: MATERIAL_DETAILS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: MATERIAL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

import Footer from "../../Components/Footer";
import Styles from "./PackageDetails.module.css";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import SliderArrow1 from "../../Components/SliderArrow1";
import SliderArrow2 from "../../Components/SliderArrow2";
import TopFooter from "../../Components/TopFooterSection";
import { IoMdStar, IoMdStarOutline } from "react-icons/io";
import Navbar2 from "../../Components/Navbar2";
import { useDispatch, useSelector } from "react-redux";
import { Package_List, packageDetails } from "../../Action/PacakgeAction";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import Enroll from "../Home/Enroll";
import PackageCard from "../../Components/PackageCard";
import Styles2 from "../Home/Home.module.css";
import { Get_Package_Type } from "../../Model/HomeModel";
import BookADemo from "../Home/BookADemo";
import { TbDiscountCheckFilled } from "react-icons/tb";
import ReactHelmet from "../../Components/ReactHelmet";
export default function PackageDetails() {
  const [EnrollActive, setEnrollActive] = useState(false);
  const [BookADemoActive, setBookADemoActive] = useState(false);
  const { id } = useParams();
  const [CourseId, setCourseId] = useState("");
  const { loading, DetailsPack, error } = useSelector(
    (s) => s.packageDetailsReducer
  );
  const BrowsePackageRef = useRef();
  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );
  const Package_Type = Get_Package_Type(DetailsPack?.PackageId?.PackageType);
  const Mobile_Screen = window.innerWidth;
  const BrowsePacakgesettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 3
        ? 3
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow1 />,
    prevArrow: <SliderArrow2 />,
  };

  const PTE_Scroller_Setting2 = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const Get_Package_Det = useCallback(() => {
    dispatch(packageDetails(id));
  }, [dispatch, id]);

  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 }));
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  useEffect(() => {
    Get_Package_Det();
  }, [Get_Package_Det]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Get_Package_Det]);

  return (
    <>
      <ReactHelmet title={"Course Details"} description={""} />
      {/* navbar Section */}
      <Navbar2 />
      {loading ? <Loading /> : ""}

      <section className={Styles.Top_banner_bg}>
        <div className={Styles.Margin2}>
          <div className={Styles.TopBanner_Sec}>
            <div>
              <div>
                <p style={{ fontSize: "13px" }}>
                  HOME / COURSE /
                  <span style={{ color: "#0b3eff" }}> IELTS CRASH COURSE</span>
                </p>
              </div>
              <div>
                <h1 className={Styles.Heading_Banner}>
                  {DetailsPack?.PackageId?.Name}
                </h1>
                <div className={Styles.Rating_Section}>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      <IoMdStar />
                    </span>
                    <span>
                      <IoMdStar />
                    </span>
                    <span>
                      <IoMdStar />
                    </span>
                    <span>
                      <IoMdStar />
                    </span>
                    <span>
                      <IoMdStarOutline />
                    </span>
                  </p>
                  <p>(256 ratings)</p>
                  <p>100 students</p>
                </div>
                <div>
                  <p>{DetailsPack?.PackageId?.Detail}</p>
                </div>
                <div className={Styles.Top_Banner_Package}>
                  <div style={{ marginRight: "6px" }}>
                    <div>
                      <img src="/assets/image/Package.svg" alt="icon" />
                    </div>
                    <div>
                      <span>PACKAGE TYPE</span>
                      <span>{DetailsPack?.PackageId?.PackageType}</span>
                    </div>
                  </div>
                  <hr />
                  <div style={{ marginRight: "6px" }}>
                    <div>
                      <img src="/assets/image/Duration.svg" alt="icon" />
                    </div>
                    <div>
                      <span>DURATION</span>
                      <span>{DetailsPack?.PackageId?.Duration} Weeks</span>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div>
                      <img src="/assets/image/Level.svg" alt="icon" />
                    </div>
                    <div>
                      <span>LEVEL TYPE</span>
                      <span>Intermediate</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={
                window.innerWidth <= 780
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <div className={Styles.RightSideSection}>
                <div className={Styles2.card_design}>
                  <div
                    style={{
                      backgroundImage: `url(${DetailsPack?.PackageId?.Image})`,
                    }}
                    className={`${Styles2.Card1} ${Styles2.Card1_Img}`}
                  >
                    <div>
                      {/* <button>Speaking</button> */}
                      <button style={{ backgroundColor: Package_Type?.color }}>
                        {Package_Type?.name}
                      </button>{" "}
                    </div>
                  </div>
                  <div>
                    <div>
                      <div>
                        {DetailsPack?.PackageId?.DiscountedPrice ? (
                          <h2>
                            <b>
                              Price : ₹{" "}
                              {DetailsPack?.PackageId?.DiscountedPrice} /-
                            </b>{" "}
                            <span className={Styles.Price_Hided}>
                              ₹ {DetailsPack?.PackageId?.ActualCost}
                            </span>{" "}
                          </h2>
                        ) : (
                          <h2>
                            <b>
                              Price : ₹ {DetailsPack?.PackageId?.ActualCost} /-
                            </b>{" "}
                            <span className={Styles.Price_Hided}></span>{" "}
                          </h2>
                        )}
                      </div>
                      <div style={{ padding: "20px 0" }}>
                        <button className={Styles.custom_btn2}>
                          <a
                            style={{ color: "white", textDecoration: "none" }}
                            href="tel:+919289192873"
                          >
                            {" "}
                            Speak With Us
                          </a>
                        </button>
                        <button
                          onClick={() => {
                            setEnrollActive(id);
                            setCourseId(DetailsPack?.PackageId?.CourseId);
                          }}
                          className={Styles.custom_btn2_active}
                        >
                          Enroll Now
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <p>
                        <strong>This package Includes : </strong>
                      </p>
                      <div className={Styles2.SpeakingWriting}>
                        {DetailsPack?.LIVE_CLASSES ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_LIVE_CLASSES.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span>
                              {DetailsPack?.LIVE_CLASSES} LIVE CLASSES{" "}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.One_On_One_DOUBT_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span>1:1 DOUBT SESSIONS </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.DOUBT_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span> DOUBT SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.SESSION_TESTS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_SESSION_TESTS.svg"
                                alt="icon_SESSION_TESTS"
                              />
                            </span>
                            <span>SESSION TESTS </span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.STUDY_MATERIAL_ACCESS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_STUDY_MATERIAL_ACCESS.svg"
                                alt="icon_STUDY_MATERIAL_ACCESS"
                              />
                            </span>
                            <span> STUDY MATERIAL ACCESS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.COMPLETE_MOCK_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_MOCK_TEST.svg"
                                alt="icon_MOCK_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.COMPLETE_MOCK_TEST} COMPLETE MOCK
                              TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.COMPLETE_MOCK_TEST_AI ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_MOCK_TEST_AI.svg"
                                alt="icon_MOCK_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.COMPLETE_MOCK_TEST_AI} COMPLETE MOCK
                              TEST (POWERED BY AI)
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.TESTIFYI_REALITY_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_reality_test.svg"
                                alt="icon_reality_test"
                              />
                            </span>
                            <span>
                              {" "}
                              {DetailsPack?.TESTIFYI_REALITY_TEST} TESTIFYI
                              REALITY TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.MASTER_CLASS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Master_class.svg"
                                alt="icon_Master_class"
                              />
                            </span>
                            <span>MASTER CLASS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.One_On_One_SPEAKING_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Speaking_Session.svg"
                                alt="icon_Speaking_Session"
                              />
                            </span>
                            <span>1:1 SPEAKING SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.LANGUAGE_PRACTICE_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_LANGUAGE_PRACTICE_TEST.svg"
                                alt="icon_LANGUAGE_PRACTICE_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.LANGUAGE_PRACTICE_TEST} TESTIFYI
                              PRACTICE TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.GRAMMAR_TEST === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_GRAMMAR_TEST.svg"
                                alt="icon_GRAMMAR_TEST"
                              />
                            </span>
                            <span>GRAMMAR TEST</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.MOCK_INTERVIEW === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Mock_INTERVIEW.svg"
                                alt="icon_Mock_INTERVIEW"
                              />
                            </span>
                            <span>MOCK INTERVIEW</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.GROUP_DISCUSSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_GROUP_DISCUSSIONS.svg"
                                alt="icon_GROUP_DISCUSSIONS"
                              />
                            </span>
                            <span>GROUP DISCUSSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.BUSINESS_ENGLISH_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_BUSINESS_ENGLISH_SESSIONS.svg"
                                alt="icon_BUSINESS_ENGLISH_SESSIONS"
                              />
                            </span>
                            <span>BUSINESS ENGLISH SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.SOFT_SKILLS_SESSION === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_SOFT_SKILLS_SESSION.svg"
                                alt="icon_SOFT_SKILLS_SESSION"
                              />
                            </span>
                            <span>SOFT SKILLS SESSION</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                
                <div>
              <button onClick={() => Navigate(`/Course/details/${DetailsPack?._id}`)}>
                {" "}
                View
              </button>
              <button onClick={() => setEnrollActive(DetailsPack?._id)}>
                Enroll Now
              </button>
            </div><div>
                  <div>
                    <img src={DetailsPack?.PackageId?.Image} alt="course" />
                  </div>
                  <div className={`${Styles.BtmConent}`}>
                    <div>
                      {DetailsPack?.PackageId?.DiscountedPrice ? (
                        <h2>
                          <b>
                            Price : ₹ {DetailsPack?.PackageId?.DiscountedPrice}{" "}
                            /-
                          </b>{" "}
                          <span className={Styles.Price_Hided}>
                            ₹ {DetailsPack?.PackageId?.ActualCost}
                          </span>{" "}
                        </h2>
                      ) : (
                        <h2>
                          <b>
                            Price : ₹ {DetailsPack?.PackageId?.ActualCost} /-
                          </b>{" "}
                          <span className={Styles.Price_Hided}>
                          
                          </span>{" "}
                        </h2>
                      )}
                    </div>
                    <div className={Styles.BuyNow}>
                      <button className={Styles.custom_btn2}>
                        Speak With Us
                      </button>
                      <button
                        onClick={() => setEnrollActive(id)}
                        className={Styles.custom_btn2_active}
                      >
                        Buy Now
                      </button>
                    </div>
                    <div>
                      <hr />
                    </div>
                    <div className={Styles.CourseIn}>
                      <h4>This Course includes :</h4>
                      <div className={Styles.SpeakingWriting}>
                        {DetailsPack?.LIVE_CLASSES ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_LIVE_CLASSES.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span>
                              {DetailsPack?.LIVE_CLASSES} LIVE CLASSES{" "}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.One_On_One_DOUBT_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span>1:1 DOUBT SESSIONS </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.DOUBT_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span> DOUBT SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.SESSION_TESTS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_SESSION_TESTS.svg"
                                alt="icon_SESSION_TESTS"
                              />
                            </span>
                            <span>SESSION TESTS </span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.STUDY_MATERIAL_ACCESS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_STUDY_MATERIAL_ACCESS.svg"
                                alt="icon_STUDY_MATERIAL_ACCESS"
                              />
                            </span>
                            <span> STUDY MATERIAL ACCESS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.COMPLETE_MOCK_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_MOCK_TEST.svg"
                                alt="icon_MOCK_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.COMPLETE_MOCK_TEST} COMPLETE MOCK
                              TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.COMPLETE_MOCK_TEST_AI ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_MOCK_TEST_AI.svg"
                                alt="icon_MOCK_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.COMPLETE_MOCK_TEST_AI} COMPLETE MOCK
                              TEST (POWERED BY AI)
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.TESTIFYI_REALITY_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_reality_test.svg"
                                alt="icon_reality_test"
                              />
                            </span>
                            <span>
                              {" "}
                              {DetailsPack?.TESTIFYI_REALITY_TEST} TESTIFYI
                              REALITY TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.MASTER_CLASS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Master_class.svg"
                                alt="icon_Master_class"
                              />
                            </span>
                            <span>MASTER CLASS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.One_On_One_SPEAKING_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Speaking_Session.svg"
                                alt="icon_Speaking_Session"
                              />
                            </span>
                            <span>1:1 SPEAKING SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.LANGUAGE_PRACTICE_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_LANGUAGE_PRACTICE_TEST.svg"
                                alt="icon_LANGUAGE_PRACTICE_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.LANGUAGE_PRACTICE_TEST} TESTIFYI
                              PRACTICE TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.GRAMMAR_TEST === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_GRAMMAR_TEST.svg"
                                alt="icon_GRAMMAR_TEST"
                              />
                            </span>
                            <span>GRAMMAR TEST</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.MOCK_INTERVIEW === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Mock_INTERVIEW.svg"
                                alt="icon_Mock_INTERVIEW"
                              />
                            </span>
                            <span>MOCK INTERVIEW</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.GROUP_DISCUSSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_GROUP_DISCUSSIONS.svg"
                                alt="icon_GROUP_DISCUSSIONS"
                              />
                            </span>
                            <span>GROUP DISCUSSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.BUSINESS_ENGLISH_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_BUSINESS_ENGLISH_SESSIONS.svg"
                                alt="icon_BUSINESS_ENGLISH_SESSIONS"
                              />
                            </span>
                            <span>BUSINESS ENGLISH SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.SOFT_SKILLS_SESSION === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_SOFT_SKILLS_SESSION.svg"
                                alt="icon_SOFT_SKILLS_SESSION"
                              />
                            </span>
                            <span>SOFT SKILLS SESSION</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>{" "}
                </div> */}

                {/* <div className={Styles.RightSide_Experts}>
                  <div className={Styles.Right_Section_Btm_sec}>
                    <h2>Language Experts In India</h2>
                    <div>
                      <h3>1200+</h3>
                      <p>IELTS & PTE Courses</p>
                    </div>
                    <hr />
                    <div>
                      <h3>360+</h3>
                      <p>Student College Support</p>
                    </div>
                    <hr />
                    <div>
                      <h3>25+</h3>
                      <p>Years Of Experience</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {EnrollActive ? (
        <Enroll
          setEnrollActive={setEnrollActive}
          Package_Id={EnrollActive}
          courseId={CourseId}
        />
      ) : (
        ""
      )}
      <section
        style={
          window.innerWidth <= 780 ? { display: "block" } : { display: "none" }
        }
      >
        <div className={Styles.RightSideSection}>
          <div className={Styles2.card_design} style={{ margin: " 20px auto" }}>
            <div
              style={{
                backgroundImage: `url(${DetailsPack?.PackageId?.Image})`,
              }}
              className={`${Styles2.Card1} ${Styles2.Card1_Img}`}
            >
              <div>
                {/* <button>Speaking</button> */}
                <button style={{ backgroundColor: Package_Type?.color }}>
                  {Package_Type?.name}
                </button>{" "}
              </div>
            </div>
            <div>
              <div>
                <div>
                  {DetailsPack?.PackageId?.DiscountedPrice ? (
                    <h2>
                      <b>
                        Price : ₹ {DetailsPack?.PackageId?.DiscountedPrice} /-
                      </b>{" "}
                      <span className={Styles.Price_Hided}>
                        ₹ {DetailsPack?.PackageId?.ActualCost}
                      </span>{" "}
                    </h2>
                  ) : (
                    <h2>
                      <b>Price : ₹ {DetailsPack?.PackageId?.ActualCost} /-</b>{" "}
                      <span className={Styles.Price_Hided}></span>{" "}
                    </h2>
                  )}
                </div>
                <div style={{ padding: "20px 0" }}>
                  <button className={Styles.custom_btn2}>
                    <a
                      style={{ color: "white", textDecoration: "none" }}
                      href="tel:+919289192873"
                    >
                      {" "}
                      Speak With Us
                    </a>
                  </button>
                  <button
                    onClick={() => {
                      setEnrollActive(id);
                      setCourseId(DetailsPack?.PackageId?.CourseId);
                    }}
                    className={Styles.custom_btn2_active}
                  >
                    Enroll Now
                  </button>
                </div>
              </div>
              <hr />
              <div>
                <p>
                  <strong>This package Includes : </strong>
                </p>
                <div className={Styles2.SpeakingWriting}>
                  {DetailsPack?.LIVE_CLASSES ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_LIVE_CLASSES.svg"
                          alt="icon_LIVE_CLASSES"
                        />
                      </span>
                      <span>{DetailsPack?.LIVE_CLASSES} LIVE CLASSES </span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.One_On_One_DOUBT_SESSIONS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                          alt="icon_LIVE_CLASSES"
                        />
                      </span>
                      <span>1:1 DOUBT SESSIONS </span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.DOUBT_SESSIONS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                          alt="icon_LIVE_CLASSES"
                        />
                      </span>
                      <span> DOUBT SESSIONS</span>
                    </p>
                  ) : (
                    ""
                  )}
                  {DetailsPack?.SESSION_TESTS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_SESSION_TESTS.svg"
                          alt="icon_SESSION_TESTS"
                        />
                      </span>
                      <span>SESSION TESTS </span>
                    </p>
                  ) : (
                    ""
                  )}
                  {DetailsPack?.STUDY_MATERIAL_ACCESS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_STUDY_MATERIAL_ACCESS.svg"
                          alt="icon_STUDY_MATERIAL_ACCESS"
                        />
                      </span>
                      <span> STUDY MATERIAL ACCESS</span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.COMPLETE_MOCK_TEST ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_MOCK_TEST.svg"
                          alt="icon_MOCK_TEST"
                        />
                      </span>
                      <span>
                        {DetailsPack?.COMPLETE_MOCK_TEST} COMPLETE MOCK TEST
                      </span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.COMPLETE_MOCK_TEST_AI ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_MOCK_TEST_AI.svg"
                          alt="icon_MOCK_TEST"
                        />
                      </span>
                      <span>
                        {DetailsPack?.COMPLETE_MOCK_TEST_AI} COMPLETE MOCK TEST
                        (POWERED BY AI)
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                  {DetailsPack?.TESTIFYI_REALITY_TEST ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_reality_test.svg"
                          alt="icon_reality_test"
                        />
                      </span>
                      <span>
                        {" "}
                        {DetailsPack?.TESTIFYI_REALITY_TEST} TESTIFYI REALITY
                        TEST
                      </span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.MASTER_CLASS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_Master_class.svg"
                          alt="icon_Master_class"
                        />
                      </span>
                      <span>MASTER CLASS</span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.One_On_One_SPEAKING_SESSIONS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_Speaking_Session.svg"
                          alt="icon_Speaking_Session"
                        />
                      </span>
                      <span>1:1 SPEAKING SESSIONS</span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.LANGUAGE_PRACTICE_TEST ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_LANGUAGE_PRACTICE_TEST.svg"
                          alt="icon_LANGUAGE_PRACTICE_TEST"
                        />
                      </span>
                      <span>
                        {DetailsPack?.LANGUAGE_PRACTICE_TEST} TESTIFYI PRACTICE
                        TEST
                      </span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.GRAMMAR_TEST === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_GRAMMAR_TEST.svg"
                          alt="icon_GRAMMAR_TEST"
                        />
                      </span>
                      <span>GRAMMAR TEST</span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.MOCK_INTERVIEW === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_Mock_INTERVIEW.svg"
                          alt="icon_Mock_INTERVIEW"
                        />
                      </span>
                      <span>MOCK INTERVIEW</span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.GROUP_DISCUSSIONS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_GROUP_DISCUSSIONS.svg"
                          alt="icon_GROUP_DISCUSSIONS"
                        />
                      </span>
                      <span>GROUP DISCUSSIONS</span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.BUSINESS_ENGLISH_SESSIONS === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_BUSINESS_ENGLISH_SESSIONS.svg"
                          alt="icon_BUSINESS_ENGLISH_SESSIONS"
                        />
                      </span>
                      <span>BUSINESS ENGLISH SESSIONS</span>
                    </p>
                  ) : (
                    ""
                  )}

                  {DetailsPack?.SOFT_SKILLS_SESSION === "Yes" ? (
                    <p>
                      <span>
                        <img
                          src="/assets/image/ProductCard-Icon/icon_SOFT_SKILLS_SESSION.svg"
                          alt="icon_SOFT_SKILLS_SESSION"
                        />
                      </span>
                      <span>SOFT SKILLS SESSION</span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles.Margin2}>
        <div>
          <div className={Styles.TopBanner_Sec2}>
            <div className={Styles.LeftSection}>
              {/* <div>
                <div className={Styles.Menu_Bar}>
                  <div
                    onClick={() => setMenubar("Lesson")}
                    className={
                      MenuBar === "Lesson" ? Styles.active : Styles.inActive
                    }
                  >
                    <p>Lesson</p>
                  </div>
                  <div className={Styles.Hr_Line}>
                    <hr className={Styles.Hr4} />
                  </div>
                  <div
                    onClick={() => setMenubar("Test Papers")}
                    className={
                      MenuBar === "Test Papers"
                        ? Styles.active
                        : Styles.inActive
                    }
                  >
                    {" "}
                    <p>Test Papers</p>{" "}
                  </div>

                  <div className={Styles.Hr_Line}>
                    <hr className={Styles.Hr4} />
                  </div>
                  <div
                    onClick={() => setMenubar("Cheatsheet")}
                    className={
                      MenuBar === "Cheatsheet" ? Styles.active : Styles.inActive
                    }
                  >
                    {" "}
                    <p>Cheatsheet</p>
                  </div>

                  <div className={Styles.Hr_Line}>
                    <hr className={Styles.Hr4} />
                  </div>
                  <div
                    onClick={() => setMenubar("Tasks")}
                    className={
                      MenuBar === "Tasks" ? Styles.active : Styles.inActive
                    }
                  >
                    {" "}
                    <p>Tasks</p>{" "}
                  </div>
                </div>
              </div> */}
              <div>
                <div className={Styles.AboutPackage}>
                  <h1 className={Styles.Heading}>
                    {" "}
                    <span className="Font_Rajdhani"> About This Package</span>
                  </h1>
                  <div style={{ minHeight: "180px" }}>
                    <p>
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: DetailsPack?.PackageId?.Description,
                        }}
                      ></div>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className={Styles.Line}>
                <hr />
              </div> */}

              {/* <div>
                <h1 className={Styles.Heading}>
                  
                  <span className="Font_Rajdhani">Package Lessons </span>
                </h1>
                <p style={{ padding: "5px 0" }}>
                  7 Lessons • 7h 19m total length
                </p>
                <div className={Styles.PackLesson}>
                  <div className={Styles.faq}>
                    <input id="faq-a" type="checkbox" />
                    <label for="faq-a">
                      <p className={Styles.faq_heading}>
                        <span>
                          <IoIosArrowDown />
                        </span>
                        General Overview
                      </p>
                      <div className={Styles.faq_arrow}>
                        4 Lectures • 30 min
                      </div>
                      <p className={Styles.faq_text}>
                        <b>Lesson Description: </b>
                        <br />
                        <br />
                        Learn how to think of ideas for any topic so you're
                        never stuck for arguments again !<br />
                        <br />
                        <button className={Styles.custom_btn3}>
                          Start Course
                        </button>
                      </p>
                    </label>

                    <input id="faq-b" type="checkbox" />
                    <label for="faq-b">
                      <p className={Styles.faq_heading}>
                        <span>
                          <IoIosArrowDown />
                        </span>
                        Approching the Eassy
                      </p>
                      <div className={Styles.faq_arrow}>
                        5 Lectures • 30 min
                      </div>
                      <p className={Styles.faq_text}>
                        <b>Lesson Description: </b>
                        <br />
                        <br />
                        Learn how to think of ideas for any topic so you're
                        never stuck for arguments again !<br />
                        <br />
                        <button className={Styles.custom_btn3}>
                          Start Course
                        </button>
                      </p>
                    </label>
                    <input id="faq-c" type="checkbox" />
                    <label for="faq-c">
                      <p className={Styles.faq_heading}>
                        <span>
                          
                          <IoIosArrowDown />
                        </span>
                        Structuring the Essay (Coherence and Cohesion)
                      </p>
                      <div className={Styles.faq_arrow}>
                        4 Lectures • 30 min
                      </div>
                      <p className={Styles.faq_text}>
                        <b>Lesson Description: </b>
                        <br />
                        <br />
                        Learn how to think of ideas for any topic so you're
                        never stuck for arguments again !<br />
                        <br />
                        <button className={Styles.custom_btn3}>
                          Start Course
                        </button>
                      </p>
                    </label>

                    <input id="faq-c" type="checkbox" />
                    <label for="faq-c">
                      <p className={Styles.faq_heading}>
                        <span>
                          {" "}
                          <IoIosArrowDown />
                        </span>{" "}
                        Answer the Question (Task Response)
                      </p>
                      <div className={Styles.faq_arrow}>
                        5 Lectures • 30 min
                      </div>
                      <p className={Styles.faq_text}>
                        <b>Lesson Description: </b>
                        <br />
                        <br />
                        Learn how to think of ideas for any topic so you're
                        never stuck for arguments again !<br />
                        <br />
                        <button className={Styles.custom_btn3}>
                          Start Course
                        </button>
                      </p>
                    </label>
                  </div>{" "}
                </div>
              </div>

              <div className={Styles.Line}>
                <hr />
              </div>
              <div className={Styles.TestPaper_Sec}>
                <h1 className={Styles.Heading}>
                  {" "}
                  <span className="Font_Rajdhani">Test Papers </span>
                </h1>
                <p>
                  Join an Testifyi to master new skills in your chosen
                  specialist area. Through a <br /> series of topic-focused
                  courses at your own pace, pass the final to earn a digital
                  certificate <br />
                  award which proves your expertise.
                </p>
                <div>
                  <div className={Styles.TestPaperCardContainer}>
                    <div className={Styles.TestPaperCard}>
                      <div>
                        <div>
                          <span style={{ color: "#f36b31" }}>
                            <BsFiletypePdf />
                          </span>
                          <span>
                            IELTS Practice Paper
                            <br /> Speaking Test
                          </span>
                        </div>
                      </div>
                      <div className={Styles.Hr_Line}>
                        <hr className={Styles.Hr3} />
                      </div>
                      <div className={Styles.Calender}>
                        <p>Duration</p>
                        <p>
                          <span>
                            <PiWatch />
                          </span>
                          <span>2 hr 30 min</span>
                        </p>
                      </div>
                    </div>
                    <div className={Styles.TestPaperCard}>
                      <div>
                        <div>
                          <span style={{ color: "#f36b31" }}>
                            <BsFiletypePdf />
                          </span>
                          <span>
                            IELTS Practice Paper
                            <br /> Speaking Test
                          </span>
                        </div>
                      </div>
                      <div className={Styles.Hr_Line}>
                        <hr className={Styles.Hr3} />
                      </div>
                      <div className={Styles.Calender}>
                        <p>Duration</p>
                        <p>
                          <span>
                            <PiWatch />
                          </span>
                          <span>2 hr 30 min</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.TestPaperCardContainer}>
                    <div className={Styles.TestPaperCard}>
                      <div>
                        <div>
                          <span style={{ color: "#f36b31" }}>
                            <BsFiletypePdf />
                          </span>
                          <span>
                            IELTS Practice Paper
                            <br /> Speaking Test
                          </span>
                        </div>
                      </div>
                      <div className={Styles.Hr_Line}>
                        <hr className={Styles.Hr3} />
                      </div>
                      <div className={Styles.Calender}>
                        <p>Duration</p>
                        <p>
                          <span>
                            <PiWatch />
                          </span>
                          <span>2 hr 30 min</span>
                        </p>
                      </div>
                    </div>
                    <div className={Styles.TestPaperCard}>
                      <div>
                        <div>
                          <span style={{ color: "#f36b31" }}>
                            <BsFiletypePdf />
                          </span>
                          <span>
                            IELTS Practice Paper
                            <br /> Speaking Test
                          </span>
                        </div>
                      </div>
                      <div className={Styles.Hr_Line}>
                        <hr className={Styles.Hr3} />
                      </div>
                      <div className={Styles.Calender}>
                        <p>Duration</p>
                        <p>
                          <span>
                            <PiWatch />
                          </span>
                          <span>2 hr 30 min</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.TestPaperCardContainer}>
                    <div className={Styles.TestPaperCard}>
                      <div>
                        <div>
                          <span style={{ color: "#f36b31" }}>
                            <BsFiletypePdf />
                          </span>
                          <span>
                            IELTS Practice Paper
                            <br /> Speaking Test
                          </span>
                        </div>
                      </div>
                      <div className={Styles.Hr_Line}>
                        <hr className={Styles.Hr3} />
                      </div>
                      <div className={Styles.Calender}>
                        <p>Duration</p>
                        <p>
                          <span>
                            <PiWatch />
                          </span>
                          <span>2 hr 30 min</span>
                        </p>
                      </div>
                    </div>
                    <div className={Styles.TestPaperCard}>
                      <div>
                        <div>
                          <span style={{ color: "#f36b31" }}>
                            <BsFiletypePdf />
                          </span>
                          <span>
                            IELTS Practice Paper
                            <br /> Speaking Test
                          </span>
                        </div>
                      </div>
                      <div className={Styles.Hr_Line}>
                        <hr className={Styles.Hr3} />
                      </div>
                      <div className={Styles.Calender}>
                        <p>Duration</p>
                        <p>
                          <span>
                            <PiWatch />
                          </span>
                          <span>2 hr 30 min</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button className={Styles.TestPaperBtn}>
                    <span>
                      <CiLock />
                    </span>
                    <span>Unlock More TestPapers Now</span>
                  </button>
                </div>
              </div>

              <div className={Styles.Line}>
                <hr />
              </div>
              <div>
                <h1 className={Styles.Heading}>
                  <span className="Font_Rajdhani">Package Cheatsheets </span>
                </h1>
                <p>
                  Join an Testifyi to master new skills in your chosen
                  specialist area. Through a <br /> series of topic-focused
                  courses at your own pace, pass the final to earn a digital
                  certificate <br />
                  award which proves your expertise.
                </p>

                <div className={Styles.CheatSheetContainer}>
                  <Slider {...CheatSheetSettings}>
                    <div>
                      <div className={Styles.CheetSheetCard}>
                        <div>
                          <span className={Styles.CReading}>Reading</span>
                        </div>
                        <div>
                          <h4>Cheatsheet 01</h4>
                          <p>
                            The IELTS Preparation courses in this specialization
                            are intended for those seeking to boost their scores
                            on
                          </p>
                        </div>
                        <div>
                          <button>View Details</button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={Styles.CheetSheetCard}>
                        <div>
                          <span className={Styles.CListening}>Listening</span>
                        </div>
                        <div>
                          <h4>Cheatsheet 01</h4>
                          <p>
                            The IELTS Preparation courses in this specialization
                            are intended for those seeking to boost their scores
                            on
                          </p>
                        </div>
                        <div>
                          <button>View Details</button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={Styles.CheetSheetCard}>
                        <div>
                          <span className={Styles.CWriting}>Writing</span>
                        </div>
                        <div>
                          <h4>Cheatsheet 01</h4>
                          <p>
                            The IELTS Preparation courses in this specialization
                            are intended for those seeking to boost their scores
                            on
                          </p>
                        </div>
                        <div>
                          <button>View Details</button>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className={Styles.CheetSheetCard}>
                        <div>
                          <span className={Styles.CReading}>Reading</span>
                        </div>
                        <div>
                          <h4>Cheatsheet 01</h4>
                          <p>
                            The IELTS Preparation courses in this specialization
                            are intended for those seeking to boost their scores
                            on
                          </p>
                        </div>
                        <div>
                          <button>View Details</button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={Styles.CheetSheetCard}>
                        <div>
                          <span className={Styles.CListening}>Listening</span>
                        </div>
                        <div>
                          <h4>Cheatsheet 01</h4>
                          <p>
                            The IELTS Preparation courses in this specialization
                            are intended for those seeking to boost their scores
                            on
                          </p>
                        </div>
                        <div>
                          <button>View Details</button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={Styles.CheetSheetCard}>
                        <div>
                          <span className={Styles.CWriting}>Writing</span>
                        </div>
                        <div>
                          <h4>Cheatsheet 01</h4>
                          <p>
                            The IELTS Preparation courses in this specialization
                            are intended for those seeking to boost their scores
                            on
                          </p>
                        </div>
                        <div>
                          <button>View Details</button>
                        </div>
                      </div>
                    </div>
                  </Slider>
                  <div className={Styles.ScrollerArrow}>
                    <div className={Styles.CheetSheetScroller}>
                      <span>
                        <IoIosArrowForward />
                      </span>
                      <span>
                        <IoIosArrowBack />
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <button className={Styles.TestPaperBtn}>
                    <span>
                      <CiLock />
                    </span>
                    <span>Unlock More Cheatsheets</span>
                  </button>
                </div>
              </div>
              <div className={Styles.Line}>
                <hr />
              </div>
              <div>
                <h1 className={Styles.Heading}>
                  {" "}
                  <span className="Font_Rajdhani">Package Tasks </span>
                </h1>
                <p>
                  Join an Testifyi to master new skills in your chosen
                  specialist area. Through a <br /> series of topic-focused
                  courses at your own pace, pass the final to earn a digital
                  certificate <br />
                  award which proves your expertise.
                </p>
                <div className={Styles.PackageTaskContainer}>
                  <div className={Styles.PackageTaskCard}>
                    <div>
                      <h4>You Need To Write 150 Words </h4>
                      <div className={Styles.pending}>
                        <span>
                          <GoClock />
                        </span>
                        <span>Pending</span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p>
                          Aims to assess your ability to express opinions or
                          explain a point in
                          <br /> the form of written English essays. It will
                          examine your language
                          <br /> proficiency level with 4 standards:
                        </p>{" "}
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <span>
                          <GoClock />
                        </span>
                        <span>Time To Complete :</span>
                        <span>2 hr 30 min</span>
                      </div>
                      <div></div>
                    </div>
                  </div>

                  <div className={Styles.PackageTaskCard}>
                    <div>
                      <h4>You Need To Write 150 Words </h4>
                      <div className={Styles.progress}>
                        <span>
                          <GoClock />
                        </span>
                        <span>In progress</span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p>
                          Aims to assess your ability to express opinions or
                          explain a point in
                          <br /> the form of written English essays. It will
                          examine your language
                          <br /> proficiency level with 4 standards:
                        </p>{" "}
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <span>
                          <GoClock />
                        </span>
                        <span>Time To Complete :</span>
                        <span>2 hr 30 min</span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className={Styles.PackageTaskCard}>
                    <div>
                      <h4>You Need To Write 150 Words </h4>
                      <div className={Styles.completed}>
                        <span>
                          <GoClock />
                        </span>
                        <span>Completed</span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p>
                          Aims to assess your ability to express opinions or
                          explain a point in
                          <br /> the form of written English essays. It will
                          examine your language
                          <br /> proficiency level with 4 standards:
                        </p>{" "}
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <span>
                          <GoClock />
                        </span>
                        <span>Time To Complete :</span>
                        <span>2 hr 30 min</span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className={Styles.PackageTaskCard}>
                    <div>
                      <h4>You Need To Write 150 Words </h4>
                      <div className={Styles.pending}>
                        <span>
                          <GoClock />
                        </span>
                        <span>Pending</span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p>
                          Aims to assess your ability to express opinions or
                          explain a point in
                          <br /> the form of written English essays. It will
                          examine your language
                          <br /> proficiency level with 4 standards:
                        </p>{" "}
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <span>
                          <GoClock />
                        </span>
                        <span>Time To Complete :</span>
                        <span>2 hr 30 min</span>
                      </div>
                      <div></div>
                    </div>
                  </div>

                  <div className={Styles.PackageTestBtn}>
                    <div>
                      <div className={Styles.PackageTestBtnScroller}>
                        <span>
                          <IoIosArrowBack />
                        </span>
                        <span>
                          <IoIosArrowForward />
                        </span>
                      </div>
                    </div>
                    <div>
                      <button className={Styles.TestPaperBtn}>
                        <span>
                          <CiLock />
                        </span>
                        <span>Unlock More Package Tasks</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div></div>
          </div>
        </div>
      </section>
      {/* <section  style={{height:"65vh"}}>

      </section> */}

      {/* One to one Counseliing Section */}

      {/* section 5 */}
      <section className={Styles2.Section4}>
        <div className={Styles2.Margin2}>
          <div
            // className={Styles.Section_7_style}
            style={{
              // display: "flex",
              // justifyContent: "flex-start",
              alignItems: "center",
              margin: "3% 0 8% 0",
            }}
          >
            <div>
              {/* <p style={{ color: "#0b3eff", fontWeight: "600" }}>Upgrade</p> */}
              <div>
                <h1
                  className={Styles2.Heading7}
                  style={{ color: "white", fontSize: "35px" }}
                >
                  <span className={Styles2.Font_Rajdhani}>
                    Book A Demo For Live Classes
                  </span>
                </h1>
                <p style={{ color: "white", margin: "0" }}>
                  " Get an overall view of the course and understand your
                  preparation journey"
                </p>
              </div>
              <div className={Styles2.Book_A_Demo}>
                {window.innerWidth >= 780 ? (
                  <Slider {...PTE_Scroller_Setting2}>
                    <div>
                      <div className={Styles2.Section4_Text}>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Live assessment and <br />
                            feedback from the <br />
                            experts
                          </p>
                        </div>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Free on-spot
                            <br /> evaluation
                          </p>
                        </div>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>Doubts resolution</p>
                        </div>
                        <hr className={Styles2.Hr1} />
                      </div>
                    </div>

                    <div>
                      <div className={Styles2.Section4_Text}>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Customized course plans
                            <br /> according to your
                            <br /> strenghts & weaknesses
                          </p>
                        </div>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Free on-spot
                            <br /> evaluation
                          </p>
                        </div>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>Doubts resolution</p>
                        </div>
                        <hr className={Styles2.Hr1} />
                      </div>
                    </div>
                  </Slider>
                ) : (
                  <Slider {...PTE_Scroller_Setting2}>
                    <div>
                      <div className={Styles2.Section4_Text}>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Live assessment and <br />
                            feedback from the <br />
                            experts
                          </p>
                        </div>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Free on-spot
                            <br /> evaluation
                          </p>
                        </div>
                        <hr className={Styles2.Hr1} />
                      </div>
                    </div>

                    <div>
                      <div className={Styles2.Section4_Text}>
                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>Doubts resolution</p>
                        </div>

                        <hr className={Styles2.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Live assessment and <br />
                            feedback from the <br />
                            experts
                          </p>
                        </div>
                        <hr className={Styles2.Hr1} />
                      </div>
                    </div>

                    {/* <div>
                    <div className={Styles.Section4_Text}>
                      <hr className={Styles.Hr1} />
                      <div style={{ fontSize: "18px" }}>
                        <p style={{ color: "#42d96b", fontSize: "25px" }}>
                          <TbDiscountCheckFilled />
                        </p>
                        <p>
                          Customized course plans
                          <br /> according to your
                          <br /> strenghts & weaknesses
                        </p>
                      </div>
                      <hr className={Styles.Hr1} />
                      <div style={{ fontSize: "18px" }}>
                        <p style={{ color: "#42d96b", fontSize: "25px" }}>
                          <TbDiscountCheckFilled />
                        </p>
                        <p>
                          Live assessment and <br />
                          feedback from the <br />
                          experts
                        </p>
                      </div>
                      <hr className={Styles.Hr1} />
                      <div style={{ fontSize: "18px" }}>
                        <p style={{ color: "#42d96b", fontSize: "25px" }}>
                          <TbDiscountCheckFilled />
                        </p>
                        <p>
                          Free on-spot
                          <br /> evaluation
                        </p>
                      </div>
                      <hr className={Styles.Hr1} />
                    </div>
                  </div> */}
                  </Slider>
                )}
              </div>

              {/* Doubts resolution */}

              <div
                style={{ padding: "40px 0px", display: "flex", gap: "20px" }}
              >
                <button
                  className={Styles2.custom_btn}
                  onClick={() => Navigate("/contact-us")}
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "0.1px solid white",
                  }}
                >
                  Let's Connect
                </button>
                <button
                  onClick={() => setBookADemoActive(true)}
                  className={Styles2.custom_btn}
                >
                  Book A Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {BookADemoActive ? (
        <BookADemo setBookADemoActive={setBookADemoActive} />
      ) : (
        ""
      )}
      {/* <section className={Styles.Section4}>
        <div className={Styles.Margin2}>
          <div
            className={Styles.Section_7_style}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "8% 0",
            }}
          >
            <div>
              <p style={{ color: "#0b3eff", fontWeight: "600" }}>Upgrade</p>
              <div>
                <h1
                  className={Styles.Heading7}
                  style={{ color: "white", fontSize: "35px" }}
                >
                  <span className="Font_Rajdhani">
                    One To One &<br /> Counselling Session{" "}
                  </span>
                </h1>
                <p
                  style={{
                    color: "white",
                    textAlign: "left",
                    letterSpacing: "2px",
                  }}
                >
                  <span>
                    Join an Testifyi to master new skills in your chosen
                    <br />
                    specialist area. Through a series of topic-focused courses
                    at
                    <br />
                    your own pace, pass the final to earn a digital certificate
                    award
                    <br /> which proves your expertise.{" "}
                  </span>
                </p>
              </div>
              <div></div>
              <div
                style={{ padding: "40px 0px", display: "flex", gap: "20px" }}
              >
                <button
                  className={Styles.custom_btn}
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "0.1px solid white",
                  }}
                >
                  Free Meet
                </button>
                <button className={Styles.custom_btn}>Paid Counselling</button>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Browse Other Packages */}
      <section style={{ background: "#f4f9ff", paddingBottom: "60px" }}>
        <div className={Styles.Margin2}>
          <div className={Styles.Browse_Package_Section}>
            <div>
              {" "}
              <h1 className={Styles.Heading}>
                <span className="Font_Rajdhani">Browse Other Courses </span>
              </h1>
              {/* <p>
                In this free interactive webinar, our IELTS expert will help us
                be ready to answer
                <br /> a range of common questions types for Part 1 of the
                Speaking Test.
              </p>{" "} */}
            </div>
            <div className={Styles.Browse_Package_Scroller}>
              <div onClick={() => BrowsePackageRef?.current?.slickPrev()}>
                <FaCircleArrowLeft />
              </div>
              <div onClick={() => BrowsePackageRef?.current?.slickNext()}>
                {" "}
                <FaCircleArrowRight />
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.Margin3}>
          <Slider
            ref={BrowsePackageRef}
            style={{ width: "100%" }}
            {...BrowsePacakgesettings}
          >
            {PackageRec?.map((el, i) => (
              <div key={el._id}>
                <PackageCard
                  setEnrollActive={setEnrollActive}
                  EnrollActive={EnrollActive}
                  setCourseId={setCourseId}
                  el={el}
                  Styles={Styles2}
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* Top Footer Section*/}
      <TopFooter />
      <Footer />
      {/* Footer Section */}
    </>
  );
}

import { useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar2 from "../../Components/Navbar2";
import Styles from "./TermsAndCondition.module.css";
import ReactHelmet from "../../Components/ReactHelmet";
export default function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ReactHelmet title={"Terms And Conditions"} description={"Terms & Conditions of Testifyi."} />
      {/* navbar Section */}
      <Navbar2 />
      <section className={Styles.showcase}>
        <img src={"/assets/image/T&C.jpg"} alt="Banner" />
        <div className={Styles.overlay}>
          <div>
            <div>
              <p>
                <span> HOME</span> /<span> TERMS AND CONDITIONS </span>{" "}
              </p>
            </div>
            <div>
              <h1 style={{ fontSize: "40px" }} className="Font_Rajdhani">
                Terms And Conditions
              </h1>
            </div>
            <div>
              <p>
                Our Terms & Conditions govern the use of our services, detailing
                user
                <br /> rights, responsibilities, and legal guidelines.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles.Margin2}>
        <div>
          <div>
            <p>
              <span>The portal </span>
              <strong>testifyi.com</strong>
              <span>
                {" "}
                has been carefully designed to provide a comprehensive,
                methodological practice platform for English Proficiency Exams,
                both in terms of content and technical aspects. These terms and
                conditions have been drafted with due diligence, covering all
                aspects of service usage. We recommend reading them thoroughly
                before using any of our services.
              </span>
            </p>
            <p>
              <span>
                By reviewing this set of Terms &amp; Conditions, you acknowledge
                and agree to the legal obligations and guidelines associated
                with using our services. By accessing our website or any
                services, you consent to all the clauses outlined in these
                terms.
              </span>
            </p>
            <p>
              <span>
                Before making any purchases, carefully review the associated
                terms to avoid any potential issues later. Please ensure you
                read and understand our Terms &amp; Conditions before agreeing
                to our Privacy Policy.
              </span>
            </p>
            <p>
              <span>
                We reserve all rights to the content, graphics, logos, layout,
                design, and infographics present on the website. The content is
                developed in compliance with Indian and international law. Any
                tampering, misuse, or unauthorized copying of the content will
                be considered illegal, and appropriate action will be taken
                immediately.
              </span>
            </p>
            <p>
              <span>
                By using our website, we assume you are of legal age and have
                basic internet and computer literacy. Any issues arising from a
                lack of such knowledge are not our responsibility.
              </span>
            </p>
            <p>
              <span>
                We reserve the right to alter, modify, delete, or update any
                content on our website for valid reasons without prior notice.
              </span>
            </p>
            <p>
              <span>
                Please note that the website may not be entirely free from bugs
                or viruses. While the platform is developed using advanced
                technology, there is always a risk of virtual attacks. We do not
                guarantee immunity from malicious activity. Users are
                responsible for taking appropriate precautionary measures, and
                we will not be liable for any damage caused by virtual threats.
              </span>
            </p>
            <p>
              <span>
                You are permitted to use our website for lawful purposes only.
                Should you be found introducing malicious technologies such as
                Trojans, worms, viruses, or logic bombs, or gaining unauthorized
                access, the company reserves the right to take immediate action
                against you.
              </span>
            </p>
            <p>
              <span>
                If any third-party content or services are linked on our
                website, we are not responsible for any harm that may result.
                These links are provided for your convenience, and any
                complaints about third-party services must be directed to those
                third parties.
              </span>
            </p>
            <p>
              <span>
                Each purchase plan is valid for a set duration starting from the
                date of purchase. Once expired, the plan cannot be reissued.
              </span>
            </p>
            <p>
              <span>
                Our exams are optimized for use on Google Chrome. While the
                platform has been tested across different systems,{" "}
              </span>
              <strong>testifyi.com</strong>
              <span>
                {" "}
                is not liable for any issues encountered on unsupported systems.
              </span>
            </p>
            <p>
              <span>
                Once a payment is made, it is non-refundable, and your exam will
                not be forfeited. The choice to take the exam remains with you.
              </span>
            </p>
            <p>
              <span>
                Under normal circumstances, exam results will be available
                within 10 minutes. We are not responsible for any delays or
                cancellations due to unforeseen circumstances.
              </span>
            </p>
            <p>
              <span>
                In the event of a violation of our terms and conditions, we
                reserve the right to suspend or permanently terminate your
                account.
              </span>
            </p>
            <p>
              <span>
                Each exam attempt allows for pausing and resuming multiple
                times. However, unsaved, random exits will not be renewed under
                any condition.
              </span>
            </p>
            <p>
              <span>The results provided by </span>
              <strong>testifyi.com</strong>
              <span>
                {" "}
                are for testing your skills and abilities. These results are not
                valid for any immigration purposes.
              </span>
            </p>
            <p>
              <strong>testifyi.com</strong>
              <span>
                {" "}
                does not guarantee that the scores obtained on our platform will
                reflect those of official exams. However, you can practice and
                assess your abilities through our services.
              </span>
            </p>
            <p>
              <span>
                PTE is a registered trademark of Pearson PLC Group. This website
                is neither endorsed nor approved by Pearson.
              </span>
            </p>
            <p>
              <span>
                To ensure smooth use of our platform, a stable internet
                connection with a minimum speed of 2 Mbps is required for mock
                tests. We are not responsible for any disruptions during mock
                tests due to connectivity issues.
              </span>
            </p>
            <p style={{ fontSize: "27px" }}>
              <strong>Security</strong>
            </p>
            <p>
              <span>At </span>
              <strong>testifyi.com</strong>
              <span>
                , we maintain secure data networks to protect your information
                and ensure the safety of your personal details. Our systems are
                designed with robust security measures to safeguard your data.
              </span>
            </p>
            <p>
              <span>
                While we take all necessary precautions to protect your personal
                information, in certain legal or reasonable circumstances, we
                reserve the right to share your information with trusted third
                parties or partners to improve our services or comply with legal
                obligations.
              </span>
            </p>
            <p>
              <span>
                We provide secure gateways for all debit and credit card
                transactions to ensure your financial data is protected.
              </span>
            </p>
            <p>
              <span>
                When you use our services, we may request personal and
                confidential details to ensure accurate service delivery. We
                trust that the information provided by you is accurate and
                reliable. Any service failure, rejection, or delay due to
                incorrect details will not be our responsibility, and you will
                bear full accountability for any errors.
              </span>
            </p>
            <p>
              <span>
                If any inaccuracies are found in the information you provide, we
                reserve the right to terminate services without prior notice,
                and no compensation will be given. Additionally, some of your
                personal information may be shared with third-party service
                providers to enhance your experience. However, we are not
                responsible for any misuse of your information by these third
                parties.
              </span>
            </p>
            <p>
              <span>
                You are solely responsible for your interactions with any
                third-party services available on our website. We will not be
                liable for any fraudulent activities or issues arising from
                third-party services.
              </span>
            </p>
            <p>
              <span>
                Please note that only your personal details will be stored with
                us. All banking information is encrypted, and we do not have
                access to these details. Any transaction failures, incorrect
                deductions, or disputes related to payments are matters to be
                handled by your bank.
              </span>
            </p>
            <p>
              <span>Any legal disputes between users and </span>
              <strong>testifyi.com</strong>
              <span>
                {" "}
                will be governed by the Information Technology Act, 2000, and
                other applicable laws and regulations.
              </span>
            </p>
            <p>
              <span>
                If you believe that our website or company has infringed on any
                intellectual property rights, please provide supporting
                evidence, and we will promptly investigate and remove any
                infringing content if necessary.
              </span>
            </p>
            <p>
              <span>
                You are responsible for any loss, damage, or misuse of
                information while using our website. You use this website at
                your own risk. We are not liable for any service disruptions,
                transaction failures, or technical issues that may arise. Claims
                for refunds or returns due to technical failures will not be
                entertained.
              </span>
            </p>
            <p>
              <span>
                Additionally, we are not responsible for any delays or
                cancellations in visa applications caused by technical issues.
                Any claims related to such delays will be dismissed without
                further notice.
              </span>
            </p>
            <p style={{ fontSize: "27px" }}>
              <strong>Cancellation and Refund Policy</strong>
            </p>
            <p>
              <span>
                Once a course/exam purchase is completed, we do not offer
                refunds. If you choose to cancel your purchase, your account
                access will be revoked, and you will be unable to perform any
                tasks on our platform. It is your responsibility to complete the
                course/exam before the expiration date.
              </span>
            </p>
            <p>
              <span>
                Digital goods fall under a unique category within the Consumer
                Contracts Regulations and are not classified as traditional
                goods or services. Consequently, there is no buyer or seller
                protection for digital goods or virtual items purchased.
              </span>
            </p>

            <p>
              <strong>No Switching Between Products:</strong>
              <span>
                {" "}
                Once you have purchased a course, you are not permitted to
                switch to a different course or package. All purchases are final
                and non-transferable.
              </span>
            </p>
            <p>
              <strong>Account Deletion:</strong>
              <span>
                {" "}
                Deleting your account does not entitle you to a refund for any
                unused portion of your purchased course or package. Furthermore,
                once your account is deleted, it cannot be restored, and any
                remaining subscription time or services will be forfeited.
              </span>
            </p>

            <p style={{ fontSize: "27px" }}>
              <strong>Discount/Coupon Redemption Terms & Conditions</strong>
            </p>

            <p>
              <strong>Non-Transferability: </strong>
              <span>
                {" "}
                The coupon is strictly non-transferable and can only be redeemed
                by the individual to whom it is issued.
              </span>
            </p>

            <p>
              <strong>No Refund Policy: </strong>
              <span>
                {" "}
                Once a discount has been applied and payment has been made, no
                refunds will be issued. This applies to all services purchased
                with a discount using the coupon, including exam preparation
                packages, mock tests, and classes (online, offline, or hybrid).
              </span>
            </p>

            <p>
              <strong>Class Schedule Changes: </strong>
              <span>
                Class timings are subject to change at the sole discretion of
                Testifyi management. Any alterations will be communicated in
                advance where possible.
              </span>
            </p>
            <p>
              <strong>Class Availability: </strong>
              <span>
                Enrollment in classes is dependent on seat availability. Seats
                will be allocated on a first-come, first-served basis. Please
                note that class schedules are subject to change at the
                discretion of Testifyi management.
              </span>
            </p>
            <p>
              <strong> Redemption Criteria: </strong>
              <span>
                To redeem the coupon, a minimum payment of ₹1,000 must be made
                at the time of registration. The remaining balance must be
                settled within one week of the initial registration payment.
              </span>
            </p>

            <p style={{ fontSize: "27px" }}>
              <strong>Cookies</strong>
            </p>
            <p>
              <span>
                A "cookie" is a text file that helps your system access specific
                website data more efficiently, enhancing your navigation
                experience on our platform. Cookies can also be used for
                record-keeping purposes, tracking your on-page activity to
                provide you with a better user experience.
              </span>
            </p>
            <ul>
              <li>
                <strong>Persistent Cookies</strong>
                <span>: These remain on your hard drive permanently.</span>
              </li>
              <li>
                <strong>Session Cookies</strong>
                <span>: These expire once the browser is closed.</span>
              </li>
            </ul>
            <p>
              <span>
                You may delete cookies if you wish, but doing so may affect the
                functionality of some services and features on our website. For
                more information on managing cookies, please refer to your
                browser settings.
              </span>
            </p>
            <p style={{ fontSize: "27px" }}>
              <strong>Third-Party Links</strong>
            </p>
            <p>
              <span>
                Third-party links may be accessible through our website and may
                use cookies to collect personal information or data
                electronically. However, we cannot control these activities, as
                our privacy policy does not cover third-party terms and
                practices. We encourage you to review the privacy policies of
                these third parties to understand their specific practices and
                opt-out options.
              </span>
            </p>
            <p>
              <span>This policy does not extend to </span>
              <strong>testifyi.com</strong>
              <span>
                . For any inquiries regarding third-party links or how they
                handle your personal data, please contact the third-party
                providers directly.
              </span>
            </p>
            <p style={{ fontSize: "27px" }}>
              <strong>Product Copyright Information</strong>
            </p>
            <ul>
              <li>
                <strong>PTE</strong>
                <span>
                  : The Pearson Test of English is a product of Pearson and is
                  not endorsed or affiliated with{" "}
                </span>
                <strong>testifyi.com</strong>
                <span>
                  . We do not hold any rights to the name or its copyright.
                </span>
              </li>
              <li>
                <strong>Duolingo</strong>
                <span>
                  : The Duolingo platform is not endorsed or approved by
                  Duolingo.{" "}
                </span>
                <strong>Testifyi.com</strong>
                <span>
                  {" "}
                  is solely a practice platform for exam preparation and does
                  not hold any copyright to the name or product.
                </span>
              </li>
              <li>
                <strong>IELTS</strong>
                <span>: </span>
                <strong>Testifyi.com</strong>
                <span>
                  {" "}
                  is not affiliated with IELTS. We act solely as a practice
                  provider, and all rights are reserved by the respective
                  companies.
                </span>
              </li>
              <li>
                <strong>CELPIP</strong>
                <span>
                  : The CELPIP test is a copyright product of Paragon Testing
                  Enterprises.{" "}
                </span>
                <strong>Testifyi.com</strong>
                <span>
                  {" "}
                  does not hold any copyright to this product and serves only as
                  a practice provider.
                </span>
              </li>
            </ul>
            <p>
              <strong>How to Contact Us</strong>
              <span>
                : For any questions or concerns related to our privacy policy or
                any other service, please contact us at admin@testifyi.com.
              </span>
            </p>
          </div>
        </div>
      </section>
      {/* Top Footer Section*/}
      <section className={"Footer_Top"}>
        <div>
          <div>
            <h1 className={"Font_Rajdhani"}>
              Start Your IELTS Journey With The Best
            </h1>
          </div>
          <div>
            <button className={"Font_Rajdhani"}>Create Account</button>
            <button className={"Font_Rajdhani"}>Login Now</button>
          </div>
        </div>
      </section>
      {/* Footer Section */}
      <Footer />
    </>
  );
}

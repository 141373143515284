import Footer from "../../Components/Footer";
import Styles from "./Home.module.css";
import { TbDiscountCheckFilled } from "react-icons/tb";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HiBadgeCheck } from "react-icons/hi";
import { BsArrowRightCircle } from "react-icons/bs";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import SliderArrow1 from "../../Components/SliderArrow1";
import SliderArrow2 from "../../Components/SliderArrow2";
import { faqs } from "../../Model/FaqModel";
import AccordionItem from "../../Components/AccordianItem";
import Testimonial from "./Testimonial";
import BookADemo from "./BookADemo";
import { useDispatch, useSelector } from "react-redux";
import IELTSQ from "./IELTSQ";
import Navbar2 from "../../Components/Navbar2";
import { useNavigate } from "react-router-dom";
import { MdArrowCircleLeft, MdArrowCircleRight } from "react-icons/md";
import { Take_Test_Det } from "../../Action/TLastAction";
import { Package_List } from "../../Action/PacakgeAction";
import PackageCard from "../../Components/PackageCard";
import Enroll from "./Enroll";

import { TAKE_A_TEST_RESET } from "../../Constant/TLatConstant";
import { Testimonials_Model } from "../../Model/HomeModel";
import ReactHelmet from "../../Components/ReactHelmet";
export default function Home2() {
  const [BookADemoActive, setBookADemoActive] = useState(false);
  const [EnrollActive, setEnrollActive] = useState(false);
  const [CourseId, setCourseId] = useState("");
  const ScrollCourseRef = useRef();
  const TestmonialRef = useRef();
  const PTEPracticeRef = useRef();
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);
  const Navigate = useNavigate();
  const [QuestionSec, setQuestionSec] = useState(true);
  const [active, setActive] = useState(null);
  const { loading, Test_Dets, error } = useSelector(
    (s) => s.Take_Test_Det_Reducer
  );

  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );

  const dispatch = useDispatch();

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  const Mobile_Screen = window.innerWidth;

  const BannerScroller = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow1 />,
    prevArrow: <SliderArrow2 />,
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: Mobile_Screen <= 600 ? 1 : 2,
    slidesToScroll: 1,
    nextArrow: <SliderArrow1 />,
    prevArrow: <SliderArrow2 />,
  };

  const BestSellingCourse_Settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 3
        ? 3
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const PTE_Scroller_Setting = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const PTE_Scroller_Setting2 = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 }, "MainPackages"));
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  useEffect(() => {
    if (Test_Dets) {
      Navigate(`/T-LAT-acknowledge/${Test_Dets}`);
      dispatch({ type: TAKE_A_TEST_RESET });
    }
    if (error) {
      Navigate("/error", { state: { error } });
    }
  }, [Test_Dets, error]);

  useEffect(() => {
    if (QuestionSec) {
      window.history.pushState("/", undefined, "/#Searchcourses");
    } else {
      if (!BookADemoActive && !QuestionSec) {
        window.history.pushState("/#Searchcourses", undefined, "/");
      }
    }
  }, [QuestionSec]);

  useEffect(() => {
    if (BookADemoActive) {
      window.history.pushState("/", undefined, "/#BookADemo");
    } else {
      if (!BookADemoActive && !QuestionSec)
        window.history.pushState("/#BookADemo", undefined, "/");
    }
  }, [BookADemoActive]);

  return (
    <div style={{ width: "100%" }}>
         <ReactHelmet title={"Testifyi"} description={"Home Page of Testifyi"} />
      {/* navbar Section */}
      <Navbar2 Page="Home" setQuestionSec={setQuestionSec} />
      {/* Fixed Menu Section */}
      {/* {Login_User ? <BottomMenu /> : ""} */}
      {/* Banner Section */}
      {QuestionSec ? <IELTSQ setQuestionSec={setQuestionSec} /> : ""}
      <section>
        <Slider {...BannerScroller}>
          <div>
            <section className={Styles.Margin}>
              <div className={Styles.Banner_Section}>
                <div>
                  <div>
                    <p
                      className="Font_Rajdhani"
                      style={{ color: "#0b3eff", fontWeight: "600" }}
                    >
                      TESTIFYI
                    </p>
                    <h1 className={Styles.Font_Rajdhani}>
                      Best English Language Training Platform in India for{" "}
                      <span
                        style={{
                          background: "blue",
                          color: "white",
                          padding: "0 2px",
                        }}
                      >
                        IELTS, PTE, and Duolingo
                      </span>{" "}
                      Preparation.
                    </h1>
                    <div>
                      <hr className={Styles.Hr45} />
                    </div>

                    <p style={{ padding: "0", margin: "0" }}>
                      <span></span>
                      <span
                        style={{
                          fontSize: "17px",
                          color: "black",
                          padding: "0",
                        }}
                      >
                        {" "}
                        <b> Testifyi</b> provides Comprehensive Training
                        Programs for
                        <b> IELTS, PTE, and Duolingo</b> Success. Target your
                        weaknesses, access practice tests, and improve rapidly
                        to achieve your desired scores!
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <img src="/assets/image/banner.png" alt="banner_ielts" />
                </div>
              </div>
            </section>
          </div>
          {/* <div>
            <section className={Styles.Margin}>
              <div className={Styles.Banner_Section}>
                <div>
                  <div>
                    <p
                      className="Font_Rajdhani"
                      style={{ color: "#0b3eff", fontWeight: "600" }}
                    >
                      TESTIFYI
                    </p>
                    <h1 className={Styles.Font_Rajdhani}>
                      Best Platform For English <br /> Language Preparation To
                      Study Abroad
                    </h1>
                    <div>
                      <hr className={Styles.Hr45} />
                    </div>
                    <p className={Styles.IELTS}>
                      <span>
                        {" "}
                        <TfiDirectionAlt />{" "}
                      </span>{" "}
                      <span> Best PTE Guidance Online</span>
                    </p>
                    <p className={Styles.IELTS}>
                      {" "}
                      <span>
                        <TbTargetArrow />{" "}
                      </span>{" "}
                      <span> 2000+ IELTS Practice Modules</span>
                    </p>
                  </div>
                </div>
                <div>
                  <img src="/assets/image/banner.png" alt="banner_ielts" />
                </div>
              </div>
            </section>{" "}
          </div> */}
        </Slider>
      </section>
      {/* Scrolled Section 2 */}
      <section style={{ width: "100%", margin: "auto", overflow: "hidden" }}>
        <div className={Styles.Rotation}>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>AI Precision</span>
          </div>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>Advanced score Predictor</span>
          </div>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>Flexible timings</span>
          </div>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>Proven success strategies</span>
          </div>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>Affordable & Accessible</span>
          </div>
          {/* {Mobile_Screen >= 600 ? (
            <>
              {" "}
              <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>AI
            Precision</span>
          </div>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>Advanced score Predictor</span>
          </div>
            </>
          ) : (
            ""
          )} */}
        </div>
      </section>
      {/* Bnner section 2 */}
      {/* Section 3 */}
      <section style={{ background: "#0b3eff" }}>
        <div className={Styles.Margin2}>
          <div
            className={Styles.Section_6_Main}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "8% 0 3% 0",
            }}
          >
            <div className={Styles.Section_6}>
              <div>
                <h1 className={Styles.Font_Rajdhani}>
                  Carefully curated courses and band predictors for improved{" "}
                  <span>learning & accuracy</span>
                </h1>
              </div>
              <div>
                <ul style={{ color: "white" }}>
                  <li>
                    GET TRAINED FROM OUR EXPERIENCED AND CERTIFIED EXPERTS
                  </li>
                  <li>
                    LIMITED STRENGTH OF BATCHES TO ENSURE PERSONALIZED ATTENTION
                  </li>
                  <li>SPECIALIZED MOCK TESTS POWERED BY AI</li>
                  {/* <li>IMPROVE YOUR SCORE WITH OUR EXPERT GUIDANCE</li> */}
                  <li>GET LATEST STUDY & PRACTICE MATERIAL</li>
                </ul>
              </div>

              <Slider {...PTE_Scroller_Setting}>
                <div>
                  <div className={Styles.Section4_Text}>
                    <hr />
                    <div>
                      <p
                        style={{
                          color: "white",
                          fontSize: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className={Styles.Icon_Tech}
                          src="/assets/image/TechBased/icon_Expert_Guidance.svg"
                          alt="Expert guidance"
                        />
                      </p>
                      <p>EXPERT GUIDANCE</p>
                    </div>

                    <hr />
                    <div>
                      <p
                        style={{
                          color: "white",
                          fontSize: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className={Styles.Icon_Tech}
                          src="/assets/image/TechBased/icon_Small_batch.svg"
                          alt="Small Batch"
                        />
                      </p>

                      <p>SMALL BATCHES</p>
                    </div>

                    <hr />
                  </div>
                </div>
                <div>
                  <div className={Styles.Section4_Text}>
                    <hr />
                    <div>
                      <p
                        style={{
                          color: "white",
                          fontSize: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className={Styles.Icon_Tech}
                          src="/assets/image/TechBased/icon_Tech_Test.svg"
                          alt="Expert guidance"
                        />
                      </p>

                      <p>TECH-ENABLED TESTS</p>
                    </div>
                    <hr />

                    <div>
                      <p
                        style={{
                          color: "white",
                          fontSize: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className={Styles.Icon_Tech}
                          src="/assets/image/TechBased/icon_Tips.svg"
                          alt="Expert guidance"
                        />
                      </p>

                      <p>TIPS & TECHNIQUES</p>
                    </div>
                    <hr />
                  </div>
                </div>
              </Slider>
            </div>
            <div
              className={Styles.Section6_Slider}
              style={{ width: "50%", overflow: "hidden" }}
            >
              {/* <div className={Styles.SliderArrow2}>
                <span onClick={() => PTEPracticeRef?.current?.slickPrev()}>
                  <MdArrowCircleLeft />
                </span>
              </div> */}
              <Slider
                ref={PTEPracticeRef}
                // style={{ width: "100%" }}
                {...settings}
              >
                <div>
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img src="/assets/image/IELTS.svg" alt="IELTS" />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        IELTS Courses
                        <br />
                      </h1>
                    </div>
                    <div>
                      <p>
                        {" "}
                        <span
                          onClick={() => Navigate("/Course")}
                          className="Font_Rajdhani"
                        >
                          Explore More{" "}
                        </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img src="/assets/image/PTE.svg" alt="PTE" />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        PTE Courses
                        <br />
                      </h1>
                    </div>
                    <div>
                      <p>
                        {" "}
                        <span
                          onClick={() => Navigate("/Course")}
                          className="Font_Rajdhani"
                        >
                          {" "}
                          Explore More{" "}
                        </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img
                        src="/assets/image/GeneralEnglish.svg"
                        alt="General English"
                      />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        General English
                        <br /> Courses
                      </h1>
                    </div>
                    <div>
                      <p>
                        {" "}
                        <span
                          onClick={() => Navigate("/Course")}
                          className="Font_Rajdhani"
                        >
                          {" "}
                          Explore More{" "}
                        </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img src="/assets/image/Duolingo.svg" alt="Duolingo" />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        Duolingo Courses
                        <br />
                      </h1>
                    </div>
                    <div>
                      <p>
                        <span
                          onClick={() => Navigate("/Course")}
                          className="Font_Rajdhani"
                        >
                          {" "}
                          Explore More{" "}
                        </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
              </Slider>
              {/* <div className={Styles.SliderArrow3}>
                <span onClick={() => PTEPracticeRef?.current?.slickNext()}>
                  <MdArrowCircleRight />
                </span>
              </div> */}
            </div>{" "}
          </div>
          <p
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            "Our goal is to understand our students' learning capabilities and
            tailor training techniques accordingly."
          </p>
        </div>
      </section>

      {/* Course Scroller section */}
      <section style={{ background: "#f4f9ff" }} className={Styles.Margin2}>
        {EnrollActive ? (
          <Enroll
            setEnrollActive={setEnrollActive}
            Package_Id={EnrollActive}
            courseId={CourseId}
          />
        ) : (
          ""
        )}
        <div className={Styles.section3_Top_heading}>
          <div>
            <h1 className={Styles.Heading}>
              <span className={Styles.Font_Rajdhani}>
                {" "}
                Best Selling Courses For <br /> English Language Preparation
              </span>
            </h1>
          </div>
          {/* <div>
            <select>
              <option>All Courses</option>
              <option>IELTS</option>
              <option>PTE</option>
              <option>General</option>
            </select>
          </div> */}
        </div>
        <div className={Styles.Course_Scroller}>
          <Slider ref={ScrollCourseRef} {...BestSellingCourse_Settings}>
            {PackageRec?.map((el, i) => (
              <div key={el._id}>
                <PackageCard
                  setEnrollActive={setEnrollActive}
                  EnrollActive={EnrollActive}
                  setCourseId={setCourseId}
                  el={el}
                  Styles={Styles}
                />
              </div>
            ))}
          </Slider>
          <div className={Styles.SliderArrow}>
            <span onClick={() => ScrollCourseRef?.current?.slickPrev()}>
              <MdArrowCircleLeft />
            </span>
            <span onClick={() => ScrollCourseRef?.current?.slickNext()}>
              <MdArrowCircleRight />
            </span>
          </div>
        </div>

        <div className={Styles.ViewAll}>
          <button onClick={() => Navigate("/course")}>View All Courses</button>
        </div>
      </section>
      {/* Section Banner */}

      <section style={{ background: "#fff1f5" }}>
        <div className={Styles.Margin2}>
          <div className={Styles.Banner_Test_Section}>
            <div>
              <h1
                className={Styles.Heading7}
                style={{
                  color: "black",
                  fontSize: "38px",
                  margin: "0",
                  padding: "0",
                  paddingBottom: "6px",
                }}
              >
                <span className={Styles.Font_Rajdhani}>
                  Take the First Step with T-LAT
                </span>
              </h1>
              <p style={{ color: "black", margin: "0" }}>
                Measure your English skills and open doors to new opportunities.
              </p>
              <p style={{ fontWeight: "600", padding: "15px 0" }}>
                Take the T-LAT now and start your journey!
              </p>
              <div>
                <button
                  onClick={() => {
                    if (Login_User?.EmailAddress) {
                      dispatch(
                        Take_Test_Det({
                          EmailAddress: Login_User?.EmailAddress,
                        })
                      );
                    } else {
                      Navigate("/signup", {
                        state: { TLAT: "Take_Test" },
                      });
                    }
                  }}
                  disabled={loading}
                  className={Styles.custom_btn}
                >
                  {loading ? "Loading..." : "Take A Test"}
                </button>
              </div>
            </div>

            {/* <div>
              <h4>Take the First Step with T-LAT</h4>
              <p>
                Measure your English skills and open doors to new opportunities.
              </p>
              <p>Take the T-LAT now and start your journey!</p>
            </div> */}
            <div>
              <img src="/assets/image/Asset_5.png" alt="Asset logo" />
            </div>
          </div>
        </div>
      </section>

      {/* Section 4 why testifyi */}
      <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div style={{ fontSize: "35px" }}>
              <h1 className={Styles.Font_Rajdhani}>
                Why prepare with{" "}
                <span style={{ color: "#0b3eff" }}>TESTIFYI</span> ?{" "}
              </h1>
              <p>
                "We at TESTIFYI are comitted for providing comprehensive &
                personalized quality service that assesses the unique needs and
                preferences of each student."
              </p>
            </div>
            <div className={Styles.VisaCardDesign}>
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/Customized_courses.png"
                    alt="Customized_courses"
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>Customized courses</h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/Flxi_classes.png"
                    alt="Flxible Timings"
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>
                    Flexible
                    <br /> timings
                  </h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img src="/assets/image/AI.png" alt="Ai" />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>
                    AI
                    <br /> Precision
                  </h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/Advance_curri.png"
                    alt="Advance_curri"
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>Advanced curriculum</h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
            </div>
            <div className={Styles.VisaCardDesign}>
              <div className={Styles.VisaCard}>
                <div>
                  <img src="/assets/image/Personalise.png" alt="Personalise" />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>Personalised sessions</h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/advance_score.png"
                    alt="advance_score"
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>Advanced score Predictor</h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/proven_strategy.png"
                    alt="proven_strategy"
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>Proven success strategies</h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/Affordable_and_accessable.png"
                    alt="Affordable_and_accessable"
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <h1>Affordable & Accessible</h1>
                  {/* <h1>
                    <BsFillArrowRightCircleFill />
                  </h1> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 4 */}

      {/* section 5 */}
      <section className={Styles.Section4}>
        <div className={Styles.Margin2}>
          <div
            // className={Styles.Section_7_style}
            style={{
              // display: "flex",
              // justifyContent: "flex-start",
              alignItems: "center",
              margin: "3% 0 8% 0",
            }}
          >
            <div>
              {/* <p style={{ color: "#0b3eff", fontWeight: "600" }}>Upgrade</p> */}
              <div>
                <h1
                  className={Styles.Heading7}
                  style={{ color: "white", fontSize: "35px" }}
                >
                  <span className={Styles.Font_Rajdhani}>
                    Book A Demo For Live Classes
                  </span>
                </h1>
                <p style={{ color: "white", margin: "0" }}>
                  " Get an overall view of the course and understand your
                  preparation journey"
                </p>
              </div>
              <div className={Styles.Book_A_Demo}>
                {window.innerWidth >= 780 ? (
                  <Slider {...PTE_Scroller_Setting2}>
                    <div>
                      <div className={Styles.Section4_Text}>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Live assessment and <br />
                            feedback from the <br />
                            experts
                          </p>
                        </div>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Free on-spot
                            <br /> evaluation
                          </p>
                        </div>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>Doubts resolution</p>
                        </div>
                        <hr className={Styles.Hr1} />
                      </div>
                    </div>

                    <div>
                      <div className={Styles.Section4_Text}>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Customized course plans
                            <br /> according to your
                            <br /> strenghts & weaknesses
                          </p>
                        </div>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Free on-spot
                            <br /> evaluation
                          </p>
                        </div>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>Doubts resolution</p>
                        </div>
                        <hr className={Styles.Hr1} />
                      </div>
                    </div>
                  </Slider>
                ) : (
                  <Slider {...PTE_Scroller_Setting2}>
                    <div>
                      <div className={Styles.Section4_Text}>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Live assessment and <br />
                            feedback from the <br />
                            experts
                          </p>
                        </div>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Free on-spot
                            <br /> evaluation
                          </p>
                        </div>
                        <hr className={Styles.Hr1} />
                      </div>
                    </div>

                    <div>
                      <div className={Styles.Section4_Text}>
                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>Doubts resolution</p>
                        </div>

                        <hr className={Styles.Hr1} />
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ color: "#42d96b", fontSize: "25px" }}>
                            <TbDiscountCheckFilled />
                          </p>
                          <p>
                            Live assessment and <br />
                            feedback from the <br />
                            experts
                          </p>
                        </div>
                        <hr className={Styles.Hr1} />
                      </div>
                    </div>

                    {/* <div>
                    <div className={Styles.Section4_Text}>
                      <hr className={Styles.Hr1} />
                      <div style={{ fontSize: "18px" }}>
                        <p style={{ color: "#42d96b", fontSize: "25px" }}>
                          <TbDiscountCheckFilled />
                        </p>
                        <p>
                          Customized course plans
                          <br /> according to your
                          <br /> strenghts & weaknesses
                        </p>
                      </div>
                      <hr className={Styles.Hr1} />
                      <div style={{ fontSize: "18px" }}>
                        <p style={{ color: "#42d96b", fontSize: "25px" }}>
                          <TbDiscountCheckFilled />
                        </p>
                        <p>
                          Live assessment and <br />
                          feedback from the <br />
                          experts
                        </p>
                      </div>
                      <hr className={Styles.Hr1} />
                      <div style={{ fontSize: "18px" }}>
                        <p style={{ color: "#42d96b", fontSize: "25px" }}>
                          <TbDiscountCheckFilled />
                        </p>
                        <p>
                          Free on-spot
                          <br /> evaluation
                        </p>
                      </div>
                      <hr className={Styles.Hr1} />
                    </div>
                  </div> */}
                  </Slider>
                )}
              </div>

              {/* Doubts resolution */}

              <div
                style={{ padding: "40px 0px", display: "flex", gap: "20px" }}
              >
                <button
                  className={Styles.custom_btn}
                  onClick={() => Navigate("/contact-us")}
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "0.1px solid white",
                  }}
                >
                  Let's Connect
                </button>
                <button
                  onClick={() => setBookADemoActive(true)}
                  className={Styles.custom_btn}
                >
                  Book A Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {BookADemoActive ? (
        <BookADemo setBookADemoActive={setBookADemoActive} />
      ) : (
        ""
      )}
      {/* section 6 IRT */}

      {/* <section style={{ background: "#ffffff" }}>
        <div className={Styles.Margin2}>
          <div className={Styles.Section4_Text2}>
            <div>
              <h1 style={{ fontSize: "30px", fontWeight: "700" }}>
                <span className={Styles.Font_Rajdhani}>Enroll in Upcoming</span>
                <br />{" "}
                <span
                  className={Styles.Font_Rajdhani}
                  style={{ color: "#0b3eff" }}
                >
                  Live Mock Tests{" "}
                </span>
              </h1>
            </div>

            <div>
              <p>
                In this free inactive webinar, Our IELTS export will help us be
                ready to
                <br /> answer a range of common questions types for part1 of the
                speaking Test.
              </p>
            </div>
          </div>

          <div>
            {MockLive.map((el, i) => (
              <div key={i + 80} className={Styles.Section5_Card}>
                <div
                  className={i % 2 === 0 ? Styles.SideColor : Styles.SideColor2}
                ></div>
                <div className={Styles.Calender}>
                  <p>Date</p>
                  <p>
                    <span>
                      <BsCalendar4Week />{" "}
                    </span>
                    <span>10 July 2020</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>
                <div className={Styles.Calender}>
                  <p>Duration</p>
                  <p>
                    <span>
                      <PiWatch />
                    </span>
                    <span>2 hr 30 min</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>
                <div>
                  <p> Writing Mock Test</p>
                  <p>
                    <span>For IELTS</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>
                <div className={Styles.Calender}>
                  <p>Location</p>
                  <p>
                    <span>
                      <MdLocationOn />
                    </span>
                    <span>Noida</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>

                <div className={Styles.EnrollBtn}>
                  <button>Enroll Now</button>
                </div>
              </div>
            ))}

            <div className={Styles.Load_More}>
              <button>
                <span style={{ color: "white" }}>
                  <BiLoader />{" "}
                </span>{" "}
                Know More
              </button>
            </div>
          </div>
        </div>
      </section> */}

      {/* section 6 IRT end */}

      {/* Testimonial Reviews */}
      <section style={{ background: "#f4f9ff" }} className={Styles.Margin2}>
        <div>
          <h1 className={Styles.Heading}>
            <span className={Styles.Font_Rajdhani}> Our Testimonials</span>
          </h1>
        </div>

        <Testimonial
          TestmonialRef={TestmonialRef}
          Testimonials_Model={Testimonials_Model}
          Styles={Styles}
        />
      </section>

      {/* FAQs */}
      <section style={{ background: "#ffffff" }} className=".Faqs">
        <div className={Styles.Margin2}>
          <div className="container-fluid mt-5 mb-5">
            <div className="card-body_Faq">
              <h4 className="form-heading mb-4">Frequently Asked Questions</h4>
              {faqs.map((faq, index) => {
                return (
                  <AccordionItem
                    key={index}
                    active={active}
                    handleToggle={handleToggle}
                    faq={faq}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* Top Footer Section*/}
      <section className={Styles.Footer_Top}>
        <div>
          <div>
            <h1 className={"Font_Rajdhani"}>
              Start Your IELTS Journey With The Best
            </h1>
          </div>
          <div>
            <button className={"Font_Rajdhani"}>Create Account</button>
            <button className={"Font_Rajdhani"}>Login Now</button>
          </div>
        </div>
      </section>
      {/* Footer Section */}
      <Footer />
    </div>
  );
}

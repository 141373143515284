export const CLASS_CENTER_LIST_REQUEST = "CLASS_CENTER_LIST_REQUEST";
export const CLASS_CENTER_LIST_SUCCESS = "CLASS_CENTER_LIST_SUCCESS";
export const CLASS_CENTER_LIST_FAIL = "CLASS_CENTER_LIST_FAIL";

export const CLASS_CENTER_GET_REQUEST = "CLASS_CENTER_GET_REQUEST";
export const CLASS_CENTER_GET_SUCCESS = "CLASS_CENTER_GET_SUCCESS";
export const CLASS_CENTER_GET_FAIL = "CLASS_CENTER_GET_FAIL";

export const CLASS_CENTER_INSERT_REQUEST = "CLASS_CENTER_INSERT_REQUEST";
export const CLASS_CENTER_INSERT_SUCCESS = "CLASS_CENTER_INSERT_SUCCESS";
export const CLASS_CENTER_INSERT_FAIL = "CLASS_CENTER_INSERT_FAIL";
export const CLASS_CENTER_INSERT_RESET = "CLASS_CENTER_INSERT_RESET";

export const CLASS_CENTER_UPDATE_REQUEST = "CLASS_CENTER_UPDATE_REQUEST";
export const CLASS_CENTER_UPDATE_SUCCESS = "CLASS_CENTER_UPDATE_SUCCESS";
export const CLASS_CENTER_UPDATE_FAIL = "CLASS_CENTER_UPDATE_FAIL";
export const CLASS_CENTER_UPDATE_RESET = "CLASS_CENTER_UPDATE_RESET";

export const CLASS_CENTER_DELETE_REQUEST = "CLASS_CENTER_DELETE_REQUEST";
export const CLASS_CENTER_DELETE_SUCCESS = "CLASS_CENTER_DELETE_SUCCESS";
export const CLASS_CENTER_DELETE_FAIL = "CLASS_CENTER_DELETE_FAIL";
export const CLASS_CENTER_DELETE_RESET = "CLASS_CENTER_DELETE_RESET";

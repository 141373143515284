import { useEffect, useState } from "react";
import Styles from "./LiveTest.module.css";
import { useSelector } from "react-redux";
import Footer from "../../Components/Footer";
import { useNavigate, useParams } from "react-router-dom";

export default function TestAcknowledge() {
  const [UserData, setUserData] = useState({});
  const { Login_User } = useSelector((s) => s.USER_LOGIN_REDUCER);
  const { Id } = useParams();
  const Navigate = useNavigate();
  useEffect(() => {
    if (Login_User) {
      setUserData(Login_User);
    }
  }, [Login_User]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <navbar>
        <div className={Styles.Navbar}>
          <div>
            <img src="/assets/image/Logos/Testifyi_logo1.jpg" alt="logo" />
          </div>
          <div>{/* <h3>Psychometric Test</h3> */}</div>

          <div className={Styles.Coordinator}>
            <div className={Styles.Coordinator_top}>
              <div>
                <img src="/assets/image/user.svg" alt="profile" />
              </div>
              <div>
                <h5>
                  {" "}
                  {UserData?.First_Name
                    ? UserData?.First_Name +
                      " " +
                      (UserData?.Last_Name ? UserData?.Last_Name : "")
                    : "User"}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </navbar>

      {/* Acknowledge */}
      <section className={Styles.Margin4}>
        <div className={Styles.Container_Ack}>
          {" "}
          <div>
            <h2
              style={{
                marginBottom: "0",
                paddingBottom: "4px",
                textAlign: "center",
              }}
            >
              {" "}
              T-LAT (Testifyi Language Assessment Test)
            </h2>
          </div>
          <p
            style={{
              textAlign: "center",
              margin: "0",
              padding: "0",
              fontWeight: "600",
            }}
          >
            "Let's unlock your mind's potential through insightful assessment."
          </p>
          <div>
            <p style={{ textAlign: "center" }}>
              <strong>DEFINITION: </strong> An English language assessment test
              is an evaluation designed to measure an individual's proficiency
              in the English language. This tests typically assess various
              aspects of language skills, including reading, writing, listening,
              and speaking.
            </p>
          </div>
          <div className={Styles.HeadAckn}>
            <h2 style={{ marginBottom: "0", paddingBottom: "4px" }}>
              {" "}
              INSTRUCTIONS FOR T-LAT
            </h2>
          </div>
          <div>
            <ol
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <li>
                <strong>Read Carefully:</strong> Before beginning the test, read
                the instructions thoroughly. Each set may have specific
                guidelines on how to approach the questions.
                <br />
                The test is of 60 marks in total, divided into 3 sets of 10
                questions each . Question 1- 10 are of 1 mark each, Question
                11-20 are of 2 marks each and Question 21-30 are of 3 marks
                each.
              </li>
              <li>
                <strong>Time Management: </strong>
                Be aware of the time allotted for the test. Some tests may be
                timed, so it’s important to pace yourself to complete all
                questions.
                <br />
                Once the test is started a countdown timer of 15 minutes starts
                on the top of the screen, the test automatically submits as soon
                as the timer runs off. In order to submit the test before the
                mentioned time, you need to move to the 30th question and click
                the submit button.
              </li>
              <li>
                <strong>Stay Focused: </strong>
                Minimize distractions in your environment to maintain focus
                throughout the test.
              </li>
              <li>
                <strong>Follow the Format:</strong>Pay attention to the format
                of the test. Some common formats include multiple-choice,
                true/false, or open-ended questions.
              </li>
              <li>
                <strong>Skip Questions If Needed:</strong>If you encounter a
                question that confuses you, it's often better to skip it and
                return later if time allows.
                <br />
                You can move to any question which you want to attempt first and
                come back to the same before the timer runs out
              </li>

              <li>
                <strong>Review Your Answers: </strong>
                If time permits, review your responses to check for any mistakes
                or to ensure you provided answers that represent your thoughts
                accurately.
              </li>
            </ol>
          </div>
          <p
            style={{
              textAlign: "center",
              fontWeight: "700",
              padding: "20px 0",
            }}
          >
            Following these instructions can help you perform better in English
            language assessments. Good luck!
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <button
              onClick={() => {
                if (Id) {
                  window.open(
                    `/T-LAT/${Id}`,
                    "_blank",
                    `top=500,left=500,width=4000,height=4000`
                  );
                  // Navigate(`/T-LAT/${Id}`, { replace: true });
                }
              }}
              className={Styles.custom_btn}
            >
              START TEST NOW
            </button>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <Footer />
    </div>
  );
}

import { useEffect, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export default function Pagination({ Styles, Filter, setFilter, total }) {
  const [activePage, setActivePage] = useState(1);
  const records = Math.ceil(total / Filter.page_size);

  const arr = Array.from({ length: records }, (_, index) => index + 1);

  useEffect(() => {
    setActivePage(Filter.page_no);
  }, [Filter]);

  return (
    <>
      <div className={Styles.Pagination}>
        <div>
          <hr />
        </div>
        <div>
          <p
            className={Styles.Page}
            disabled={activePage == 1 || total == 0 || total === undefined}
            onClick={() => {
              if (activePage == 1 || total == 0 || total === undefined) {
              } else {
                setFilter({
                  ...Filter,
                  page_no: Filter.page_no - 1,
                });
              }
            }}
          >
            <BsChevronLeft />
          </p>

          {arr?.map((ev, i) => (
            <p
              style={
                i <= activePage + 2 && i >= activePage - 3
                  ? { display: "block" }
                  : { display: "none" }
              }
              className={i === activePage - 1 ? Styles.active : Styles.Page}
              onClick={() => setFilter({ ...Filter, page_no: ev })}
            >
              {ev}
            </p>
          ))}
          {/* <p className={Styles.active}>1</p>
          <p className={Styles.Page}>2</p>
          <p className={Styles.Page}>3</p>
          <p className={Styles.Page}>4</p>
          <p className={Styles.Page}>5</p>*/}
          <p
            className={Styles.Page}
            disabled={
              activePage === arr.length || total === 0 || total === undefined
            }
            onClick={() => {
              if (
                activePage === arr.length ||
                total === 0 ||
                total === undefined
              ) {
              } else {
                setFilter({
                  ...Filter,
                  page_no: Filter.page_no + 1,
                });
              }
            }}
          >
            <BsChevronRight />{" "}
          </p>
        </div>
        <div>
          <hr />
        </div>
      </div>
    </>
  );
}
